@if (comment()) {
  <div 
    class="pt-thread__header"
    *puSpinner="isLoading()">
    <h4 class="pt-thread__header-text">
      {{ 'blog.post.thread.header' | translate }}
    </h4>
  
    <button
      class="pt-thread__close"
      type="button"
      puButton
      size="m"
      uiType="quaternary"
      [onlyIcon]="true"
      (click)="onClose()"
    >
      <pu-icon
        iconName="close"
        size="16"
      />
    </button>
  </div>
  
  <div
    class="pt-thread__body"
    #scrollRef
    ptInfiniteScroll
    [hasMore]="replies().length < comment().repliesCount"
    (scrolled)="loadMore()"
    [isLoading]="isLoading()">
    <pt-comment
      isParent
      [comment]="comment()"
      (reactionsChanged)="markAsDirty()"
      />
  
    <div class="pt-thread__replies">
      <h5 class="pt-thread__replies-title">
        {{ 'blog.post.thread.replies.title' | translate: { count: comment().repliesCount || 0 }  }}
      </h5>
    
      <ul class="pt-thread__replies-list">
        @for (reply of replies(); track $index) {
          <li
            ptComment
            [comment]="reply"
            [isEditing]="isEditing(reply.id)"
            (edit)="onEdit($event)"
            (save)="onSave($event, reply)"
            (cancel)="cancelEdit()"
            (remove)="onDelete($event)">
          </li>
        }
      </ul>
    </div>
  
    <pt-comment-form
      class="pt-thread__form"
      (formSubmitted)="onSave($event)"/>
  </div>
}
