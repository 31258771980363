import { inject, Injectable } from '@angular/core';

import {
  filter, map, Observable, of, switchMap, take, takeWhile, tap, timer, catchError,
} from 'rxjs';
import { WINDOW, CookieService } from '@pinup-teams/common';
import { EnvName } from '@pt/models';
import { environment } from '@pt/environment';
import { AuthService } from '@pt/auth';

import { AssetLoaderService } from './asset-loader.service';

type WindowExtended = Window & { aft(...args: any): void; aftSID(): string };

const AFT_SESSION_ID_KEY = 'aftSID';

@Injectable()
export class AnalyticsService {
  readonly #window = inject<WindowExtended>(WINDOW);
  readonly #auth = inject(AuthService);
  readonly #cookie = inject(CookieService);
  readonly #assetLoader = inject(AssetLoaderService);

  init(): Observable<string> {
    // need for preventing debugger from loaded scripts
    localStorage.setItem('dcs', 't');

    return this.#loadScript()
      .pipe(
        switchMap(() => this.#setSIDToCookie()),
        catchError(() => of('')),
      );
  }

  sendLoginEvent(token: string): void {
    if (environment.envName === EnvName.PROD && this.#window.aft) {
      const { email } = this.#auth.decodeJwt(token);
      this.#window.aft('tag', email);
      this.#window.aft('send');
    }
  }

  #loadScript(): Observable<void> {
    let url: string;
    const { envName, assetsHost } = environment;

    if (envName === EnvName.PROD || envName === EnvName.STAGE) {
      url = `${assetsHost}/assets/scripts/aft-fp-prod.js`;
    } else if (envName === EnvName.DEV) {
      url = `${assetsHost}/assets/scripts/aft-fp-dev.js`;
    }

    return this.#assetLoader.loadJS(url);
  }

  #setSIDToCookie(): Observable<string> {
    return timer(0, 50).pipe(
      takeWhile(i => i < 40),
      filter(() => !!this.#window.aftSID),
      map(() => this.#window.aftSID()),
      take(1),
      tap(sid => {
        const afterThreeDays = new Date(Date.now() + (3 * 24 * 60 * 60 * 1000)).toUTCString();
        this.#cookie.set(AFT_SESSION_ID_KEY, sid, afterThreeDays, environment.cookieDomain);
      }),
    );
  }
}
