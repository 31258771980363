import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/**
 * The loading state of a bundle.
 *
 * UNKNOWN -> It has not been tried to load this bundle.
 * LOADING -> The loading of this bundle is currently happening.
 * LOADED -> The bundle has been successfully loaded.
 * FAILED -> The loading of the bundle failed.
 */
type LoadingState = 'UNKNOWN' | 'LOADING' | 'LOADED' | 'FAILED';

@Injectable({ providedIn: 'root' })
export class WebComponentsRegistryService {
  #loadingStates: Record<string, LoadingState> = {};

  #document = inject(DOCUMENT);

  /**
   * Loads the given bundle if not already loaded, registering its custom elements in the browser.
   *
   * @param bundleUrl The url of the bundle, can be absolute or relative to the domain + base href.
   */
  async loadBundle(bundleUrl: string): Promise<boolean> {
    if (['LOADING', 'LOADED'].includes(this.getLoadingState(bundleUrl))) {
      return true;
    }

    this.#loadingStates[bundleUrl] = 'LOADING';

    const isSuccess = await this.#load(bundleUrl)
      .then(() => true)
      .catch(() => false);

    this.#loadingStates[bundleUrl] = isSuccess ? 'LOADED' : 'FAILED';

    return isSuccess;
  }

  /**
   * Returns the loading state of the bundle.
   *
   * @param bundleUrl The url of the bundle.
   */
  getLoadingState(bundleUrl: string): LoadingState {
    return this.#loadingStates[bundleUrl] || 'UNKNOWN';
  }

  unloadBundle(bundleUrl: string): void {
    const bundle = this.#document.querySelector(`script[src="${bundleUrl}"]`);

    bundle?.remove();
  }

  /**
   * Returns if the bundle has already been loaded successfully.
   *
   * @param bundleUrl The url of the bundle.
   */
  isBundleLoaded(bundleUrl: string): boolean {
    return this.getLoadingState(bundleUrl) === 'LOADED';
  }

  #load(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = this.#document.createElement('script');
      script.src = url;
      script.onload = () => resolve();
      script.onerror = () => reject({
        error: `Bundle ${url} could not be loaded`,
      });
      this.#document.body.appendChild(script);
    });
  }
}
