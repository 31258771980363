import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';

import { CompleteTaskReq, CompleteTaskRes } from '../models';

export interface OnboardingTaskDetailsApi {
  completeTask(params: CompleteTaskReq): Observable<CompleteTaskRes>;
}

export const ONBOARDING_TASK_DETAILS_API = new InjectionToken<OnboardingTaskDetailsApi>(
  'ONBOARDING_TASK_DETAILS_API',
);
