import { AsyncPipe } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostBinding,
  inject,
  input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { DonutTotalComponent } from '@pt/components';
import {
  ButtonModule,
  CommonMediaQueries,
  DictionaryItem,
  EmptyComponent,
  initFormGroup,
  LetDirective,
  MediaScreenService,
  SelectComponent,
  SpinnerDirective,
  WithAllOptionPipe,
} from '@pinup-teams/common';
import { NgxPermissionsService } from 'ngx-permissions';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { FormBuilder } from '@ngneat/reactive-forms';
import { changePage, subscribeOnControls, subscribeOnRouteParams } from '@pinup-teams/common';
import { RootState } from '@pt/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DictionariesService } from '@pt/services';
import { TransactionGroup, TransactionStatus } from '@pt/models';
import { SwiperOptions } from 'swiper';
import { SwiperModule } from 'swiper/angular';
import { environment } from '@pt/environment';
import { MfeUrlSegments } from '@pt/mfe';

import { TotalFullComponent } from './components/total-full/total-full.component';
import { TotalStatusComponent } from './components/total-status/total-status.component';
import { TransactionComponent } from './components/transaction/transaction.component';
import {
  TransactionsFilters,
  TransactionsActions,
  initialFilters,
  TransactionsSelectors,
  TransactionsViewModel,
  routeFiltersSettings,
} from './store';

@Component({
  standalone: true,
  selector: 'pt-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LetDirective,
    AsyncPipe,
    SpinnerDirective,
    TranslateModule,
    SwiperModule,
    WithAllOptionPipe,
    ButtonModule,
    SelectComponent,
    TransactionComponent,
    EmptyComponent,
    MatPaginator,
    DonutTotalComponent,
    TotalFullComponent,
    TotalStatusComponent,
  ],
})
export class TransactionsComponent implements OnInit, OnDestroy {
  userId = input<number>();
  isAdmin = input(false, { transform: booleanAttribute });
  forTeam = input(false, { transform: booleanAttribute });
  myTeam = input(false, { transform: booleanAttribute });
  dialog = input(false, { transform: booleanAttribute });

  @HostBinding('class.pt-transactions_dialog')
  private get _dialog() {
    return this.dialog();
  }

  private readonly destroyRef = inject(DestroyRef);
  private readonly _route = inject(ActivatedRoute);
  private readonly _store = inject(Store<RootState>);
  private readonly _fb = inject(FormBuilder);
  private readonly _translate = inject(TranslateService);
  private readonly _dictionaries = inject(DictionariesService);
  private readonly _mediaScreenService = inject(MediaScreenService);
  private readonly _permissionsService = inject(NgxPermissionsService);
  private readonly _mfeUrlSegments = inject(MfeUrlSegments);

  private readonly _permissions = this._permissionsService.getPermissions();
  private _hasTransactionsFuncPermission = false;

  readonly transactionsSegment = `/${this._mfeUrlSegments.transactions}`;

  readonly breakpoints$: Observable<{
    isTabletMode: boolean;
    isDesktopMode: boolean;
  }> = combineLatest({
      isTabletMode: this._mediaScreenService.mediaMatcher(CommonMediaQueries.MD),
      isDesktopMode: this._mediaScreenService.mediaMatcher(CommonMediaQueries.LG),
    });

  readonly vm$: Observable<TransactionsViewModel> = this._store.select(
    TransactionsSelectors.selectViewModel,
  );

  readonly filtersForm = initFormGroup<TransactionsFilters>(
    this._store.select(TransactionsSelectors.selectFilters),
    this._fb.group<TransactionsFilters>({ ...initialFilters }),
    this,
  );

  readonly TransactionGroup = TransactionGroup;
  readonly periods = this._dictionaries.periods;

  readonly groups: DictionaryItem<TransactionGroup>[] = [
    { id: TransactionGroup.Income, name: this._translate.instant('transactions.group.income') },
    { id: TransactionGroup.Expense, name: this._translate.instant('transactions.group.expense') },
  ];

  readonly expenseStatuses: DictionaryItem<TransactionStatus>[] = [
    {
      id: TransactionStatus.Purchase,
      name: this._translate.instant('transactions.expenseStatuses.purchases'),
    },
    {
      id: TransactionStatus.ToColleague,
      name: this._translate.instant('transactions.expenseStatuses.toColleagues'),
    },
    {
      id: TransactionStatus.Rollback,
      name: this._translate.instant('transactions.expenseStatuses.rollback'),
    },
  ];

  readonly teamBonusStatuses: DictionaryItem<TransactionStatus>[] = [
    {
      id: TransactionStatus.Sent,
      name: this._translate.instant('transactions.teamBonusStatuses.sent'),
    },
    {
      id: TransactionStatus.Received,
      name: this._translate.instant('transactions.teamBonusStatuses.received'),
    },
    {
      id: TransactionStatus.Burned,
      name: this._translate.instant('transactions.teamBonusStatuses.burned'),
    },
  ];

  readonly swiperConfig: SwiperOptions = {
    slidesPerView: 'auto',
    grabCursor: true,
    navigation: {
      prevEl: '.pt-transactions__swiper-control-prev',
      nextEl: '.pt-transactions__swiper-control-next',
    },
  };

  readonly project = environment.shortName;

  incomeStatuses: DictionaryItem<TransactionStatus>[] = [];

  ngOnInit(): void {
    if (this.dialog()) {
      routeFiltersSettings.ignoreRoute = true;
    }

    this._store.dispatch(
      TransactionsActions.init({
        routeData: {
          ...this._route.snapshot.data,
          userId: this.userId(),
          forTeam: this.forTeam(),
          myTeam: this.myTeam(),
        },
      }),
    );
    subscribeOnRouteParams(<any> this, routeFiltersSettings);
    subscribeOnControls(<any> this, routeFiltersSettings);

    this._hasTransactionsFuncPermission = !this.isAdmin()
      || !!this._permissions['AdminTransactionsFunctional'];

    this.incomeStatuses = [
      {
        id: TransactionStatus.Reward,
        name: this._translate.instant('transactions.incomeStatuses.rewards'),
      },
      ...(this._hasTransactionsFuncPermission
        ? [
          {
            id: TransactionStatus.FuncReward,
            name: this._translate.instant('transactions.incomeStatuses.funcRewards'),
          },
        ]
        : []
      ),
      {
        id: TransactionStatus.FromColleague,
        name: this._translate.instant('transactions.incomeStatuses.fromColleagues'),
      },
      {
        id: TransactionStatus.Refund,
        name: this._translate.instant('transactions.incomeStatuses.refunds'),
      },
    ];
  }

  ngOnDestroy(): void {
    routeFiltersSettings.ignoreRoute = false;
  }

  changePage(event: PageEvent): void {
    changePage(<any> this, event);
  }

  changeGroup(group: DictionaryItem<TransactionGroup>): void {
    this.filtersForm.patchValue({ group: group.id, status: null });
  }

  changeStatus(status: DictionaryItem<TransactionStatus>): void {
    this.filtersForm.patchValue({ group: null, status: status.id });
  }
}
