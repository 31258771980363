import { createReducer, on } from '@ngrx/store';

import { UI_IS_DARK_THEME_KEY, UiActions, UiState } from './';

const isDarkTheme = window.localStorage.getItem(UI_IS_DARK_THEME_KEY);

export const uiReducer = createReducer<UiState>(
  {
    isDarkTheme: isDarkTheme === 'true',
    isNavbarHiddenByScroll: false,
    isShadowVisibleByScroll: false,
    headerHeight: null,
  },
  on(
    UiActions.setTheme,
    (state, { isDarkTheme }): UiState => ({
      ...state,
      isDarkTheme,
    }),
  ),
  on(
    UiActions.elementsScrollVisibilityChanged,
    (state, action): UiState => ({
      ...state,
      isNavbarHiddenByScroll: action.isNavbarHidden,
      isShadowVisibleByScroll: action.isShadowVisible,
    }),
  ),
  on(
    UiActions.setHeaderHeight,
    (state, { headerHeight }): UiState => ({
      ...state,
      headerHeight,
    }),
  ),
);
