<form
  class="pt-comment-form"
  [formGroup]="form"
  (ngSubmit)="onSubmit()">

  <div class="pt-comment-form__control">
    <div  class="pt-comment-form__emoji">
      <button
        type="button"
        puButton
        size="s"
        uiType="quaternary"
        #dropdownRef="puDropdownTrigger"
        [puDropdownTriggerFor]="emojiMart"
        [autoOverlayPosition]="true"
        [offsetY]="30">
        <pu-icon iconName="add-emoji" size="20" />
      </button>
        
      <pu-dropdown #emojiMart>
        <emoji-mart
          [darkMode]="isDarkTheme()"
          [isNative]="true"
          (emojiSelect)="selectEmoji($event)" />
      </pu-dropdown>
    </div>
  
    <pu-text-area
      controlId="commentFormText"
      formControlName="content"
      [maxLength]="1000"
      [rows]="3"
      [placeholder]="'blog.post.commentForm.control.comment' |translate"
      [hint]="'blog.post.commentForm.hint' | translate: {
        length: form.controls.content.value?.length || 0
      }"
    />
  </div>

  <div class="pt-comment-form__actions">
    <ng-content />

    <button
      type="submit"
      puButton
      size="m"
      uiType="primary"
      [disabled]="!form.controls.content.value">
      {{ 'blog.post.commentForm.control.send' | translate }}
    </button>
  </div>
</form>
