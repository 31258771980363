import { DictAchievement, TeamAward } from '@pt/models';
import { fillArr } from '@pinup-teams/common';
import { environment } from '@pt/environment';

export const getTeamAwardsMock = (count = 12): TeamAward[] => {
  return fillArr(count, i => ({
    colleague: {
      id: i,
      fullName: 'Aleksander Sidorov',
      imageUrl: `${environment.assetsHost}/assets/images/user-avatar-mock.jpg`,
      position: 'Head of QA',
      email: 'a.sidorov@company.team',
    },
    awards: getAchievementMock(),
  }));
};

export const getAchievementMock = (count = 5): DictAchievement[] => {
  return fillArr(count, i => ({
    id: i,
    name: `Achievement-${i}`,
    image: {
      id: i,
      url: `${environment.assetsHost}/assets/images/mock-achievement.png`,
      name: `Achievement-${i}.png`,
    },
  }));
};
