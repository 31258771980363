import { delay, Observable, of } from 'rxjs';

import { GetSegmentsSelectOptionsResModel } from '../models';

export function segmentsMock(): Observable<GetSegmentsSelectOptionsResModel> {
  return of({ segments: ['SEGMENT.Business', 'SEGMENT.CRM', 'SEGMENT.Play', 'SEGMENT.Tech'] })
    .pipe(
      delay(500),
    );
}
