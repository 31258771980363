import { ChangeDetectionStrategy, Component, inject, input, OnInit, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';

import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonModule,
  DropdownModule,
  EmojiModule,
  IconModule,
  TextAreaComponentModule,
} from '@pinup-teams/common';
import { RootState, UiSelectors } from '@pt/store';

@Component({
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    ButtonModule,
    TextAreaComponentModule,
    EmojiModule,
    DropdownModule,
    IconModule,
    PickerModule,
  ],
  selector: 'pt-comment-form',
  templateUrl: './comment-form.component.html',
  styleUrl: './comment-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentFormComponent implements OnInit {
  readonly #formBuilder = inject(FormBuilder);
  readonly #store = inject(Store<RootState>);
  content = input<string>();

  formSubmitted = output<string>();

  form = this.#formBuilder.group({
    content: '',
  });

  isDarkTheme = toSignal(this.#store.select(UiSelectors.selectIsDarkTheme));

  ngOnInit(): void {
    this.form.patchValue({ content: this.content() });
  }

  selectEmoji({ emoji }: EmojiEvent): void {
    this.form.patchValue({ content: `${this.form.value.content || ''}${emoji.native}` });
  }

  onSubmit(): void {
    this.formSubmitted.emit(this.form.value.content);
    this.form.reset();
  }
}
