import { inject, Injectable } from '@angular/core';

import { environment } from '@pt/environment';
import { WINDOW } from '@pinup-teams/common';
import { ProductCart, ProductOption, ProductShop } from '@pt/models';
import { TextFormaterHelper } from '@pt/helpers';
import { DocumentTitleService } from './document-title.service';
import { filter, take, map, switchMap, combineLatest, of } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

interface ShopEventProductDetails {
  product: ProductShop | ProductCart;
  option: ProductOption | null;
  quantity: number;
}

interface EcommerceEventProductsDetails {
  products: ShopEventProductDetails[];
  transactionId?: number;
}

export type GoogleAnalyticEventName = 'news'
  | 'shop'
  | 'product_purchase'
  | 'quest_catalogue'
  | 'clubs'
  | 'search'
  | 'birthdays'
  | 'share_pincoins'
  | 'header'
  | 'contact_support'
  | 'blog'
  | 'error'
  | 'view_item'
  | 'add_to_cart'
  | 'begin_checkout'
  | 'add_to_wishlist'
  | 'purchase'
  | 'page_view';

export type GoogleAnalyticEventAction = 'external_links'
  | 'save_article'
  | 'comment_added'
  | 'reaction_added'
  | 'materials_open'
  | 'materials_download'
  | 'comments_loaded'
  | 'add_to_cart'
  | 'buy_now'
  | 'add_to_wishlist'
  | 'place_order_button'
  | 'success'
  | 'take_part'
  | 'location_click'
  | 'request_new_club'
  | 'input_text'
  | 'result_click'
  | 'click'
  | 'open'
  | 'close'
  | 'share_button'
  | 'cancel_button'
  | 'send_button'
  | 'browse_image_button'
  | 'follow_button'
  | 'toast'
  | 'ecommerce'
  | 'category_tabs_click';

interface CustomGoogleAnalyticEvent {
  event: GoogleAnalyticEventName;
  eventCategory: GoogleAnalyticEventName;
  eventAction: GoogleAnalyticEventAction;
  text?: string | number;
  url?: string;
  sum?: number;
  item_category?: string;
  item_variant?: string;
  item_id?: string;
  item_name?: string;
  quantity?: number;
  status?: string;
  id?: string;
  element_name?: string;
  mode?: string;
  category?: string;
  page_title?: string;
  page_path?: string;
}

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  readonly #window = inject<Window & { dataLayer: any[] }>(WINDOW);
  readonly #documentTitleService = inject(DocumentTitleService);
  readonly #router = inject(Router);

  constructor() {
    if (environment.googleAnalyticsId) {
      this.#initAnalytics();
      this.#initPageViewTracking();
    }
  }

  pushCustomEvent(
    event: GoogleAnalyticEventName,
    eventAction: GoogleAnalyticEventAction | null,
    payload?: Partial<CustomGoogleAnalyticEvent>,
  ): void {
    if (!environment.googleAnalyticsId) return;

    this.#window.dataLayer = this.#window.dataLayer || [];
    this.#window.dataLayer.push({
      event,
      eventCategory: event,
      eventAction,
      ...(payload ?? {}),
    });
  }

  pushPlaceOrderEvent(action: any): void {
    if (!environment.googleAnalyticsId) return;

    this.#window.dataLayer = this.#window.dataLayer || [];
    this.#window.dataLayer.push({
      event: 'shop',
      eventCategory: 'shop',
      eventAction: 'place_order_button',
      sum: action.initiator.total,
      status: action.error?.code
        ? TextFormaterHelper.transformToSnakeCase(action.error.code)
        : 'success',
      quantity: action.initiator.products.reduce(
        (acc: number, el: ProductCart) => acc += el.quantity, 0,
      ),
    });
  }

  pushShopEvent(
    event: GoogleAnalyticEventName,
    eventAction: GoogleAnalyticEventAction,
    { product, option, quantity }: ShopEventProductDetails,
    payload?: Partial<CustomGoogleAnalyticEvent>,
  ): void {
    if (!environment.googleAnalyticsId) return;

    this.#window.dataLayer = this.#window.dataLayer || [];
    this.#window.dataLayer.push({
      event,
      eventCategory: event,
      eventAction,
      sum: product.price,
      item_category: '',
      item_variant: TextFormaterHelper.transformToSnakeCase(option?.optionColor.name ?? ''),
      item_name: TextFormaterHelper.transformToSnakeCase(product.name),
      item_id: `${product.id}`,
      quantity,
      ...(payload ?? {}),
    });
  }

  pushEcommerceEvent(
    event: GoogleAnalyticEventName,
    ecommerceEventProductsDetails: EcommerceEventProductsDetails,
  ): void {
    if (!environment.googleAnalyticsId) return;

    this.#window.dataLayer = this.#window.dataLayer || [];
    this.#window.dataLayer.push({ ecommerce: null });

    const productsSum = ecommerceEventProductsDetails.products.reduce(
      (acc, el) => acc += el.product.price,
      0,
    );

    this.#window.dataLayer.push({
      event,
      eventCategory: event,
      eventAction: 'ecommerce',
      sum: productsSum,
      ecommerce: {
        currency: 'USD',
        ...(ecommerceEventProductsDetails.transactionId
          && { transaction_id: `${ecommerceEventProductsDetails.transactionId}` }),
        value: productsSum,
        items: ecommerceEventProductsDetails.products.map(shopProduct => ({
          item_id: `${shopProduct.product.id}`,
          item_name: TextFormaterHelper.transformToSnakeCase(shopProduct.product.name),
          item_variant: TextFormaterHelper.transformToSnakeCase(
            shopProduct.option?.optionColor.name ?? '',
          ),
          affiliation: environment.affiliation || '',
          item_category: '',
          price: shopProduct.product.price,
          quantity: shopProduct.quantity,
        })),
      },
    });
  }

  private _loadGoogleAnalytics(): void {
    this._createGoogleScript();
    this._createGoogleNoScript();
  }

  private _createGoogleScript(): void {
    const startComment = document.createComment(' Google Tag Manager ');
    const endComment = document.createComment(' End Google Tag Manager ');
    const scriptEl = document.createElement('script');
    const fragment = document.createDocumentFragment();

    scriptEl.textContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${environment.googleAnalyticsId}');(function(){
      window.dataLayer = window.dataLayer ||[];
      function gtag() {dataLayer.push(arguments);} 
      gtag('js', new Date());
      gtag('config', '${environment.googleAnalyticsId}', {send_page_view: false}) 
      }());
    `;

    fragment.appendChild(startComment);
    fragment.appendChild(scriptEl);
    fragment.appendChild(endComment);

    document.head.insertBefore(fragment, document.head.firstChild);
  }

  private _createGoogleNoScript(): void {
    const startComment = document.createComment(' Google Tag Manager (noscript) ');
    const endComment = document.createComment(' End Google Tag Manager (noscript) ');
    const noScriptEl = document.createElement('noscript');
    const iframeEl = document.createElement('iframe');
    const fragment = document.createDocumentFragment();

    iframeEl.src = `https://www.googletagmanager.com/ns.html?id=${environment.googleAnalyticsId}`;
    iframeEl.height = '0';
    iframeEl.width = '0';
    iframeEl.style.display = 'none';
    iframeEl.style.visibility = 'hidden';

    noScriptEl.appendChild(iframeEl);

    fragment.appendChild(startComment);
    fragment.appendChild(noScriptEl);
    fragment.appendChild(endComment);

    document.body.insertBefore(fragment, document.body.firstChild);
  }

  #initPageViewTracking() {
    this.#router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      switchMap((event) => combineLatest([of(event), this.#documentTitleService.title.pipe(take(1))])),
      map((event) => this.#trackPageView((event[0] as NavigationEnd).urlAfterRedirects, event[1]))
    ).subscribe();
  }

  #initAnalytics() {
    this.#documentTitleService.title.pipe(
      filter(title => !!title),
      take(1),
      map(() => this._loadGoogleAnalytics())
    ).subscribe();
  }

  #trackPageView(url: string, title: string) {
    this.pushCustomEvent('page_view', null, {
      page_path: url,
      page_title: title
    });
  }
}
