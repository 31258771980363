import { RubricTag } from '@pt/models';

export function getTagsMock(count = 12): RubricTag[] {
  const tags: RubricTag[] = [];

  tags.push({
    id: null,
    name: 'All',
  });

  for (let i = 1; i <= count; i++) {
    tags.push({
      id: i,
      name: `Tag ${i}`,
    });
  }

  tags.push({
    id: tags.length + 1,
    name: 'Coins',
    code: 'coins',
  });

  return tags;
}
