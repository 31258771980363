import { of, delay, Observable } from 'rxjs';
import { environment } from '@pt/environment';
import { CommentModel } from '@pt/models';
import { fillArr } from '@pinup-teams/common';

import { getRandomCount, getReactions } from '../../../mocks';

const getReplies = (count = 3): CommentModel[] => {
  return fillArr(count, i => ({
    id: i,
    article: i,
    user: {
      id: i,
      fullName: `User ${i}`,
      imageUrl: `${environment.assetsHost}/assets/images/user-avatar-mock.jpg`,
      position: `Position ${i}`,
      email: '',
    },
    content: `This is a comment content for comment ${i}`,
    createdAt: new Date().toString(),
    updatedAt: Math.random() > 0.5 ? new Date().toString() : null,
    deletedAt: Math.random() > 0.5 ? new Date().toString() : null,
    parentCommentId: 1,
    repliesCount: 0,
    replies: [] as CommentModel[],
  }));
};

export const getCommentMock = (id: number): Observable<CommentModel> => {
  return of({
    id,
    article: id,
    user: {
      id,
      fullName: `User ${id}`,
      imageUrl: `${environment.assetsHost}/assets/images/user-avatar-mock.jpg`,
      position: `Position ${id}`,
      email: '',
    },
    content: `This is a comment content for comment ${id}`,
    createdAt: new Date().toString(),
    updatedAt: Math.random() > 0.5 ? new Date().toString() : null,
    deletedAt: Math.random() > 0.5 ? new Date().toString() : null,
    parentCommentId: 0,
    repliesCount: Math.floor(Math.random() * 10),
    replies: getReplies(getRandomCount(10)),
    reactions: getReactions(getRandomCount(8)),
  }).pipe(delay(500));
};
