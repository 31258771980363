<div class="switch-buttons switch-buttons-{{ size() }}">
  @for (option of options(); track option.id) {
    <div
      class="switch-buttons__item switch-buttons__item-{{ size() }}"
      [class.active]="option.id === selectedOption()"
      (click)="selectOption(option.id)"
    >
      @if (option.icon) {
        <pu-icon
          [iconName]="option.icon"
          [size]="iconSize()"
        />
      }
      <span class="switch-buttons__label">
        {{ option.label | translate }}
      </span>
    </div>
  }
</div>
