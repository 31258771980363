<form
  class="pu-tfa__body"
  [formGroup]="form"
  (ngSubmit)="verify()"
>
  <pt-small-logo/>

  <h4
    class="pu-tfa__header"
    translate="tfa.header"
  >
  </h4>

  <p
    class="pu-tfa__message"
    [translate]="'tfa.message1'"
  >
  </p>

  <ng-container *ngIf="manualSetupUri">
    <div class="pu-tfa__qrcode-box">
      <a
        class="pu-tfa__qrcode"
        [href]="manualSetupUri"
      >
        <qrcode
          [qrdata]="manualSetupUri"
          [width]="128"
          [errorCorrectionLevel]="'M'"
        />
      </a>

      <div class="pu-tfa__instructions">
        <p class="pu-tfa__instruction">
          <span translate="tfa.instruction11"></span>&nbsp;

          <a
            class="pu-tfa__instruction"
            target="_blank"
            href="https://authy.com"
          >
            Authy
          </a>,&nbsp;

          <a
            class="pu-tfa__instruction"
            target="_blank"
            [href]="isIOS ? 'https://apps.apple.com/ru/app/google-authenticator/id388497605' : 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'"
          >
            Google Authenticator
          </a>&nbsp;

          <span translate="tfa.instruction12"></span>
        </p>

        <p
          class="pu-tfa__instruction"
          translate="tfa.instruction2"
        >
        </p>

        <p
          class="pu-tfa__instruction"
          translate="tfa.instruction3"
        >
        </p>
      </div>
    </div>
  </ng-container>

  <pu-simple-input
    class="pu-form-container"
    formControlName="code"
    [errorDictionary]="errorDictionary"
    [hasAutofocus]="true"
    [label]="'tfa.control.code' | translate"
  />

  <div class="pu-tfa__actions">
    <button
      type="submit"
      puButton
      size="l"
      uiType="primary"
      [disabled]="!form.valid || isLoading"
      translate="tfa.btn.verify"
    >
    </button>
  </div>
</form>
