import { ChangeDetectionStrategy, Component, inject, input, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { environment } from '@pt/environment';
import { CommentModel } from '@pt/models';
import {
  ButtonModule,
  CommonMediaQueries,
  MediaScreenService,
  SpinnerModule,
} from '@pinup-teams/common';
import { InfiniteScrollDirective } from '@pt/directives';
import { Store } from '@ngrx/store';
import { RootState, UiSelectors } from '@pt/store';
import { GoogleAnalyticEventName, GoogleAnalyticsService } from '@pt/services';

import { COMMENTS_API, CommentsApiMockService, CommentsApiService } from './api';
import { CommentComponent, CommentFormComponent } from './components';
import { CommentsStore } from './comments.store';
import { ThreadDialog } from './components/thread/thread.dialog';

@Component({
  selector: 'pt-comments',
  templateUrl: './comments.component.html',
  styleUrl: './comments.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    CommentFormComponent,
    CommentComponent,
    SpinnerModule,
    InfiniteScrollDirective,
    ButtonModule,
  ],
  providers: [
    {
      provide: COMMENTS_API,
      useClass: environment.useMocks ? CommentsApiMockService : CommentsApiService,
    },
    CommentsStore,
    ThreadDialog,
  ],
})
export class CommentsComponent implements OnInit {
  readonly #commentsStore = inject(CommentsStore);
  readonly #store = inject(Store<RootState>);
  readonly #mediaScreenService = inject(MediaScreenService);
  readonly #viewportScroller = inject(ViewportScroller);
  readonly #googleAnalyticsService = inject(GoogleAnalyticsService);
  readonly #route = inject(ActivatedRoute);

  comments = this.#commentsStore.comments;
  itemsCount = this.#commentsStore.itemsCount;
  isLoading = this.#commentsStore.isLoading;
  isDesktop = toSignal(this.#mediaScreenService.mediaMatcher(CommonMediaQueries.LG));
  isNavbarHidden = toSignal(this.#store.select(UiSelectors.selectIsNavbarHiddenByScroll));
  headerHeight = toSignal(this.#store.select(UiSelectors.selectHeaderHeight));
  postId = input<number>();
  googleAnalyticEvent = input<GoogleAnalyticEventName>();
  editingCommentId: number;
  showScrollTopBtn = false;

  ngOnInit(): void {
    this.#commentsStore.setPostId(this.postId());
    this.#viewportScroller.setOffset([0, this.headerHeight()]);
  }

  isEditing(commentId: number): boolean {
    return this.editingCommentId === commentId;
  }

  onEdit(commentId: number): void {
    this.editingCommentId = commentId;
  }

  cancelEdit(): void {
    this.editingCommentId = null;
  }

  onReply(comment: CommentModel): void {
    this.#commentsStore.openThreadDialog(comment);
  }

  onDelete(comment: CommentModel) {
    this.#commentsStore.deleteComment(comment);
    this.cancelEdit();
  }

  onSave(content: string, comment?: CommentModel): void {
    const updatedComment = {
      ...comment,
      content,
      article: this.postId(),
    };

    this.#commentsStore.saveComment(updatedComment);
    if (comment) {
      this.cancelEdit();
    } else {
      this.#viewportScroller.scrollToAnchor('comments');
    }

    if (this.googleAnalyticEvent()) {
      this.#googleAnalyticsService.pushCustomEvent(this.googleAnalyticEvent(), 'comment_added', {
        text: content,
        ...(this.googleAnalyticEvent() === 'news' && {
          category: this.#route.snapshot.queryParams['categoryTagName'] ?? 'All',
        }),
      });
    }
  }

  loadMore(): void {
    this.#commentsStore.updateFilters({
      page: this.#commentsStore.filters().page + 1,
    });
  }

  onScrollPossitionChanged(scrollPosition: number): void {
    this.showScrollTopBtn = scrollPosition > 300;
  }

  scrollToPost(): void {
    this.#viewportScroller.scrollToPosition([0, 0]);
  }
}
