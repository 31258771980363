import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { MAX_FILE_SIZE } from '@pt/constants';
import { RootState } from '@pt/store';
import {
  ButtonModule,
  DialogRef,
  IconModule,
  ImageModel,
  SpinnerModule,
  TextAreaComponentModule,
  UploaderModule,
} from '@pinup-teams/common';
import { SupportContact } from '@pt/models';
import { FormBuilder, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { GroupResolverFormBuilder } from '@ngneat/reactive-forms/lib/form-builder';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '@pt/environment';
import { GoogleAnalyticsService } from '@pt/services';
import { asyncScheduler } from 'rxjs';

import { SupportActions } from '../../store';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'pu-support-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    SpinnerModule,
    ButtonModule,
    IconModule,
    TextAreaComponentModule,
    SpinnerModule,
    UploaderModule,
  ],
})
export class ContactComponent implements OnInit {
  readonly #googleAnalyticsService = inject(GoogleAnalyticsService);

  readonly MAX_FILE_SIZE = MAX_FILE_SIZE;

  assetsHost = environment.assetsHost;
  form: FormGroup<GroupResolverFormBuilder<SupportContact>>;
  imagesControl: FormControl<ImageModel[]>;
  isLoading = false;
  isClickInProgress = false;

  constructor(
    private _store: Store<RootState>,
    private _actionsSubject: ActionsSubject,
    private _fb: FormBuilder,
    private _dialogRef: DialogRef,
  ) {
  }

  ngOnInit(): void {
    this.form = this._fb.group({
      text: ['', Validators.required],
      images: [<any>[]],
    });

    this.imagesControl = <any> this.form.controls.images;

    this._actionsSubject.pipe(
      ofType(SupportActions.sendMessageReq.succeededAction),
      untilDestroyed(this),
    ).subscribe({
      next: () => {
        this.isLoading = false;
        this._dialogRef.close();
      },
    });

    this.#googleAnalyticsService.pushCustomEvent('contact_support', 'open');
  }

  send(): void {
    this.isLoading = true;
    this._store.dispatch(SupportActions.sendMessageReq.action({
      payload: { ...(<any> this.form.value) },
    }));
  }

  cancel(closedByIcon = false): void {
    this._dialogRef.close();

    this.#googleAnalyticsService.pushCustomEvent(
      'contact_support',
      closedByIcon ? 'close' : 'cancel_button',
    );
  }

  clickOnBrowserImage(): void {
    if (!this.isClickInProgress) {
      this.#googleAnalyticsService.pushCustomEvent('contact_support', 'browse_image_button');
      this.isClickInProgress = true;
    }

    asyncScheduler.schedule(() => {
      this.isClickInProgress = false;
    });
  }
}
