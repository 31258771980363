import {
  Component,
  ChangeDetectionStrategy,
  inject,
  signal,
  DestroyRef,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { environment } from '@pt/environment';
import { OnboardingPreview, UserOnboardingTaskDetails } from '@pt/models';
import { CommonMediaQueries, SliderControlsModule } from '@pinup-teams/common';
import { SwiperModule } from 'swiper/angular';
import { OnboardingStageComponent, OnboardingTaskDetailsDialog } from '@pt/components';
import { SwiperOptions, Mousewheel } from 'swiper';

import {
  ONBOARDING_PREVIEW_API,
  OnboardingPreviewApiMockService,
  OnboardingPreviewService,
} from './api';

export type TaskData = {
  task: UserOnboardingTaskDetails;
  userOnboardingId: number;
};

@Component({
  standalone: true,
  selector: 'pu-onboarding-preview',
  templateUrl: './onboarding-preview.component.html',
  styleUrls: ['./onboarding-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    OnboardingStageComponent,
    SwiperModule,
    SliderControlsModule,
  ],
  providers: [
    {
      provide: ONBOARDING_PREVIEW_API,
      useClass: environment.useMocks ? OnboardingPreviewApiMockService : OnboardingPreviewService,
    },
  ],
})
export class OnboardingPreviewComponent implements OnDestroy {
  readonly #router = inject(Router);
  readonly #destroyRef = inject(DestroyRef);
  readonly #onboardingPreviewService = inject(ONBOARDING_PREVIEW_API);
  readonly #onboardingTaskDetailsDialog = inject(OnboardingTaskDetailsDialog);

  readonly onboardingDetails = signal<OnboardingPreview>(null);
  readonly sliderConfig: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 16,
    grabCursor: true,
    modules: [Mousewheel],
    mousewheel: { forceToAxis: true },
    navigation: {
      prevEl: '.pt-onboarding__swiper-control-prev',
      nextEl: '.pt-onboarding__swiper-control-next',
    },
    breakpoints: {
      [CommonMediaQueries.MD]: {
        slidesPerView: 2,
        spaceBetween: 16,
      },
      [CommonMediaQueries.LG]: {
        slidesPerView: 'auto',
        spaceBetween: 32,
      },
    },
  };

  constructor() {
    // this needs to be executed in constructor
    // because in any other way router extras would be empty
    this.#getOnboardingDetails();
  }

  openTask(taskData: TaskData) {
    const { task, userOnboardingId } = taskData;

    task.completed = false;
    this.#onboardingTaskDetailsDialog.open({
      task,
      mode: 'preview',
      userOnboardingId,
    });
  }

  ngOnDestroy(): void {
    localStorage.removeItem('onboardingDetails');
  }

  #getOnboardingDetails(): void {
    if (localStorage.getItem('onboardingDetails')) {
      this.onboardingDetails.set(JSON.parse(localStorage.getItem('onboardingDetails')));
    } else if (this.#router.getCurrentNavigation()?.extras.state['id']) {
      this.#onboardingPreviewService.getOnboardingDetails(
        this.#router.getCurrentNavigation().extras.state['id'],
        this.#router.getCurrentNavigation().extras.state['onboardingType'],
      ).pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe(onboardingDetails => {
          this.onboardingDetails.set(onboardingDetails);
          localStorage.setItem('onboardingDetails', JSON.stringify(onboardingDetails));
        });
    } else {
      this.onboardingDetails.set(
        this.#router.getCurrentNavigation()?.extras.state || history.state,
      );
    }
  }
}
