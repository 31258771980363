import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, DialogRef, IconModule, LetModule, trackByIndex } from '@pinup-teams/common';
import { RouterActions } from '@pinup-teams/common';
import { Store } from '@ngrx/store';
import { environment } from '@pt/environment';
import { RootState } from '@pt/store';
import { ClipboardService } from '@pt/services';

// todo: 2FA doesn't work with import { SmallLogoComponent } from '@pt/components';
import {
  SmallLogoComponent,
} from '../../../../../core/src/lib/components/small-logo/small-logo.component';
import { AuthSelectors } from '../../store';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, ButtonModule, IconModule, LetModule, SmallLogoComponent],
  selector: 'pu-tfa-codes',
  templateUrl: './codes.component.html',
  styleUrls: ['./codes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodesComponent {
  trackByIndex = trackByIndex;
  codes$ = this._store.select(AuthSelectors.selectRecoveryCodes);

  constructor(
    private _store: Store<RootState>,
    private _clipboard: ClipboardService,
    private _dialogRef: DialogRef,
  ) {
  }

  ok(): void {
    this._store.dispatch(RouterActions.navigateByUrl({ url: '/' }));
    this._dialogRef.close();
  }

  copy(codes: string[]): void {
    this._clipboard.copy(
      `${environment.appName} Recovery codes: \n\n` + codes.join('\n'),
      'tfaCodes.codesCopied',
    );
  }
}
