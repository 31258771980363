import { createSelector } from '@ngrx/store';
import { Balance, RubricTag } from '@pt/models';

import { balanceFeature } from './balances.reducers';
import { TeamBonusesSelectors } from '../../team-bonuses/store';

const {
  selectBalance,
  selectTeamBalance,
  selectTags,
  selectGetBalanceAction,
  selectGetBalanceSkinsAction,
  selectApplyBalanceSkinAction,
  selectGetTagsAction,
} = balanceFeature;

export interface BalanceViewModel {
  userBalance: Balance;
  balances: Balance[];
  coinsTag: RubricTag;
}

const selectViewModel = createSelector(
  selectTags,
  selectBalance,
  selectTeamBalance,
  (tags, ...balances): BalanceViewModel => ({
    userBalance: balances.filter(Boolean).find(balance => balance.balanceType === 'regular'),
    balances: balances.filter(Boolean)
      .map(balance => ({ title: `balanceCard.title.${balance.balanceType}`, ...balance })),
    coinsTag: tags.tags.find(tag => tag.code === 'coins'),
  }),
);

const selectIsLoading = createSelector(
  selectGetBalanceSkinsAction,
  selectApplyBalanceSkinAction,
  selectGetTagsAction,
  TeamBonusesSelectors.selectSendAwardAction,
  TeamBonusesSelectors.selectSendBonusAction,
  (
    getBalanceSkinsAction,
    applyBalanceSkinAction,
    getTagsAction,
    sendAwardAction,
    sendBonusAction,
  ): boolean => getBalanceSkinsAction.inProgress
    || applyBalanceSkinAction.inProgress
    || getTagsAction.inProgress
    || sendAwardAction.inProgress
    || sendBonusAction.inProgress,
);

export const BalanceSelectors = {
  selectBalance,
  selectGetBalanceAction,
  selectApplyBalanceSkinAction,
  selectGetTagsAction,
  selectViewModel,
  selectIsLoading,
};
