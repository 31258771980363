import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function maxArrayLength(maxLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (Array.isArray(control.value)) {
      return control.value.length > maxLength
        ? { maxArrayLength: { maxLength, actualLength: control.value.length } }
        : null;
    }
    return null;
  };
}
