import { Injectable } from "@angular/core";
import { BehaviorSubject, filter, map, Observable, Subject, switchMap, tap } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class DocumentTitleService {
    #title$ = new Subject<string | null>();
    
    constructor() {
        const target = document.querySelector('head > title');
        const observer = new MutationObserver((mutations) => {
            mutations.filter(newTitleValue => !!newTitleValue).forEach((newTitleValue) =>  {
                this.#title$.next(newTitleValue.target.textContent);
            });
        });

        observer.observe(target, { subtree: true, characterData: true, childList: true });
    }

    get title(): Observable<string | null> {
        return this.#title$.asObservable();
    }
}