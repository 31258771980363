import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'pu-user-status',
  template: '{{ i18nPrefix + \'.\' + statusType | translate }}',
  styleUrls: ['./user-status.component.scss'],
})
export class UserStatusComponent {
  @Input() set type(statusType: string) {
    this.statusType = statusType;
    this._hostClasses = `pu-user-status pu-user-status_${this.statusType}`;
  }

  @Input() i18nPrefix: string;

  @HostBinding('class') private _hostClasses = '';

  statusType: string;
}
