import { Injectable } from '@angular/core';

import { delay, Observable, of } from 'rxjs';
import { OnboardingType } from '@pt/models';

import { OnboardingPreviewApi } from './onboarding-preview-api.interface';
import { getOnboardingDetailsMock } from '../mocks';
import { GetOnboardingDetailsRes } from '../models';

@Injectable()
export class OnboardingPreviewApiMockService implements OnboardingPreviewApi {
  getOnboardingDetails(
    templateId: number,
    onboardingType: OnboardingType,
  ): Observable<GetOnboardingDetailsRes> {
    return of(getOnboardingDetailsMock(templateId, onboardingType)).pipe(delay(500));
  }
}
