import {
  ChangeDetectionStrategy, Component, computed, effect, input, model,
} from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@pinup-teams/common';

import { SwitchButtonOption } from './models/switch-button-option.model';

@Component({
  selector: 'pt-switch-button',
  styleUrl: './switch-button.component.scss',
  templateUrl: './switch-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconModule, TranslateModule],
})
export class SwitchButtonComponent {
  options = input.required<SwitchButtonOption[]>();
  selectedOption = model<string>();
  size = input<'m' | 'l'>('l');

  iconSize = computed(() => (this.size() === 'm' ? '16' : '20'));

  constructor() {
    effect(() => {
      this.#defineSelectedOption();
    }, { allowSignalWrites: true });
  }

  selectOption(option: string): void {
    this.selectedOption.set(option);
  }

  #defineSelectedOption(): void {
    if (this.selectedOption()) {
      return;
    }
    const [autoDetectedSelectedOption] = this.options() ?? [];
    this.selectedOption.set(autoDetectedSelectedOption?.id);
  }
}
