<div class="pt-onboarding-task-details__header">
  <pu-icon
    class="pt-onboarding-task-details__completed-icon"
    iconName="pu-checkbox-yes"
    size="24"
    [@completedIcon]="completedIconAnimationState()"
  />

  <h4 class="pt-onboarding-task-details__header-text">
    {{ task()?.name }}
  </h4>

  <button
    class="pt-onboarding-task-details__close"
    type="button"
    puButton
    size="m"
    uiType="quaternary"
    [onlyIcon]="true"
    (click)="cancel()"
  >
    <pu-icon
      iconName="close"
      size="16"
    />
  </button>
</div>

<div class="pt-onboarding-task-details__body">
  @switch (taskGetter.confirmation) {
    @case ('manual') {
      @if (taskGetter.videoUrl) {
        <iframe
          class="pt-onboarding-task-details__video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          frameborder="0"
          [src]="taskGetter.videoUrl | safeUrl"
        >
        </iframe>
      } @else {
        <div class="pt-onboarding-task-details__image">
          <img
            ptImageLoader
            [src]="taskGetter.image.url"
            [alt]="taskGetter.image.name"
          >
        </div>
      }

      <div
        class="pt-onboarding-task-details__description"
        [froalaView]="task().description"
      ></div>

      @if (taskGetter.button) {
        <a
          type="button"
          target="_blank"
          puButton
          size="l"
          uiType="primary"
          [href]="taskGetter.button.url"
        >
          {{ taskGetter.button.name }}
        </a>
      }

      @if (taskGetter.pdfButton) {
        <a
          type="button"
          target="_blank"
          puButton
          size="l"
          uiType="primary"
          [href]="taskGetter.pdfButton.pdf.url"
        >
          {{ taskGetter.pdfButton.name }}
        </a>
      }

      @if (!isPreviewMode) {
        <mat-slide-toggle
          [formControl]="completedControl"
          [puDisabled]="completedControl.value"
        >
          {{ 'onboardingTask.controls.completed.label' | translate }}
        </mat-slide-toggle>
      }
    }
    @case ('auto') {
      <div class="pt-onboarding-task-details__image">
        @if (taskImgSrc) {
          <img
            ptImageLoader
            [src]="taskImgSrc"
            [alt]="$any(taskGetter).entity.mainImage?.name || $any(taskGetter).image?.name ?? ''"
          >
        }
      </div>

      <div
        class="pt-onboarding-task-details__description"
        [froalaView]="taskGetter.description"
      ></div>

      @if ($any(taskGetter).entity.reward) {
        <div class="pt-onboarding-task-details__reward">
          <span class="pt-onboarding-task-details__reward-label">{{
              'onboardingTask.reward' | translate }}:</span>
          <span class="pt-onboarding-task-details__reward-amount">
            {{ $any(taskGetter).entity.reward }}
            <pt-coin size="24"/>
          </span>
        </div>
      }

      <a
        type="button"
        puButton
        size="l"
        uiType="primary"
        [routerLink]="autoTaskLink"
        (click)="cancel()"
      >
        {{ taskBtnKey | translate }}
      </a>
    }
  }
</div>

<pt-confetti/>
