import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  OnInit,
  viewChild,
} from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonModule,
  DIALOG_DATA,
  DialogRef,
  IconModule,
  SpinnerModule,
} from '@pinup-teams/common';
import { CommentModel } from '@pt/models';
import { InfiniteScrollDirective } from '@pt/directives';
import { environment } from '@pt/environment';

import { CommentComponent, CommentFormComponent } from '..';
import { THREAD_API, ThreadApiMockService, ThreadApiService } from './api';
import { ThreadStore } from './thread.store';

@Component({
  selector: 'pt-thread',
  templateUrl: './thread.component.html',
  styleUrls: ['./thread.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    ButtonModule,
    IconModule,
    CommentComponent,
    CommentFormComponent,
    InfiniteScrollDirective,
    SpinnerModule,
  ],
  providers: [
    {
      provide: THREAD_API,
      useClass: environment.useMocks ? ThreadApiMockService : ThreadApiService,
    },
    ThreadStore,
  ],
})
export class ThreadComponent implements OnInit {
  readonly #data = inject<CommentModel>(DIALOG_DATA);
  readonly #store = inject(ThreadStore);
  readonly #dialogRef = inject(DialogRef);
  scrolledElem = viewChild<ElementRef>('scrollRef');

  comment = this.#store.comment;
  replies = this.#store.replies;
  isLoading = this.#store.isLoading;

  editingReplyId: number;
  #isDirty = false;

  ngOnInit(): void {
    this.#store.setComment(this.#data);
  }

  isEditing(replyId: number): boolean {
    return this.editingReplyId === replyId;
  }

  onEdit(replyId: number): void {
    this.editingReplyId = replyId;
  }

  cancelEdit(): void {
    this.editingReplyId = null;
  }

  onDelete(reply: CommentModel) {
    this.#store.deleteReply(reply);
    this.markAsDirty();
    this.cancelEdit();
  }

  onSave(content: string, reply?: CommentModel): void {
    const updatedReply = {
      ...reply,
      content,
      article: this.comment().article,
      parentCommentId: this.comment().id,
    };

    this.#store.saveReply(updatedReply);
    this.markAsDirty();
    this.cancelEdit();

    if (!updatedReply.id) {
      this.#scrollToTop();
    }
  }

  loadMore(): void {
    this.#store.updateFilters({
      page: this.#store.filters().page + 1,
    });
  }

  onClose(): void {
    this.#dialogRef.close(this.#isDirty);
  }

  markAsDirty(): void {
    this.#isDirty = true;
  }

  #scrollToTop(): void {
    this.scrolledElem().nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
