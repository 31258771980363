import { Observable, of, delay, share } from 'rxjs';
import { UserProfile } from '@pt/models';
import { fillArr } from '@pinup-teams/common';
import { environment } from '@pt/environment';

import { GetUsersReactionsRes } from '../models';

const emojis = ['👍', '👀', '✅', '🔥', '😂', '😢', '👏', '🎉'];

const getUsersMock = (count = 12): UserProfile[] => {
  return fillArr(count, i => ({
    id: i,
    fullName: `User ${i}`,
    imageUrl: `${environment.assetsHost}/assets/images/user-avatar-mock.jpg`,
    position: `Position ${i}`,
    email: '',
  }));
};

export const getUsersReactionsMock = (count = 12): Observable<GetUsersReactionsRes> => {
  const reactions = emojis.map(emoji => {
    const users = getUsersMock(count);
    return {
      emoji,
      count: users.length,
      users,
    };
  });

  return of({ reactions }).pipe(delay(500), share());
};
