import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject,
  viewChild,
} from '@angular/core';

import { WINDOW } from '@pinup-teams/common';
import { environment } from '@pt/environment';

@Component({
  selector: 'pt-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent implements AfterViewInit {
  readonly #window = inject(WINDOW);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  readonly svgUse = viewChild<ElementRef>('idSvgUse');
  readonly assetsHost = environment.assetsHost;
  readonly logoName = `${environment.shortName}-logo`;

  logoWidth = 0;

  ngAfterViewInit(): void {
    this.#window.queueMicrotask(() => {
      this.logoWidth = this.svgUse().nativeElement?.getBBox().width || 0;
      this.#changeDetectorRef.markForCheck();
    });
  }
}
