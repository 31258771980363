import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, IconModule, DIALOG_DATA, DialogRef } from '@pinup-teams/common';
import { User } from '@pt/models';

import { TransactionsModule } from '../../transactions.module';

interface DialogData {
  user: User;
  isAdmin: boolean;
  forTeam: boolean;
  myTeam: boolean;
}

@Component({
  standalone: true,
  selector: 'pt-transactions-dialog',
  templateUrl: './transactions-dialog.component.html',
  styleUrls: ['./transactions-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, ButtonModule, IconModule, TransactionsModule],
})
export class TransactionsDialogComponent {
  readonly data = inject<DialogData>(DIALOG_DATA);
  private readonly _dialogRef = inject(DialogRef);

  close(): void {
    this._dialogRef.close();
  }
}
