import {
  booleanAttribute,
  ChangeDetectionStrategy, Component, input, output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { IconModule, ImageModel } from '@pinup-teams/common';
import { ImageLoaderDirective } from '@pt/directives';
import { ArticleType, RubricTag } from '@pt/models';

export interface ArticleCardItem {
  id: number;
  title: string;
  previewImage: ImageModel;
  image: ImageModel;
  previewText: string;
  publishedAt: string;
  articleType: ArticleType;
  read?: boolean;
  saved?: boolean;
  tag?: RubricTag;
  commentsCount?: number;
}

@Component({
  standalone: true,
  selector: 'pt-article-card, [ptArticleCard]',
  imports: [CommonModule, RouterModule, TranslateModule, IconModule, ImageLoaderDirective],
  templateUrl: './article-card.component.html',
  styleUrls: ['./article-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleCardComponent {
  item = input.required<ArticleCardItem>();
  categoryTagName = input<string>();
  widget = input(false, { transform: booleanAttribute });
  link = input<string>();

  toggleSaved = output<void>();
}
