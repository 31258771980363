<div class="pu-pwa-setup__header">
  <h4
    class="pu-pwa-setup__header-text"
    translate="pwaSetupDialog.header"
  >
  </h4>

  <button
    class="pu-pwa-setup__close"
    type="button"
    puButton
    size="m"
    uiType="quaternary"
    [onlyIcon]="true"
    (click)="close()"
  >
    <pu-icon
      iconName="close"
      size="16"
    />
  </button>
</div>

<div class="pu-pwa-setup__body">
  <div class="pu-pwa-setup__body-preview-img">
    <img
      [src]="assetsHost() + '/assets/app-images/pwa/preview/preview.png'"
      [srcset]="srcSet()"
      [sizes]="'(min-width: 768px) 400px, 343px'"
      alt="Preview"
      height="225"
      width="400"
    />
  </div>
  @if (isIOS()) {
    <p class="pu-pwa-setup__text">
      {{ 'pwaSetupDialog.ios.setupStep1' | translate }}
      <pu-icon
        class="pu-pwa-setup__text-icon"
        iconName="share"
        size="16"
      />
    </p>

    <p class="pu-pwa-setup__text">
      {{ 'pwaSetupDialog.ios.setupStep2' | translate }}
      <pu-icon
        class="pu-pwa-setup__text-icon"
        iconName="filled-plus"
        size="16"
      />
    </p>
  } @else {
    <p class="pu-pwa-setup__text">
      {{ 'pwaSetupDialog.setupStep1' | translate }}
      <pu-icon
        class="pu-pwa-setup__text-icon"
        iconName="dots"
        size="16"
      />
    </p>

    <p class="pu-pwa-setup__text">
      {{ 'pwaSetupDialog.setupStep2' | translate }}
      <pu-icon
        class="pu-pwa-setup__text-icon"
        iconName="app"
        size="16"
      />
    </p>
  }

  <p
    class="pu-pwa-setup__text"
    translate="pwaSetupDialog.setupStep3"
  >
  </p>

  @if (canInstallPwa() || isInstalling()) {
    <hr class="pu-pwa-setup__body-divider">
    <p
      class="pu-pwa-setup__body-step"
      translate="pwaSetupDialog.setupStep4"
    >
    </p>

    <button
      class="pu-pwa-setup__body-install-btn"
      type="button"
      puButton
      size="m"
      uiType="primary"
      (click)="installPwa()"
    >
      {{ 'pwaSetupDialog.install' | translate }}
    </button>
  }
</div>
