import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { getQueryParams } from '@pinup-teams/common';
import { GroupTagEnum } from '@pt/models';
import { Observable } from 'rxjs';
import { environment } from '@pt/environment';

import {
  getGroupsMock, getLocationsSelectOptionsMock, positionsMock, segmentsMock, teamsMock,
} from './mocks';
import {
  GetGroupsRes, GetLocationsSelectOptionsRes, GetPositionsSelectOptionsResModel,
  GetSegmentsSelectOptionsResModel,
  GetTeamsSelectOptionsResModel,
} from './models';

@Injectable({ providedIn: 'root' })
export class UserApiService {
  readonly #http = inject(HttpClient);
  readonly #selectOptionsApiPath = `${environment.apiHost}user/select-options/`;

  getUserGroups(params: {
    tagTypes: GroupTagEnum | string;
  }): Observable<GetGroupsRes> {
    return environment.useMocks
      ? getGroupsMock()
      : this.#http.get<GetGroupsRes>(
        `${environment.apiHost}groups/admin/groups-select/`,
        { params: getQueryParams(params) },
      );
  }

  getLocationsSelectOptions(): Observable<GetLocationsSelectOptionsRes> {
    return environment.useMocks
      ? getLocationsSelectOptionsMock()
      : this.#http.get<GetLocationsSelectOptionsRes>(
        `${this.#selectOptionsApiPath}locations/`,
      );
  }

  getSegmentsSelectOptions(): Observable<GetSegmentsSelectOptionsResModel> {
    return environment.useMocks
      ? segmentsMock()
      : this.#http.get<GetSegmentsSelectOptionsResModel>(`${this.#selectOptionsApiPath}segments/`);
  }

  getTeamsSelectOptions(): Observable<GetTeamsSelectOptionsResModel> {
    return environment.useMocks
      ? teamsMock()
      : this.#http.get<GetTeamsSelectOptionsResModel>(`${this.#selectOptionsApiPath}teams/`);
  }

  getPositionsSelectOptions(): Observable<GetPositionsSelectOptionsResModel> {
    return environment.useMocks
      ? positionsMock()
      : this.#http.get<GetPositionsSelectOptionsResModel>(
        `${this.#selectOptionsApiPath}positions/`,
      );
  }
}
