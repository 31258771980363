import { getImgMock, randomNum } from '@pinup-teams/common';
import { OnboardingPreviewTask, OnboardingType } from '@pt/models';

import { GetOnboardingDetailsRes } from '../models';

export function getOnboardingDetailsMock(
  id: number,
  onboardingType: OnboardingType,
): GetOnboardingDetailsRes {
  const baseInfo = {
    id,
    name: `Task name ${id}`,
    description: `Task description ${id}`,
    image: getImgMock(),
    videoUrl: 'https://www.youtube.com/embed/o74WSoJxGPI?si=HVofDy3G6vpVi6Cq',
    button: {
      name: `Button name ${id}`,
      url: 'https://www.youtube.com/watch?v=o74WSoJxGPI&ab_channel=DecodedFrontend',
    },
    completed: !!(randomNum(2, 1) % 2),
  };
  const options: OnboardingPreviewTask[] = [
    {
      ...baseInfo,
      pdfButton: { name: 'Open PDF', pdf: getImgMock() },
      confirmation: 'manual',
    },
    {
      ...baseInfo,
      entity: { id: 1, type: 'course', mainImage: getImgMock() },
      confirmation: 'auto',
    },
    {
      ...baseInfo,
      entity: { id: 1, type: 'quest', mainImage: getImgMock() },
      confirmation: 'auto',
    },
    {
      ...baseInfo,
      entity: { id: 1, type: 'questionnaire', mainImage: getImgMock() },
      confirmation: 'auto',
    },
  ];

  return {
    stages: [
      {
        id,
        onboardingType,
        status: 'notCompleted',
        name: `Stage name ${id}`,
        subtitle: `Stage subtitle ${id}`,
        reward: 100,
        achievement: {
          id,
          name: `Achievement name ${id}`,
          description: `Achievement description ${id}`,
          image: getImgMock(),
        },
        requiredTasks: [options[randomNum(3, 0)]],
        optionalTasks: [options[randomNum(3, 0)]],
      },
    ],
  };
}
