<div
  class="pt-bar-chart__wrapper"
  #chartWrapper
  (puResize)="svgResized($event)"
>
</div>

<div class="pt-bar-chart__legend">
  @for (legendItem of legend(); track legendItem.name) {
    <div class="pt-bar-chart__legend-item">
      <div class="pt-bar-chart__legend-item__marker" [style.background-color]="legendItem.color"></div>
      <div class="pt-bar-chart__legend-item__name">{{ legendItem.name }}</div>
    </div>
  }
</div>
