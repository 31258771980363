import { combineReducers, createReducer, on } from '@ngrx/store';
import { PersonalInfo } from '@pt/models';

import { AuthState } from './auth.state';
import { AuthActions } from './auth.actions';

const profileReducer = createReducer<PersonalInfo>(
  null,
  on(
    AuthActions.setProfile,
    (state, { profile }): PersonalInfo => (profile ? addFakeYear(profile) : null),
  ),
);

const recoveryCodesReducer = createReducer(
  [],
  on(
    AuthActions.enable2fa.succeededAction,
    (state, { payload: { recoveryCodes } }): string[] => recoveryCodes,
  ),
);

const is2faEnabledReducer = createReducer(
  false,
  on(AuthActions.remove2fa.succeededAction, (): boolean => false),
  on(AuthActions.signIn.succeededAction, (state, { payload }): boolean => payload.is2faEnabled),
);

export const authReducer = combineReducers<AuthState>({
  profile: profileReducer,
  recoveryCodes: recoveryCodesReducer,
  is2faEnabled: is2faEnabledReducer,
});

function addFakeYear(res: PersonalInfo): PersonalInfo {
  return { ...res, birthday: res.birthday ? `2077-${res.birthday}` : null };
}
