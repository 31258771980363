import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { environment } from '@pt/environment';
import { Observable } from 'rxjs';

import { OnboardingTaskDetailsApi } from './onboarding-task-details-api.inteface';
import { CompleteTaskReq, CompleteTaskRes } from '../models';

@Injectable()
export class OnboardingTaskDetailsService implements OnboardingTaskDetailsApi {
  readonly #httpClient = inject(HttpClient);

  completeTask(params: CompleteTaskReq): Observable<CompleteTaskRes> {
    return this.#httpClient.post<CompleteTaskRes>(
      `${environment.apiHost}onboarding/my-onboardings/tasks/`,
      { taskId: params.id, userOnboardingId: params.userOnboardingId },
    );
  }
}
