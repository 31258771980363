import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { OnboardingTaskDetailsApi } from './onboarding-task-details-api.inteface';
import { CompleteTaskReq, CompleteTaskRes } from '../models';

@Injectable()
export class OnboardingTaskDetailsApiMockService implements OnboardingTaskDetailsApi {
  completeTask(params: CompleteTaskReq): Observable<CompleteTaskRes> {
    return of(null);
  }
}
