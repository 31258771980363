import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  ButtonModule, IconModule, IncrementModule, LetModule, SelectModule, TextAreaComponentModule,
} from '@pinup-teams/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { CoinComponent, UserProfileCardModule } from '@pt/components';

import { TeamBonusesComponent } from './team-bonuses.component';
import { AwardsFormComponent } from './components/awards-form/awards-form.component';
import { BonusFormComponent } from './components/bonus-form/bonus-form.component';
import { TeamBonusesEffects, teamBonusesFeature } from './store';
import { TeamBonusesDialog } from './team-bonuses.dialog';

@NgModule({
  declarations: [TeamBonusesComponent, AwardsFormComponent, BonusFormComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    ButtonModule,
    IconModule,
    LetModule,
    UserProfileCardModule,
    IncrementModule,
    SelectModule,
    TextAreaComponentModule,
    StoreModule.forFeature(teamBonusesFeature),
    EffectsModule.forFeature([TeamBonusesEffects]),
    CoinComponent,
  ],
  providers: [TeamBonusesDialog],
  exports: [TeamBonusesComponent],
})
export class TeamBonusesModule {
}
