import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, FroalaModule, IconModule, PopoverDirective } from '@pinup-teams/common';
import { AvatarModule, ReactionsComponent, UserProfileCardModule } from '@pt/components';
import { Store } from '@ngrx/store';
import { AuthSelectors } from '@pt/auth';
import { CommentModel, ReactionType } from '@pt/models';

import { CommentFormComponent } from '../comment-form/comment-form.component';

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    FroalaModule,
    UserProfileCardModule,
    DatePipe,
    CommentFormComponent,
    IconModule,
    ButtonModule,
    AvatarModule,
    ReactionsComponent,
    PopoverDirective,
  ],
  selector: 'pt-comment, [ptComment]',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentComponent {
  readonly #store = inject(Store);

  comment = input.required<CommentModel>();
  isEditing = input<boolean>(false);
  showReplyBtn = input(false, { transform: booleanAttribute });
  isParent = input(false, { transform: booleanAttribute });

  edit = output<number>();
  reply = output<CommentModel>();
  save = output<string>();
  cancel = output<void>();
  remove = output<CommentModel>();
  reactionsChanged = output<void>();

  currentUserId = toSignal(this.#store.select(AuthSelectors.selectUserId));
  isCommentOwner = computed(() => this.comment().user?.id === this.currentUserId());
  isDeleted = computed(() => !!this.comment().deletedAt);

  reactionType = ReactionType;
}
