import { Nullable, uniqueId } from '@pinup-teams/common';
import {
  axisBottom, axisLeft, create, max, ScaleBand, scaleBand, scaleLinear, ScaleLinear, ScaleOrdinal,
  scaleOrdinal, select, Selection, Transition,
} from 'd3';

import { BaseChart } from '../charts.abstraction';
import { Tooltip } from '../tooltip/tooltip';
import { BarChartConfig, BarChartData } from './bar-chart.abstraction';
import {
  BAR_CHART_DEFAULT_CONFIG, BAR_CHART_DEFAULT_DATA, BAR_CHART_TICKS_DISTANCE,
} from './bar-chart.constant';

export class VerticalBarChart implements BaseChart<BarChartConfig, BarChartData> {
  svg: Selection<SVGSVGElement, unknown, null, unknown>;
  data: BarChartData = BAR_CHART_DEFAULT_DATA;
  config: BarChartConfig = BAR_CHART_DEFAULT_CONFIG;
  colorScale: ScaleOrdinal<string, string> = scaleOrdinal([
    '#00cfa6',
    '#fac600',
    '#ff2400',
    '#ad00ff',
    '#2c99ff',
    '#ff005c',
    '#c1694f',
  ]);
  #tooltip = new Tooltip();
  #xAxis: Selection<SVGGElement, unknown, null, unknown>;
  #yAxis: Selection<SVGGElement, unknown, null, unknown>;
  #yAxisGrid: Selection<SVGGElement, unknown, null, unknown>;
  #fxScale: ScaleBand<string> = scaleBand();
  #xScale: ScaleBand<string> = scaleBand();
  #yScale: ScaleLinear<number, number> = scaleLinear();

  constructor() {
    this.svg = create('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .style('position', 'absolute') as Selection<SVGSVGElement, unknown, null, unknown>;
    this.#xAxis = this.svg.append('g').attr('class', 'axisX');
    this.#yAxis = this.svg.append('g').attr('class', 'axisY');
    this.#yAxisGrid = this.svg.append('g').attr('class', 'axisYGrid');
  }

  get svgElement(): SVGSVGElement {
    return this.svg.node()!;
  }

  handleConfigChange(config: Partial<BarChartConfig>): void {
    this.config = { ...this.config, ...config };
    this.render();
  }

  handleDataChange(data: BarChartData): void {
    this.data = data;
    this.render();
  }

  handleChartResize(width: number, height: number): void {
    this.config.width = width;
    this.config.height = height;
    this.render();
  }

  render(): void {
    this.svg.selectAll('g.group').remove();

    this.#updateSvgViewBox();
    this.#updateScales();
    this.#updateAxes();
    this.#addGroups();
  }

  get #transition(): Transition<any, any, any, any> {
    return this.svg.transition().duration(250);
  }

  #updateSvgViewBox(): void {
    this.svg.attr('viewBox', [0, 0, this.config.width, this.config.height]);
  }

  #updateScales(): void {
    this.#fxScale.domain(this.data.categories);
    this.#fxScale.range([this.config.marginLeft, this.config.width - this.config.marginRight]);
    this.#fxScale.padding(0.2);

    this.#xScale.domain(this.data.series.map(item => item.name));
    this.#xScale.range([0, this.#fxScale.bandwidth()]);
    this.#xScale.paddingInner(0.1);

    this.#yScale.domain([
      0,
      max(this.data.series.reduce((
        prev: number[],
        curr,
      ) => prev.concat(curr.data.map(item => item.value)), [])) || 1,
    ]).nice();
    this.#yScale.range([this.config.height - this.config.marginBottom, this.config.marginTop]);

    this.colorScale.domain(this.data.series.map(item => item.name));
  }

  #addBars(group: Selection<SVGGElement, string, SVGSVGElement, unknown>): void {
    group
      .selectAll('rect.bar')
      .data((label, i) => this.data.series.map(item => ({
        label: item.name,
        value: item.data[i].value,
        percentage: item.data[i].percentage,
      })))
      .join(
        enter => enter
          .append('rect')
          .call(bar => this.#handleTooltip(bar))
          .attr('class', 'bar')
          .attr('x', d => this.#xScale(d.label)!)
          .attr('y', d => this.#yScale(0))
          .attr('width', this.#xScale.bandwidth())
          .attr('height', 0)
          .attr('fill', d => this.colorScale(d.label)),
      )
      .transition(this.#transition)
      .attr('height', d => this.#yScale(0) - this.#yScale(d.value))
      .attr('y', d => this.#yScale(d.value));
  }

  #addGroups(): void {
    this.svg
      .selectAll('g.group')
      .data<string>(this.data.categories)
      .join(
        enter => enter
          .append('g')
          .attr('class', 'group')
          .attr('transform', label => `translate(${this.#fxScale(label)}, 0)`)
          .call(group => this.#addBars(group)),
      );
  }

  #updateAxes(): void {
    this.#xAxis
      .attr('transform', `translate(0, ${this.config.height - this.config.marginBottom})`)
      .call(axisBottom(this.#fxScale))
      .call(g => g.select('.domain').remove())
      .call(g => g.selectAll('.tick line').remove())
      .call(g => g.selectAll('.tick text')
        .each(function (d, i, nodes) {
          const current = select(this);

          if (i > 0) {
            const currentBox = (this as SVGTextElement).getBoundingClientRect();
            const prevBox = (nodes[i - 1] as SVGTextElement).getBoundingClientRect();

            if (currentBox.left < (prevBox.right - 8)) {
              current.remove();
            }
          }
        }));

    const [rangeMin, rangeMax] = this.#yScale.range();
    const rangeDelta = rangeMin - rangeMax;
    const ticks = rangeDelta / BAR_CHART_TICKS_DISTANCE < 1
      ? 2
      : rangeDelta / BAR_CHART_TICKS_DISTANCE;

    this.#yAxis
      .attr('transform', `translate(${this.config.marginLeft}, 0)`)
      .call(axisLeft(this.#yScale).ticks(ticks))
      .call(g => g.select('.domain').remove())
      .call(g => g.selectAll('.tick line').remove());

    this.#yAxisGrid
      .selectAll('line.grid-line')
      .data(this.#yScale.ticks(ticks))
      .join(
        enter => enter
          .append('line')
          .attr('class', 'grid-line')
          .attr('x1', this.config.marginLeft)
          .attr('x2', this.config.width - this.config.marginRight)
          .attr('y1', d => this.#yScale(d))
          .attr('y2', d => this.#yScale(d)),
        update => update
          .attr('x1', this.config.marginLeft)
          .attr('x2', this.config.width - this.config.marginRight)
          .attr('y1', d => this.#yScale(d))
          .attr('y2', d => this.#yScale(d)),
      )
      .call(g => g.selectAll('line.grid-line').remove());
  }

  #handleTooltip(bar: Selection<SVGRectElement, {
    label: string;
    value: number;
    percentage: Nullable<number>;
  }, SVGGElement, string>): void {
    bar
      .on('mouseenter', (event, d) => {
        const { top, left, width } = event.target.getBoundingClientRect();
        this.#tooltip.show({
          id: uniqueId(),
          value: d.percentage ? `${d.value}(${d.percentage}%)` : `${d.value}`,
          top: top - this.config.tooltipPadding,
          left: left + (width / 2),
        });
      })
      .on('mouseleave', () => this.#tooltip.hide());
  }
}
