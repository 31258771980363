import {
  ChangeDetectionStrategy, Component, computed, DestroyRef, inject, input, OnInit, signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  ButtonModule, CommonMediaQueries, DonutChartModule, IconModule, MediaScreenService,
} from '@pinup-teams/common';
import { CoinComponent } from '@pt/components';

import { TransactionsTotal } from '../../store';
import { DonutTotal } from '../../models';
import { getDonutTotal } from '../../helpers/get-donut-total';

@Component({
  standalone: true,
  selector: 'pt-transactions-total-full',
  templateUrl: './total-full.component.html',
  styleUrls: ['./total-full.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, DonutChartModule, ButtonModule, IconModule, CoinComponent],
})
export class TotalFullComponent implements OnInit {
  total = input.required<TransactionsTotal>();

  private readonly _destroyRef = inject(DestroyRef);
  private readonly _translate = inject(TranslateService);
  private readonly _mediaScreenService = inject(MediaScreenService);

  readonly showMore = signal(false);
  readonly incomeTotal = computed(() => this.total().rewards + this.total().fromColleagues);
  readonly expenseTotal = computed(() => this.total().purchases + this.total().toColleagues);
  readonly totals = computed<DonutTotal[]>(() => [
    getDonutTotal(
      this._translate.instant('transactions.header.incomeTotal'),
      this.total().rewards,
      'var(--color-donut-2)',
      this._translate.instant('transactions.label.rewardsTotal'),
      this.total().fromColleagues,
      'var(--color-donut-3)',
      this._translate.instant('transactions.label.fromColleaguesTotal'),
    ),

    getDonutTotal(
      this._translate.instant('transactions.header.expenseTotal'),
      this.total().purchases,
      'var(--color-donut-1)',
      this._translate.instant('transactions.label.purchasesTotal'),
      this.total().toColleagues,
      'var(--color-donut-4)',
      this._translate.instant('transactions.label.toColleaguesTotal'),
    ),

    getDonutTotal(
      this._translate.instant('transactions.header.receivedSpentTotal'),
      this.total().rewards + this.total().fromColleagues,
      'var(--color-donut-2)',
      this._translate.instant('transactions.label.receivedTotal'),
      this.total().purchases + this.total().toColleagues,
      'var(--color-donut-1)',
      this._translate.instant('transactions.label.spentTotal'),
    ),

    getDonutTotal(
      this._translate.instant('transactions.header.receivedSentTotal'),
      this.total().fromColleagues,
      'var(--color-donut-3)',
      this._translate.instant('transactions.label.fromColleaguesTotal'),
      this.total().toColleagues,
      'var(--color-donut-4)',
      this._translate.instant('transactions.label.toColleaguesTotal'),
    ),
  ]);

  ngOnInit(): void {
    this._mediaScreenService
      .mediaMatcher(CommonMediaQueries.MD)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(isMD => this.showMore.set(isMD));
  }

  toggleShowMore(): void {
    this.showMore.update(value => !value);
  }
}
