import { effect, inject, untracked } from '@angular/core';

import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import { filter, switchMap, tap } from 'rxjs/operators';
import { AppError } from '@pinup-teams/common';
import {
  setPending,
  setError,
  setSuccess,
  withRequestHandler,
  withRequestStatus,
  withFiltersUpdate,
} from '@pt/store';
import { CommentModel } from '@pt/models';
import { pipe } from 'rxjs';

import { CommentsFilters, CommentsState } from './models';
import { COMMENTS_API } from './api';
import { ThreadDialog } from './components/thread/thread.dialog';

const initialFilters: CommentsFilters = {
  page: 1,
  limit: 15,
};

const initialState: CommentsState = {
  filters: initialFilters,
  itemsCount: null,
  comments: [],
  postId: null,
};

export const CommentsStore = signalStore(
  withState(initialState),
  withRequestStatus(),
  withRequestHandler(),
  withFiltersUpdate<CommentsFilters>(),
  withMethods(store => ({
    setPostId: (postId: number) => patchState(store, { postId }),

    updateComments: (updatedComment: CommentModel) => {
      const updatedComments = store.comments().map(comment => (comment.id === updatedComment.id
        ? updatedComment
        : comment));

      patchState(store, { comments: updatedComments });
    },
  })),
  withMethods((
    store,
    blogApi = inject(COMMENTS_API),
    threadDialog = inject(ThreadDialog),
  ) => ({
    getComments: rxMethod<CommentsFilters>(
      switchMap(params => {
        patchState(store, setPending());
        return blogApi.getComments(params, store.postId()).pipe(
          tapResponse({
            next: ({ comments, itemsCount }) => patchState(
              store,
              {
                comments: params.page > 1
                  ? [...store.comments(), ...comments]
                  : comments,
                itemsCount,
              },
              setSuccess(),
            ),
            error: (error: AppError) => patchState(store, setError(error)),
          }),
        );
      }),
    ),

    saveComment: rxMethod<CommentModel>(
      switchMap(comment => {
        const isEdit = !!comment.id;

        patchState(store, setPending());
        const request$ = isEdit
          ? blogApi.editComment(comment)
          : blogApi.addComment(comment);

        return request$.pipe(
          tapResponse({
            next: response => {
              patchState(store, setSuccess());

              isEdit
                ? store.updateComments(response)
                : store.updateFilters(initialFilters);
            },
            error: (error: AppError) => patchState(store, setError(error)),
          }),
        );
      }),
    ),

    deleteComment: rxMethod<CommentModel>(
      switchMap(comment => {
        patchState(store, setPending());
        return blogApi.deleteComment(comment).pipe(
          tapResponse({
            next: () => {
              patchState(store, setSuccess());
              store.updateFilters(initialFilters);
            },
            error: (error: AppError) => patchState(store, setError(error)),
          }),
        );
      }),
    ),

    openThreadDialog: rxMethod<CommentModel>(
      pipe(
        switchMap(comment => threadDialog
          .open(comment)
          .afterClosed<boolean>()),
        filter(Boolean),
        tap(() => store.updateFilters(initialFilters)),
      ),
    ),
  })),
  withHooks({
    onInit(store) {
      store.getComments(store.filters);

      effect(() => {
        if (store.error()) {
          untracked(() => store.handleError(store.error()));
        }
      });
    },
  }),
);
