export const accessDataMock = {
  permissions: [
    'Admin',
    'AdminMyWards',
    'AdminUsers',
    'AdminUsersGroups',
    'AdminUsersCabinetIds',
    'AdminUsersLogins',
    'AdminUsersStatuses',
    'AdminUsersBalance',
    'AdminUsersLocations',
    'AdminUsersFunctionalBalance',
    'AdminUsersAchievementsHistory',
    'AdminUsers2FAForUsers',
    'AdminAdmins',
    'AdminUserInfo',
    'AdminTransactionsFunctional',
    'AdminMessageHub',
    'AdminMessageHubLearningAndDevelopmentMessages',
    'AdminMessageHubItsMessages',
    'AdminMessageHubICMessage',
    'AdminPinCoins',
    'AdminPinCoinsAutomaticAccrual',
    'AdminPinCoinsEventRewards',
    'AdminProducts',
    'AdminProductsEditable',
    'AdminLocations',
    'AdminOrders',
    'AdminNews',
    'AdminFAQ',
    'AdminBlogs',
    'AdminBlogsCreate',
    'AdminLimits',
    'AdminQuests',
    'AdminQuestCatalog',
    'AdminSkins',
    'AdminFinanceRequests',
    'AdminSupport',
    'AdminSupportOffice',
    'AdminSupportPlatform',
    'AdminSupportEducation',
    'AdminSupportFinance',
    'AdminAchievements',
    'AdminCourses',
    'AdminCoursesAward',
    'AdminCalendar',
    'AdminChannels',
    'AdminTemplates',
    'AdminCongrats',
    'AdminOnboarding',
    'AdminPpWards',
    'AdminClubs',
    'AdminAnalytics',
    'AdminAiAnalytics',
    'AdminTgChats',
    'AdminSurveys',
    'AdminSurveysManagement',
    'LMSAnalytic',
    'AdminEducationEvents',
    'user',
    'AdminLibrary',
    'AdminCommunity',
    'AdminUsersDownloadExcel',
    'AdminWelcomeContent',
    'PilotUser',
    'AdminWelcomeContent',
  ],
};
