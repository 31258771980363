<div
  class="pt-transaction"
  [ngClass]="'pt-transaction_' + transaction().status"
>

  <section class="pt-transaction__status">
    {{ ('transaction.status.' + transaction().status) | translate }}
    @if (name()) {
      <span class="pt-transaction__status-name">
        {{ name() }}
      </span>
    }
  </section>

  <section class="pt-transaction__date-sum">
    <span class="pt-transaction__date">
      {{ transaction().createdAt | date:'dd MMM y HH:mm' }}
    </span>

    <div class="pt-transaction__sum">
      {{ transaction().sum }}
      <pt-coin/>
    </div>
  </section>

  <section class="pt-transaction__comment">
    {{ transaction().comment }}
  </section>
</div>
