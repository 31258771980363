<h4 class="pu-balance-skin-form__header">
  {{ 'balanceSkinForm.header' | translate }}

  <button
    class="pu-balance-skin-form__close"
    type="button"
    puButton
    uiType="quaternary"
    size="m"
    [onlyIcon]="true"
    (click)="close()"
  >
    <pu-icon iconName="close-2"/>
  </button>
</h4>

<div class="pu-balance-skin-form__body">
  <div
    class="pu-balance-skin-form__card"
    [class.pu-balance-skin-form__card_dark]="selectedSkin?.isDark"
  >
    <img
      class="pu-balance-skin-form__card-img"
      ptImageLoader
      skipSmall
      [src]="selectedSkin?.url || assetsHost+'/assets/images/balance/balance-card-mock.png'"
      [alt]="selectedSkin?.name || ''"
    >

    <h5 class="pu-balance-skin-form__card-title">
      {{ balance.title| translate }}
    </h5>

    <p class="pu-balance-skin-form__card-balance">
      {{ balance.amount }}
      <pt-coin
        class="md:pu-icon_m"
        size="32"
      />
    </p>

    <div class="pu-balance-skin-form__card-actions">
      @switch (balance.balanceType) {
        @case ('regular') {
          <div class="pu-balance-skin-form__card-btn">
            {{ 'balances.button.share' | translate }}
          </div>

          <div class="pu-balance-skin-form__card-btn">
            {{ 'balances.button.earn' | translate }}
          </div>
        }
        @case ('functional') {
          <div class="pu-balance-skin-form__card-btn">
            {{ 'balances.button.sendBonus' | translate }}
          </div>
        }
      }
    </div>
  </div>

  <div class="pu-balance-skin-form__skins">
    <h5 class="pu-balance-skin-form__skins-title">
      {{ 'balanceSkinForm.skinsTitle' | translate }}
    </h5>

    <ul class="pu-balance-skin-form__skins-list">
      <li
        class="pu-balance-skin-form__skins-list-item"
        [class.pu-balance-skin-form__skins-list-item_active]="selectedSkin?.id === skin.id"
        *ngFor="let skin of skins; trackBy: trackById"
        (click)="selectSkin(skin)"
      >

        <img
          ptImageLoader
          skipSmall
          [src]="skin.url"
          [alt]="skin.name"
        >
      </li>
    </ul>
  </div>

  <div class="pu-balance-skin-form__actions">
    <button
      type="submit"
      puButton
      size="l"
      uiType="primary"
      (click)="apply()"
      [disabled]="!selectedSkin"
    >
      {{ 'balanceSkinForm.button.apply' | translate }}
    </button>

    <button
      type="button"
      puButton
      size="l"
      uiType="secondary"
      (click)="close()"
    >
      {{ 'balanceSkinForm.button.cancel' | translate }}
    </button>
  </div>
</div>
