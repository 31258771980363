import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, input, output } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { OnboardingPreviewStage, UserOnboardingStage } from '@pt/models';
import {
  AchievementComponent,
  CoinComponent,
  OnboardingStageImageComponent,
  TaskData,
  TimerComponent,
} from '@pt/components';
import { IconModule } from '@pinup-teams/common';

@Component({
  standalone: true,
  selector: 'pt-onboarding-stage',
  templateUrl: './onboarding-stage.component.html',
  styleUrl: './onboarding-stage.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    TranslateModule,
    CoinComponent,
    IconModule,
    TimerComponent,
    AchievementComponent,
    OnboardingStageImageComponent,
    NgTemplateOutlet,
  ],
})
export class OnboardingStageComponent {
  openTask = output<TaskData>();
  stageOrder = input.required<number>();
  stage = input.required<UserOnboardingStage | OnboardingPreviewStage>();

  @HostBinding('class.pt-onboarding-stage_completed') get isCompleted() {
    return this.stage().status === 'completed';
  }

  @HostBinding('class.pt-onboarding-stage_blocked') get isBlocked() {
    return !this.stage().status;
  }

  @HostBinding('class') get onboardingTypeClass() {
    return this.isCompleted ? this.stage().onboardingType : '';
  }
}
