import { inject, Injectable } from '@angular/core';

import { DialogRef, DialogService } from '@pinup-teams/common';
import { ReactionDialog } from '@pt/models';

import { ReactionsDialogComponent } from './reactions-dialog.component';

@Injectable()
export class ReactionsDialog {
  readonly #dialog = inject(DialogService);

  open(reactions: ReactionDialog[], limit: number): DialogRef {
    return this.#dialog.open(
      ReactionsDialogComponent,
      { reactions, limit },
      {
        panelClass: 'pu-overlay-panel_reactions-dialog',
        isQueue: false,
      },
    );
  }
}
