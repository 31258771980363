import { PersonalInfo, UserProfile } from '@pt/models';
import { fillArr } from '@pinup-teams/common';
import { environment } from '@pt/environment';

export const getUsersMock = (count = 12): UserProfile[] => {
  return fillArr(count, i => ({
    id: i,
    fullName: 'Aleksander Sidorov',
    imageUrl: `${environment.assetsHost}/assets/images/user-avatar-mock.jpg`,
    position: 'Head of QA',
    email: 'a.sidorov@company.team',
  }));
};

export const profileMock: PersonalInfo = {
  id: 3335,
  email: 'a.timofeenko@company.business',
  fullName: 'Alexander Timofeenko',
  location: 'UA IT',
  locationId: 1,
  team: 'Frontend',
  position: 'Front End developer',
  segment: 'SEGMENT.TECH',
  hobby: 'Some hobby, bla bla bla',
  birthday: '02-24',
  startDate: '2023-06-29',
  personalYearStart: '2023-06-29',
  picture: '',
  phone: '+380968425369',
  status: 'Probation',
  // eslint-disable-next-line @stylistic/max-len
  myGoals: '●Contribute to project perfection - maintainability, scalability, upgradability and reuse.\n●Become an active participator in the process of code review;\n●Understand the basics of project business domain;\n●Master the benefits of latest Angular version (usage of stand alone components, etc);\n●As part of a frontend team, launch MVP version of the COMPANY.TEAM project;',
  vacationAvailablePaid: null,
  personalYearEnd: '2024-06-28',
  updated: '2023-06-29T14:59:05+03:00',
  balance: 0,
  is2faEnabled: true,
  directManager: {
    id: 1738,
    fullName: 'Tiukh Oleksandr',
    email: 'o.tiukh@company.team',
    picture: 'https://jira.time2go.tech/secure/useravatar?ownerId=JIRAUSER24589&avatarId=24123',
    position: 'Product Owner Web',
  },
  functionalManager: {
    id: 1738,
    fullName: 'Tiukh Oleksandr',
    email: 'o.tiukh@company.team',
    picture: 'https://jira.time2go.tech/secure/useravatar?ownerId=JIRAUSER24589&avatarId=24123',
    position: 'Product Owner Web',
  },
  peoplePartner: {
    id: 1738,
    fullName: 'Tiukh Oleksandr',
    email: 'o.tiukh@company.team',
    picture: '',
    position: 'Product Owner Web',
  },
  colleagues: {
    direct: getUsersMock(3),
    functional: getUsersMock(5),
  },
  teamUsers: {
    direct: getUsersMock(7),
    functional: getUsersMock(4),
  },
  function: 'Developer',
  age: '30',
  workFormat: 'remote',
  gender: 'male',
};
