<div class="pt-blog-form__header">
  <h4 class="pt-blog-form__header-text">
    {{ 'blog.blogForm.header.addPost' | translate }}
  </h4>

  <button
    class="pt-blog-form__close"
    type="button"
    puButton
    size="m"
    uiType="quaternary"
    [onlyIcon]="true"
    (click)="cancel()"
  >
    <pu-icon
      iconName="close"
      size="16"
    />
  </button>
</div>

<form
  class="pt-blog-form__body"
  [formGroup]="form"
  (ngSubmit)="save()"
>

  <div class="pu-form-container">
    <div class="pu-form-group">
      <pu-uploader
        formControlName="previewImage"
        apiS3="upload/image/"
        accept=".jpg,.jpeg,.png,.webp"
        labelKey="blog.blogForm.images.previewImage"
        placeholderKey="blog.blogForm.images.browseImage"
        hiddenBrowse
        hiddenPlaceholder
        [maxFileSize]="MAX_FILE_SIZE"
      />
    </div>

    <pu-simple-input
      formControlName="title"
      [label]="'blog.blogForm.control.title' | translate"
    />

    <div
      formControlName="article"
      [froalaEditor]="editorConfig"
    >
    </div>
  </div>

  <div class="pt-blog-form__actions">
    <button
      type="submit"
      puButton
      size="l"
      uiType="primary"
      [disabled]="!form.valid"
    >
      {{ 'blog.blogForm.controls.sendPost' | translate }}
    </button>

    <button
      type="button"
      puButton
      size="l"
      uiType="secondary"
      (click)="cancel()"
    >
      {{ 'blog.blogForm.controls.cancel' | translate }}
    </button>
  </div>
</form>
