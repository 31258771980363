import { inject, Injectable } from '@angular/core';

import { DialogRef, DialogService } from '@pinup-teams/common';
import { CommentModel } from '@pt/models';

import { ThreadComponent } from './thread.component';

@Injectable()
export class ThreadDialog {
  readonly #dialog = inject(DialogService);

  open(comment: CommentModel): DialogRef {
    return this.#dialog.open(ThreadComponent, comment);
  }
}
