import { Injectable } from '@angular/core';

import { delay, Observable, of, share } from 'rxjs';
import { Reaction } from '@pt/models';

import { getUsersReactionsMock } from '../mocks';
import { ReactionsApi } from '.';
import { GetUsersReactionsRes } from '../models';

@Injectable()
export class ReactionsApiMockService implements ReactionsApi {
  addReaction(): Observable<Reaction> {
    return of(null).pipe(delay(500), share());
  }

  deleteReaction() {
    return of(null).pipe(delay(500), share());
  }

  getUsersReactions(): Observable<GetUsersReactionsRes> {
    return getUsersReactionsMock();
  }
}
