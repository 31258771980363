import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { IconModule, IconSize } from '@pinup-teams/common';
import { environment } from '@pt/environment';

@Component({
  standalone: true,
  selector: 'pt-small-logo',
  templateUrl: './small-logo.component.html',
  styleUrl: './small-logo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconModule],
})
export class SmallLogoComponent {
  size = input<IconSize>('96');
  readonly logoIconName = `${environment.shortName}-small-logo`;
}
