import { inject, effect, untracked, computed } from '@angular/core';

import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { setError, setPending, setSuccess, withRequestHandler, withRequestStatus } from '@pt/store';
import { debounceTime, distinctUntilChanged, pipe, switchMap, tap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { AppError, SpinnerService } from '@pinup-teams/common';
import { Reaction, ReactionDialog } from '@pt/models';

import { REACTIONS_API } from './api';
import { ReactionReq } from './models';
import { ReactionsDialog } from './components';

interface ReactionsState {
  reactions: Reaction[];
  usersReactions: ReactionDialog[];
  limit: number;
}

export const reactionsState: ReactionsState = {
  reactions: [],
  usersReactions: [],
  limit: 50,
};

export const ReactionsStore = signalStore(
  withState(reactionsState),
  withComputed(({ usersReactions }) => ({
    reactedUsers: computed(() => usersReactions()[0]?.users),
    usersCount: computed(() => usersReactions()[0]?.count),
  })),
  withRequestStatus(),
  withRequestHandler(),
  withMethods((
    store,
    blogApi = inject(REACTIONS_API),
    spinnerService = inject(SpinnerService),
    reactionsDialog = inject(ReactionsDialog),
  ) => ({
    setReactions(reactions: Reaction[]): void {
      patchState(store, { reactions });
    },

    clearUsers(): void {
      patchState(store, { usersReactions: [] });
    },

    addReaction: rxMethod<ReactionReq>(
      switchMap(params => {
        patchState(store, setPending());

        return spinnerService.observe(blogApi.addReaction(params), true).pipe(
          tapResponse({
            next: res => {
              const reactions = store.reactions();
              const isUpdated = reactions.some(r => r.emoji === params.emoji);

              patchState(
                store,
                {
                  reactions: isUpdated
                    ? reactions.map(r => (r.emoji === params.emoji ? res : r))
                    : [...reactions, res],
                },
                setSuccess(),
              );
            },
            error: (error: AppError) => patchState(store, setError(error)),
          }),
        );
      }),
    ),

    deleteReaction: rxMethod<ReactionReq>(
      switchMap(params => {
        patchState(store, setPending());

        return spinnerService.observe(blogApi.deleteReaction(params), true).pipe(
          tapResponse({
            next: () => {
              patchState(
                store,
                {
                  reactions: store.reactions()
                    .map(reaction => (reaction.userReactionId === params.userReactionId
                      ? { ...reaction, count: reaction.count - 1, currentUserReacted: false }
                      : reaction))
                    .filter(reaction => reaction.count),
                },
                setSuccess(),
              );
            },
            error: (error: AppError) => patchState(store, setError(error)),
          }),
        );
      }),
    ),

    getUsersReactions: rxMethod<{ reaction: ReactionReq; isTouch: boolean }>(
      pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => patchState(store, setPending())),
        switchMap(({ reaction, isTouch }) => {
          return blogApi.getUsersReactions({ ...reaction, limit: store.limit() }).pipe(
            tapResponse({
              next: ({ reactions }) => {
                if (isTouch) {
                  reactionsDialog.open(reactions, store.limit());
                } else {
                  patchState(store, { usersReactions: reactions }, setSuccess());
                }
              },
              error: (error: AppError) => patchState(store, setError(error)),
            }),
          );
        }),
      ),
    ),
  })),
  withHooks({
    onInit(store) {
      effect(() => {
        if (store.error()) {
          untracked(() => store.handleError(store.error()));
        }
      });
    },
  }),
);
