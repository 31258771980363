import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '@pt/environment';
import { OnboardingType } from '@pt/models';

import { OnboardingPreviewApi } from './onboarding-preview-api.interface';
import { GetOnboardingDetailsRes } from '../models';

@Injectable()
export class OnboardingPreviewService implements OnboardingPreviewApi {
  readonly #httpClient = inject(HttpClient);

  getOnboardingDetails(
    templateId: number,
    onboardingType: OnboardingType,
  ): Observable<GetOnboardingDetailsRes> {
    return this.#httpClient.get<GetOnboardingDetailsRes>(
      `${environment.apiHost}onboarding/admin/templates/${onboardingType}/${templateId}/preview/`,
    );
  }
}
