<div class="pt-reactions-dialog__header">
  <h4 class="pt-reactions-dialog__header-text">
    {{ 'reactions.dialog.header' | translate }}
  </h4>

  <button
    class="pt-reactions-dialog__close"
    type="button"
    puButton
    size="m"
    uiType="quaternary"
    [onlyIcon]="true"
    (click)="onClose()"
  >
    <pu-icon
      iconName="close"
      size="16"
    />
  </button>
</div>

<div class="pt-reactions-dialog__tabs">
  <swiper
    class="pt-reactions-dialog__swiper pu-swiper-slide-width-auto"
    #swiper
    [config]="sliderConfig">
    <button
      class="pt-reactions-dialog__tab"
      puButton
      *swiperSlide
      type="button"
      size="m"
      [class.pu-button_pressed]="!selectedReaction"
      uiType="gradient"
      (click)="setReaction(null)">
      {{ 'reactions.dialog.tab.all' | translate }}
    </button>

    @for (reaction of reactions; track reaction.emoji) {
      <button
        class="pt-reactions-dialog__tab"
        puButton
        *swiperSlide
        type="button"
        size="m"
        uiType="gradient"
        [class.pu-button_pressed]="selectedReaction === reaction"
        (click)="setReaction(reaction)">
        {{ reaction.emoji }}
        <span>{{ reaction.count }}</span>
      </button>
    }
  </swiper>
</div>

<ul 
  class="pt-reactions-dialog__content"
  [class.pt-reactions-dialog__content_profile]="selectedReaction">
  @if(!selectedReaction) {
    @for (reaction of reactions; track reaction.emoji) {
      <li class="pt-reactions-dialog__content-item">
        {{ reaction.emoji }}
        <ul class="pt-reactions-dialog__content-item-users">
          @for (user of reaction.users; track user.id) {
            <li>{{ user.fullName + (!$last ? ',' : '') }}</li>
          }

          @if (reaction.count > usersLimit) {
            <li class="pt-reactions-dialog__content-item-users">
              {{ 'reactions.users.andOthers' | translate: { count: reaction.count - usersLimit } }} 
            </li>
          }
        </ul>
      </li>
    }
  } @else {
    @for (user of selectedReaction.users; track user.id) {
      <li class="pt-reactions-dialog__content-item-profile">
        <pu-user-profile-card [user]="user" />
      </li>
    }

    @if (selectedReaction.count > usersLimit) {
      <li class="pt-reactions-dialog__content-item-profile">
        {{ 'reactions.users.andOthers' | translate: { count: selectedReaction.count - usersLimit } }} 
      </li>
    }
  }
</ul>
