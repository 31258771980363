import { Nullable, uniqueId } from '@pinup-teams/common';
import {
  axisBottom, axisLeft, create, max, ScaleBand, scaleBand, scaleLinear, ScaleLinear, ScaleOrdinal,
  scaleOrdinal, Selection, Transition,
} from 'd3';
import { BarChartConfig, BarChartData } from '@pt/components';

import { BaseChart } from '../charts.abstraction';
import { Tooltip } from '../tooltip/tooltip';
import {
  BAR_CHART_DEFAULT_CONFIG,
  BAR_CHART_DEFAULT_DATA,
  BAR_CHART_TICKS_DISTANCE,
} from './bar-chart.constant';

export class HorizontalBarChart implements BaseChart<BarChartConfig, BarChartData> {
  svg: Selection<SVGSVGElement, unknown, null, unknown>;
  data: BarChartData = BAR_CHART_DEFAULT_DATA;
  config: BarChartConfig = BAR_CHART_DEFAULT_CONFIG;

  colorScale: ScaleOrdinal<string, string> = scaleOrdinal([
    '#00cfa6',
    '#fac600',
    '#ff2400',
    '#ad00ff',
    '#2c99ff',
    '#ff005c',
    '#c1694f',
  ]);

  #tooltip = new Tooltip();

  #xAxis: Selection<SVGGElement, unknown, null, unknown>;
  #yAxis: Selection<SVGGElement, unknown, null, unknown>;
  #yAxisGrid: Selection<SVGGElement, unknown, null, unknown>;

  #yScale: ScaleBand<string> = scaleBand();
  #xScale: ScaleLinear<number, number> = scaleLinear();
  #fxScale: ScaleBand<string> = scaleBand();

  #marginLeft = this.config.marginLeft;

  constructor() {
    this.svg = create('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .style('position', 'absolute') as Selection<SVGSVGElement, unknown, null, unknown>;

    this.#xAxis = this.svg.append('g').attr('class', 'axisX');
    this.#yAxis = this.svg.append('g').attr('class', 'axisY');
    this.#yAxisGrid = this.svg.append('g').attr('class', 'axisYGrid');
  }

  get svgElement(): SVGSVGElement {
    return this.svg.node()!;
  }

  handleConfigChange(config: Partial<BarChartConfig>): void {
    this.config = { ...this.config, ...config };
    this.#marginLeft = this.config.marginLeft;
    this.render();
  }

  handleDataChange(data: BarChartData): void {
    this.data = data;
    this.render();
  }

  handleChartResize(width: number, height: number): void {
    this.config.width = width;
    this.config.height = height;
    this.render();
  }

  render(): void {
    this.svg.selectAll('g.group').remove();

    this.#updateSvgViewBox();
    this.#updateScales();
    this.#updateAxes();
    this.#updateGridLines();
    this.#addGroups();
  }

  get #transition(): Transition<any, any, any, any> {
    return this.svg.transition().duration(250);
  }

  #updateSvgViewBox(): void {
    this.svg.attr('viewBox', [0, 0, this.config.width, this.config.height]);
  }

  #updateScales(): void {
    this.#yScale
      .domain(this.data.categories)
      .range([this.config.marginTop, this.config.height - this.config.marginBottom])
      .padding(0.2);

    this.#fxScale
      .domain(this.data.series.map(item => item.name))
      .range([0, this.#yScale.bandwidth()])
      .paddingInner(0.1);

    const maxValue = max(this.data.series.flatMap(series => series.data.map(d => d.value))) || 1;
    this.#xScale
      .domain([0, maxValue])
      .nice()
      .range([this.#marginLeft, this.config.width - this.config.marginRight]);

    this.colorScale.domain(this.data.series.map(item => item.name));
  }

  #addGroups(): void {
    this.svg
      .selectAll('g.group')
      .data<string>(this.data.categories)
      .join(
        enter => enter
          .append('g')
          .attr('class', 'group')
          .attr('transform', category => `translate(0, ${this.#yScale(category)})`)
          .call(group => this.#addBars(group)),
      );
  }

  #addBars(group: Selection<SVGGElement, string, SVGSVGElement, unknown>): void {
    group
      .selectAll('rect.bar')
      .data((category, i) => {
        return this.data.series.map(series => ({
          label: series.name,
          value: series.data[i].value,
          percentage: series.data[i].percentage,
        }));
      })
      .join(
        enter => enter
          .append('rect')
          .call(rect => this.#handleTooltip(rect))
          .attr('class', 'bar')
          .attr('x', () => this.#xScale(0))
          .attr('y', d => this.#fxScale(d.label)!)
          .attr('width', 0)
          .attr('height', this.#fxScale.bandwidth())
          .attr('fill', d => this.colorScale(d.label)),
      )
      .transition(this.#transition)
      .attr('width', d => this.#xScale(d.value) - this.#xScale(0));
  }

  #updateAxes(): void {
    this.#yAxis
      .attr('transform', `translate(${this.#marginLeft}, 0)`)
      .call(axisLeft(this.#yScale))
      .call(g => g.select('.domain').remove());

    let maxLabelWidth = 0;
    this.#yAxis.selectAll('.tick text').each(function () {
      const labelWidth = (this as SVGTextElement).getBBox().width;
      if (labelWidth > maxLabelWidth) {
        maxLabelWidth = labelWidth;
      }
    });

    maxLabelWidth += 10;

    if (this.#marginLeft < maxLabelWidth) {
      this.#marginLeft = maxLabelWidth;

      this.render();
    }
  }

  #updateGridLines(): void {
    this.#yAxisGrid.selectAll('line.grid-line').remove();

    const [rangeMin, rangeMax] = this.#xScale.range();
    const rangeDelta = rangeMax - rangeMin;
    const ticksCount = Math.floor(rangeDelta / BAR_CHART_TICKS_DISTANCE) || 2;

    const ticks = this.#xScale.ticks(ticksCount);

    this.#yAxisGrid
      .selectAll('line.grid-line')
      .data(ticks)
      .join('line')
      .attr('class', 'grid-line')
      .attr('y1', this.config.marginTop)
      .attr('y2', this.config.height - this.config.marginBottom)
      .attr('x1', d => this.#xScale(d))
      .attr('x2', d => this.#xScale(d));

    this.#xAxis
      .attr('transform', `translate(0, ${this.config.height - this.config.marginBottom})`)
      .call(
        axisBottom(this.#xScale)
          .ticks(ticksCount)
          .tickSize(-(this.config.height - (this.config.marginTop + this.config.marginBottom))),
      )
      .call(g => g.select('.domain').remove())
      .call(g => g.selectAll('.tick line').remove());
  }

  #handleTooltip(
    bar: Selection<SVGRectElement, {
      label: string;
      value: number;
      percentage: Nullable<number>;
    }, SVGGElement, string>,
  ): void {
    bar
      .on('mouseenter', (event, d) => {
        const { top, left, width } = event.target.getBoundingClientRect();
        this.#tooltip.show({
          id: uniqueId(),
          value: d.percentage ? `${d.value}(${d.percentage}%)` : `${d.value}`,
          top: top - this.config.tooltipPadding,
          left: left + (width / 2),
        });
      })
      .on('mouseleave', () => this.#tooltip.hide());
  }
}
