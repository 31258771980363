import { inject, Injectable } from '@angular/core';
import { HttpStatusCode } from '@angular/common/http';

import { OperatorFunction, catchError } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppError, ToastType } from '@pinup-teams/common';
import { ErrorsActions } from '@pinup-teams/common';
import { UiActions } from '@pt/store';

@Injectable({ providedIn: 'root' })
export class StoreHelperService {
  readonly #store = inject(Store);
  readonly handleError = this.#handleError.bind(this);

  showToast(toastType: ToastType, messageKey: string, titleKey?: string): void {
    this.#store.dispatch(UiActions.showToast({ toastType, message: messageKey, title: titleKey }));
  }

  catchError<T>(): OperatorFunction<T, T> {
    return catchError((error: AppError) => {
      this.handleError(error);
      throw error;
    });
  }

  #handleError(error: AppError): void {
    if (error?.originalError?.status !== HttpStatusCode.NotFound && error?.originalError?.status
      !== HttpStatusCode.Unauthorized) {
      this.#store.dispatch(ErrorsActions.unexpectedServerErrorHappened({ error }));
    }
  }
}
