<form
  class="pu-bonus-form__form"
  (ngSubmit)="send()"
>
  <div
    class="pu-bonus-form__controls"
  >
    <button
      class="pu-bonus-form__accrue-btn"
      type="button"
      puButton
      size="l"
      uiType="secondary"
      [innerHTML]="'personalInfo.teamBonuses.bonusForm.button.accrueEqually' | translate"
      (click)="accrueEqually()"
    >
    </button>

    <button
      type="button"
      puButton
      size="l"
      uiType="secondary"
      (click)="reset()"
    >
      {{ 'personalInfo.teamBonuses.bonusForm.button.reset' | translate }}
    </button>

    <div class="pu-bonus-form__bonus">
      <span class="pu-bonus-form__bonus-text">
        {{ 'personalInfo.teamBonuses.bonusForm.bonus' | translate }}:
      </span>

      <div class="pu-bonus-form__bonus-total">
        {{ bonusLeft || 0 }}
        <pt-coin/>
      </div>
    </div>
  </div>

  <ul
    class="pu-bonus-form__users"
    *ngIf="functionalUsers.length; else emptyList"
  >
    <li
      class="pu-bonus-form__user"
      *ngFor="let group of bonusesForm.controls; trackBy: trackByIndex; let i = index"
    >
      <div class="pu-bonus-form__user-profile">
        <a
          [routerLink]="['/colleague', group.value.userId]"
          (click)="close()"
        >
          <pu-user-profile-card
            [user]="functionalUsers[i]"
            [hasEmail]="false"
          />
        </a>

        <pu-increment
          class="pu-bonus-form__user-increment"
          [formControl]="group.controls.amount"
          [max]="bonusLeft + group.value.amount"
          [iconName]="coinIconName"
        />
      </div>

      <button
        class="pu-bonus-form__user-btn"
        type="button"
        puButton
        size="s"
        uiType="secondary"
        (click)="toggleComment(i)"
      >
        {{ 'personalInfo.teamBonuses.bonusForm.button.' + (commentsState[i]
        ? 'hideComment'
        : 'addComment') | translate }}
      </button>

      <div
        class="pu-bonus-form__user-comment"
        *ngIf="commentsState[i]"
      >
        <pu-text-area
          [maxLength]="200"
          [rows]="3"
          [formControl]="group.controls.comment"
          [label]="'personalInfo.teamBonuses.bonusForm.comment' | translate"
        />

        <p class="pu-bonus-form__user-comment-symbols">
          {{ 'personalInfo.teamBonuses.bonusForm.symbols' | translate }} {{
            group.value.comment.length }}/200
        </p>
      </div>
    </li>
  </ul>

  <ng-template #emptyList>
    <div class="pu-user-list__empty">
      <pu-icon
        class="pu-user-list__empty-icon"
        iconName="search"
      />
      <p class="pu-user-list__empty-text">
        {{ 'personalInfo.teamBonuses.bonusForm.empty' | translate }}
      </p>
    </div>
  </ng-template>

  <div class="pu-bonus-form__actions">
    <button
      type="submit"
      puButton
      size="l"
      uiType="primary"
      [disabled]="bonusesForm.invalid"
    >
      {{ 'personalInfo.teamBonuses.bonusForm.button.sendBonus' | translate }}
    </button>

    <button
      type="button"
      puButton
      size="l"
      uiType="secondary"
      (click)="close()"
    >
      {{ 'personalInfo.teamBonuses.bonusForm.button.cancel' | translate }}
    </button>
  </div>
</form>
