import { DonutChartConfig, DonutChartData } from './donut-chart.abstraction';

export const DONUT_CHART_DEFAULT_DATA: DonutChartData[] = [];

export const DONUT_CHART_DEFAULT_CONFIG: DonutChartConfig = {
  width: 500,
  height: 500,
  marginTop: 0,
  marginRight: 0,
  marginBottom: 0,
  marginLeft: 0,
  innerRadiusMultiplier: 0.67,
  tooltipPadding: 10,
  padAngle: 0,
};
