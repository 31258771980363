/**
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a website to a home screen on mobile.
 *
 * This is an experimental technology. Be cautious when using it.
 *
 * @compatibility {@link
 * https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent#Browser_compatibility}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent}
 */
export interface BeforeInstallPromptEvent extends Event {
  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to choose between a web version or
   * an Android version.
   */
  readonly platforms?: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice?: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;

  /**
   * Allows a developer to show install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt?(): Promise<void>;
}

export const IS_PWA_INSTALLED = 'pwa';
