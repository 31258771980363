import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';
import { CommentModel } from '@pt/models';

import {
  CommentsFilters,
  GetCommentsRes,
} from '../models';

export interface CommentsApi {
  getComments(params: CommentsFilters, postId: number): Observable<GetCommentsRes>;
  addComment(params: CommentModel): Observable<CommentModel>;
  editComment(params: CommentModel): Observable<CommentModel>;
  deleteComment(params: CommentModel): Observable<void>;
}

export const COMMENTS_API = new InjectionToken<CommentsApi>('COMMENTS_API');
