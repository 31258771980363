import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AssetLoaderService {
  readonly #document = inject(DOCUMENT);
  readonly #loadedScripts: Record<string, boolean> = {};

  /**
   * Loads a JavaScript file and appends the element to the document head.
   * @param {string} src - The path to the script.
   * @returns {Observable<void>} An observable that completes when the script is loaded.
   */
  loadJS(src: string): Observable<void> {
    if (this.#loadedScripts[src]) {
      return of();
    }
    this.#loadedScripts[src] = true;

    return new Observable<void>(subscriber => {
      const element = this.#document.createElement('script');
      element.type = 'text/javascript';
      element.src = src;

      element.onload = () => {
        subscriber.next();
        subscriber.complete();
      };

      element.onerror = error => {
        subscriber.error(error);
      };

      this.#document.head.appendChild(element);
    });
  }

  /**
   * Loads a CSS file and appends the element to the document head.
   * @param {string} src - The path to the stylesheet.
   * @returns {Observable<void>} An observable that completes when the stylesheet is loaded.
   */
  loadCSS(src: string): Observable<void> {
    if (this.#loadedScripts[src]) {
      return of();
    }
    this.#loadedScripts[src] = true;

    return new Observable<void>(subscriber => {
      const element = this.#document.createElement('link');
      element.rel = 'stylesheet';
      element.href = src;

      element.onload = () => {
        subscriber.next();
        subscriber.complete();
      };

      element.onerror = error => {
        subscriber.error(error);
      };

      this.#document.head.appendChild(element);
    });
  }
}
