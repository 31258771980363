<div
  class="pt-donut-chart__wrapper"
  #chartWrapper
  (puResize)="svgResized($event)"
>
</div>

<div class="pt-donut-chart__legend">
  @for (legendItem of legend(); track legendItem.name) {
    <div class="pt-donut-chart__legend-item">
      <div
        class="pt-donut-chart__legend-item__marker"
        [style.background-color]="legendItem.color"
      ></div>
      <div class="pt-donut-chart__legend-item__name">{{ legendItem.name }}</div>
    </div>
  }
</div>
