<div
  class="pu-balance-card"
  [class.pu-balance-card_dark]="balance.skin?.isDark"
>
  <img
    class="pu-balance-card__img"
    ptImageLoader
    skipSmall
    [src]="balance.skin?.url || assetsHost+'/assets/images/balance/balance-card-mock.png'"
    [alt]="balance.skin?.name || ''"
  />

  <h3 class="pu-balance-card__title">
    {{ (balance.title || '') | translate }}

    <button
      class="pu-balance-card__edit-btn"
      type="button"
      puButton
      uiType="quaternary"
      [onlyIcon]="true"
      (click)="edit(balance); $event.stopPropagation()"
    >
      <pu-icon iconName="edit"/>
    </button>
  </h3>

  <p class="pu-balance-card__balance">
    <span
      class="pu-balance-card__balance-sum"
      [attr.translate]="'no'"
    >
      {{ balance.amount }}
    </span>

    <pt-coin size="32"/>
  </p>

  <div class="pu-balance-card__actions">
    <ng-content/>
  </div>
</div>
