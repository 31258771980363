import { uniqueId } from '@pinup-teams/common';
import {
  arc, create, interpolate, Pie, pie, PieArcDatum, ScaleOrdinal, scaleOrdinal, Selection,
  Transition,
} from 'd3';

import { BaseChart } from '../charts.abstraction';
import { Tooltip } from '../tooltip/tooltip';
import { DonutChartConfig, DonutChartData } from './donut-chart.abstraction';
import { DONUT_CHART_DEFAULT_CONFIG, DONUT_CHART_DEFAULT_DATA } from './donut-chart.constant';

export class DonutChart implements BaseChart<DonutChartConfig, DonutChartData[]> {
  svg: Selection<SVGSVGElement, unknown, null, unknown>;
  data: DonutChartData[] = DONUT_CHART_DEFAULT_DATA;
  config: DonutChartConfig = DONUT_CHART_DEFAULT_CONFIG;
  colorScale: ScaleOrdinal<string, string> = scaleOrdinal<string>([
    '#00cfa6',
    '#fac600',
    '#ff2400',
    '#ad00ff',
    '#2c99ff',
    '#ff005c',
    '#c1694f',
  ]);
  #radius!: number;
  #tooltip = new Tooltip();
  #pieces: Selection<SVGGElement, unknown, null, unknown>;
  #pieGenerator: Pie<any, DonutChartData> = pie<DonutChartData>().value(d => d.count).sort(null);

  constructor() {
    this.svg = create('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .style('position', 'absolute') as Selection<SVGSVGElement, unknown, null, unknown>;
    this.#pieces = this.svg.append('g').attr('class', 'pieces');
  }

  get svgElement(): SVGSVGElement {
    return this.svg.node()!;
  }

  handleConfigChange(config: Partial<DonutChartConfig>): void {
    this.config = { ...this.config, ...config };
    this.render();
  }

  handleDataChange(data: DonutChartData[]): void {
    this.data = data;
    this.render();
  }

  handleChartResize(width: number, height: number): void {
    this.config.width = width;
    this.config.height = height;
    this.render();
  }

  render(): void {
    this.#pieces.selectAll('path.piece').remove();

    this.#updateSvgViewBox();
    this.#updateRadius();
    this.#updateScales();
    this.#addPieces();
  }

  get #transition(): Transition<any, any, any, any> {
    return this.svg.transition().duration(250);
  }

  #updateSvgViewBox(): void {
    this.svg.attr('viewBox', [0, 0, this.config.width, this.config.height]);
  }

  #updateRadius(): void {
    if (this.config.width < this.config.height) {
      this.#radius = this.config.width / 2 - this.config.marginRight - this.config.marginLeft;
    } else {
      this.#radius = this.config.height / 2 - this.config.marginTop - this.config.marginBottom;
    }
  }

  #updateScales(): void {
    this.colorScale.domain(this.data.map(item => item.label));
  }

  #addPieces(): void {
    const data = this.#pieGenerator(this.data);
    const arcGenerator = arc<d3.PieArcDatum<any>>()
      .innerRadius(this.#radius * this.config.innerRadiusMultiplier)
      .outerRadius(this.#radius);

    const labelArcGenerator = arc<d3.PieArcDatum<any>>()
      .innerRadius(this.#radius * 1.1)
      .outerRadius(this.#radius * 1.1);

    this.#pieces
      .selectAll('path.piece')
      .data(data)
      .join(
        enter => enter
          .append('path')
          .attr('class', 'piece')
          .attr(
            'transform',
            `translate(${this.config.width / 2}, ${this.config.height / 2})`,
          )
          .attr('d', d => arcGenerator(d))
          .attr('fill', d => this.colorScale(d.data.label)),
      )
      .transition(this.#transition)
      .attrTween('d', d => t => arcGenerator({
        ...d,
        endAngle: interpolate(d.startAngle, d.endAngle)(t),
        padAngle: this.config.padAngle,
      }) as string);

    this.#pieces
      .selectAll('text.label')
      .data(data.filter(d => d.data.count >= 3))
      .join(
        enter => enter
          .append('text')
          .attr('class', 'label')
          .attr(
            'transform',
            d => {
              const [x, y] = labelArcGenerator.centroid(d);
              return `translate(${x + (this.config.width / 2)}, ${y + (this.config.height
                / 2)})`;
            },
          )
          .attr(
            'text-anchor',
            d => ((d.startAngle + d.endAngle) / 2 > Math.PI ? 'end' : 'start'),
          )
          .attr('alignment-baseline', 'middle')
          .text(d => d.data.count + '%'),
      )
      .transition(this.#transition)
      .attr(
        'transform',
        d => {
          const [x, y] = labelArcGenerator.centroid(d);
          return `translate(${x + (this.config.width / 2)}, ${y + (this.config.height / 2)})`;
        },
      );
  }

  #handleTooltip(
    path: Selection<SVGPathElement,
      PieArcDatum<DonutChartData>, SVGGElement, unknown>,
  ): void {
    let tooltipId: number;
    path
      .on('mouseenter', (event, d) => {
        tooltipId = uniqueId();

        this.#tooltip.show({
          id: tooltipId,
          value: `${d.data.label}: ${d.data.count}`,
          top: event.clientY - this.config.tooltipPadding,
          left: event.clientX,
        });
      })
      .on('mousemove', (event, d) => {
        this.#tooltip.move({
          id: tooltipId,
          value: `${d.data.label}: ${d.data.count}`,
          top: event.clientY - this.config.tooltipPadding,
          left: event.clientX,
        });
      })
      .on('mouseleave', () => this.#tooltip.hide());
  }
}
