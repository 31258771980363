import { at, DonutSlice } from '@pinup-teams/common';

export type InputDonutData = [number, string]; // [sum, color]
export type DonutData = { sum: number; slices: DonutSlice[] };

export function getDonutData(...data: InputDonutData[]): DonutData {
  const sum = data.reduce((acc, item) => acc + item[0], 0);
  let slices: DonutSlice[] = [{ id: 1, percent: 100, color: 'var(--background-color-3x)' }];

  if (sum) {
    slices = data
      .filter(item => item[0] > 0)
      .map((item, i) => ({
        id: i + 1,
        percent: Number(((item[0] * 100) / sum).toFixed(2)),
        color: item[1],
      }));

    const totalPercent = slices.reduce((acc, item) => acc + item.percent, 0);

    if (totalPercent !== 100) {
      const slice = at(slices, -1);
      slice.percent += 100 - totalPercent;
    }
  }

  return { sum, slices };
}
