import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '@pt/environment';
import { getParams, getQueryParams } from '@pinup-teams/common';
import { CommentModel } from '@pt/models';

import {
  CommentsFilters,
  GetCommentsRes,
} from '../models';
import { CommentsApi } from './comments-api.interface';

@Injectable()
export class CommentsApiService implements CommentsApi {
  readonly #http = inject(HttpClient);
  readonly #apiHost = environment.apiHost;
  readonly #apiPath = `${this.#apiHost}news/`;

  getComments(params: CommentsFilters, postId: number): Observable<GetCommentsRes> {
    return this.#http.get<GetCommentsRes>(
      `${this.#apiPath}${postId}/comments/`,
      { params: getQueryParams(params) },
    );
  }

  addComment(params: CommentModel): Observable<CommentModel> {
    return this.#http.post<CommentModel>(
      `${this.#apiPath}${params.article}/comments/`, getParams(params, ['content']),
    );
  }

  editComment(params: CommentModel): Observable<CommentModel> {
    return this.#http.patch<CommentModel>(
      `${this.#apiPath}${params.article}/comments/${params.id}/`,
      getParams(params, ['content']),
    );
  }

  deleteComment(params: CommentModel): Observable<void> {
    return this.#http.delete<void>(
      `${this.#apiPath}${params.article}/comments/${params.id}/`,
    );
  }
}
