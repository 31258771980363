import { InjectionToken } from '@angular/core';

import { OnboardingType } from '@pt/models';
import { Observable } from 'rxjs';

import { GetOnboardingDetailsRes } from '../models';

export interface OnboardingPreviewApi {
  getOnboardingDetails(
    templateId: number,
    onboardingType: OnboardingType
  ): Observable<GetOnboardingDetailsRes>;
}

export const ONBOARDING_PREVIEW_API = new InjectionToken<OnboardingPreviewApi>(
  'ONBOARDING_PREVIEW_API',
);
