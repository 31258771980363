import {
  ChangeDetectionStrategy, Component, computed, DestroyRef, HostBinding, inject, OnInit, signal,
  viewChild,
} from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterLink } from '@angular/router';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonModule, DIALOG_DATA, DialogRef, DisabledDirective, FroalaModule, IconModule, SafeUrlPipe,
} from '@pinup-teams/common';
import {
  OnboardingPreviewTask,
  UserAutoOnboardingTaskDetails,
  UserOnboardingTaskDetails,
} from '@pt/models';
import { distinctUntilChanged, filter } from 'rxjs';
import { Store } from '@ngrx/store';
import { CoinComponent, ConfettiComponent } from '@pt/components';
import { AuthSelectors } from '@pt/auth';
import { ImageLoaderDirective } from '@pt/directives';
import { environment } from '@pt/environment';

import {
  ONBOARDING_TASK_DETAILS_API,
  OnboardingTaskDetailsApiMockService,
  OnboardingTaskDetailsService,
} from './api';

export type DialogData = {
  task: UserOnboardingTaskDetails;
  mode: 'preview' | 'regular';
  userOnboardingId?: number;
};

const CompletedIconAnimation = trigger('completedIcon', [
  state('notCompleted', style({ opacity: 0, transform: 'rotate(-45deg) scale(2)' })),
  state('completed', style({ opacity: 1 })),
  transition('notCompleted => completed', animate('100ms')),
]);

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    ButtonModule,
    IconModule,
    MatSlideToggleModule,
    FroalaModule,
    RouterLink,
    DisabledDirective,
    ReactiveFormsModule,
    SafeUrlPipe,
    ConfettiComponent,
    ImageLoaderDirective,
    CoinComponent,
  ],
  providers: [
    {
      provide: ONBOARDING_TASK_DETAILS_API,
      useClass: environment.useMocks
        ? OnboardingTaskDetailsApiMockService
        : OnboardingTaskDetailsService,
    },
  ],
  selector: 'pt-onboarding-task-details',
  templateUrl: './onboarding-task-details.component.html',
  styleUrls: ['./onboarding-task-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [CompletedIconAnimation],
})
export class OnboardingTaskDetailsComponent implements OnInit {
  protected store = inject(Store);

  readonly #dialogRef = inject(DialogRef);
  readonly #destroyRef = inject(DestroyRef);
  readonly #data = inject<DialogData>(DIALOG_DATA);
  readonly #onboardingTaskDetailsService = inject(ONBOARDING_TASK_DETAILS_API);

  confettiComponent = viewChild.required(ConfettiComponent);
  task = signal(this.#data.task);
  isCompleted = computed(() => this.task().completed);
  isPreviewMode: boolean = this.#data.mode === 'preview';
  completedIconAnimationState = computed(() => (this.isCompleted() ? 'completed' : 'notCompleted'));
  completedControl = new FormControl(this.isCompleted());
  userLocationId = toSignal(this.store.select(AuthSelectors.selectUserLocationId));

  @HostBinding('class.pt-onboarding-task-details_completed') get isTaskCompleted() {
    return this.isCompleted();
  }

  // TODO: workaround for non working type narrowing
  //  when type of signal is discriminating union type
  //  https://github.com/angular/angular/issues/49161
  get taskGetter() {
    return this.task();
  }

  get taskBtnKey() {
    if (this.taskGetter.confirmation === 'auto') {
      return `onboardingTask.btn.goTo.${this.taskGetter.entity.type}`;
    }

    return '';
  }

  get autoTaskLink(): (string | number)[] {
    if (this.taskGetter.confirmation === 'auto') {
      switch (this.taskGetter.entity.type) {
        case 'quest':
          return ['/quests', this.userLocationId(), 'quest', this.taskGetter.entity.id];
        case 'course':
          return ['/education/courses', this.taskGetter.entity.id];
        case 'questionnaire':
          return ['/my-interview'];
      }
    }

    return [];
  }

  get taskImgSrc(): string {
    const autoTask = (this.taskGetter as UserAutoOnboardingTaskDetails);

    if (autoTask.entity.type === 'questionnaire') {
      return '/assets/images/questionnaire-placeholder.png';
    }

    return autoTask.entity.mainImage?.url || (this.taskGetter as OnboardingPreviewTask).image.url;
  }

  ngOnInit(): void {
    this.completedControl.valueChanges
      .pipe(
        filter(value => value !== this.isCompleted()),
        distinctUntilChanged(),
        takeUntilDestroyed(this.#destroyRef),
      ).subscribe(() => {
        if (!this.isPreviewMode) {
          this.completeTaskReq();
        }
      });
  }

  completeTaskReq(): void {
    this.#onboardingTaskDetailsService.completeTask({
      id: this.task().id,
      userOnboardingId: this.#data.userOnboardingId,
    }).pipe(takeUntilDestroyed(this.#destroyRef)).subscribe({ next: () => {
      this.task.update(task => ({ ...task, completed: true }));
      this.confettiComponent().shootConfetti();
    }, error: () => this.completedControl.setValue(false) });
  }

  cancel(): void {
    this.#dialogRef.close(!this.isPreviewMode && this.completedControl.value);
  }
}
