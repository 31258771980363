import { inject, Injectable } from '@angular/core';

import { DialogRef, DialogService } from '@pinup-teams/common';

import { AchievementsHistoryDialogComponent } from './achievements-history-dialog.component';

@Injectable()
export class AchievementsHistoryDialog {
  private readonly _dialog = inject(DialogService);

  open(userId?: number, myTeam = false): DialogRef {
    return this._dialog.open(AchievementsHistoryDialogComponent, { userId, myTeam });
  }
}
