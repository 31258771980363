import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { DictionaryItem } from '@pinup-teams/common';
import { SwitchButtonOption } from '@pt/components';
import {
  AchievementStatus, AchievementType, AutomaticCongratsType, BlogStatus, CommunityRequestStatus,
  CommunityRoles, CourseRequestStatus, CourseStatusType, CourseType, DeliveryMethod,
  EducationEventAuthorCommentType, EducationEventStatus, EducationRequestStatus,
  EventLanguageOption, EventsViewType, FinancialRequestStatus, GroupTagEnum, LibraryMaterialTypes, LimitApprovalStatus,
  ManualCongratsType, MessageStatus, Messengers, OfficeVisitsStatuses, OfficeVisitsTypes,
  QuestionType, QuestStatusType, SharingSessionType, SlackChannelType, SortType, SubordinatesLevels,
  SubordinatesTypes, SupportRequestCategoryType, SupportRequestStatusType, SurveyStatus,
  TgChatPermissions, TgChatTypes, TgUserPermissions, TimezoneTypes, UserRoles, UserStatusType,
  WelcomeContentType,
  WorkingMessengerLinks,
} from '@pt/models';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService {
  deliveries: DeliveryMethod[] = [
    { id: 'international', name: this._translate.instant('dictionary.deliveries.international') },
    { id: 'ukrposhta', name: this._translate.instant('dictionary.deliveries.ukrposhta') },
    { id: 'nova_poshta', name: this._translate.instant('dictionary.deliveries.novaPoshta') },
    { id: 'fedex', name: this._translate.instant('dictionary.deliveries.fedex') },
    { id: 'dhl', name: this._translate.instant('dictionary.deliveries.dhl') },
    { id: 'dpd', name: this._translate.instant('dictionary.deliveries.dpd') },
    { id: 'inpost', name: this._translate.instant('dictionary.deliveries.inpost') },
    { id: 'acs', name: this._translate.instant('dictionary.deliveries.acs') },
    {
      id: 'gap_akis_express',
      name: this._translate.instant('dictionary.deliveries.gapAkisExpress'),
    },
  ];

  languages: DictionaryItem<string>[] = [
    {
      id: 'EN',
      name: this._translate.instant('dictionary.languages.en'),
    },
    {
      id: 'RU',
      name: this._translate.instant('dictionary.languages.ru'),
    },
    {
      id: 'UA',
      name: this._translate.instant('dictionary.languages.ua'),
    },
    {
      id: 'KZ',
      name: this._translate.instant('dictionary.languages.kz'),
    },
    {
      id: 'PL',
      name: this._translate.instant('dictionary.languages.pl'),
    },
    {
      id: 'AM',
      name: this._translate.instant('dictionary.languages.am'),
    },
    {
      id: 'ES',
      name: this._translate.instant('dictionary.languages.es'),
    },
  ];

  materialTypes: DictionaryItem<LibraryMaterialTypes>[] = [
    {
      id: LibraryMaterialTypes.ARTICLE,
      name: 'Article',
    },
    {
      id: LibraryMaterialTypes.E_BOOK,
      name: 'E-book',
    },
    {
      id: LibraryMaterialTypes.PRESENTATION,
      name: 'Presentation',
    },
    {
      id: LibraryMaterialTypes.VIDEO,
      name: 'Video',
    },
  ];

  orderStatuses: DictionaryItem<string>[] = [
    { id: null, name: this._translate.instant('dictionary.orderStatuses.all') },
    { id: 'inReview', name: this._translate.instant('dictionary.orderStatuses.inReview') },
    { id: 'rejected', name: this._translate.instant('dictionary.orderStatuses.rejected') },
    { id: 'new', name: this._translate.instant('dictionary.orderStatuses.new') },
    { id: 'confirmed', name: this._translate.instant('dictionary.orderStatuses.confirmed') },
    { id: 'shipping', name: this._translate.instant('dictionary.orderStatuses.shipping') },
    { id: 'delivered', name: this._translate.instant('dictionary.orderStatuses.delivered') },
    { id: 'completed', name: this._translate.instant('dictionary.orderStatuses.completed') },
    { id: 'canceled', name: this._translate.instant('dictionary.orderStatuses.canceled') },
  ];

  questStatuses: DictionaryItem<QuestStatusType>[] = [
    { id: null, name: this._translate.instant('dictionary.questStatuses.all') },
    { id: 'inProgress', name: this._translate.instant('dictionary.questStatuses.inProgress') },
    { id: 'inReview', name: this._translate.instant('dictionary.questStatuses.inReview') },
    { id: 'completed', name: this._translate.instant('dictionary.questStatuses.completed') },
    { id: 'rejected', name: this._translate.instant('dictionary.questStatuses.rejected') },
    { id: 'canceled', name: this._translate.instant('dictionary.questStatuses.canceled') },
  ];

  supportRequestStatuses: DictionaryItem<SupportRequestStatusType>[] = [
    {
      id: SupportRequestStatusType.New,
      name: this._translate.instant('dictionary.supportRequestStatuses.new'),
    },
    {
      id: SupportRequestStatusType.InProgress,
      name: this._translate.instant('dictionary.supportRequestStatuses.inProgress'),
    },
    {
      id: SupportRequestStatusType.Completed,
      name: this._translate.instant('dictionary.supportRequestStatuses.completed'),
    },
  ];

  courseStatuses: DictionaryItem<CourseStatusType>[] = [
    {
      id: CourseStatusType.InProgress,
      name: this._translate.instant('dictionary.courseStatuses.inProgress'),
    },
    {
      id: CourseStatusType.Completed,
      name: this._translate.instant('dictionary.courseStatuses.completed'),
    },
    {
      id: CourseStatusType.NotPassed,
      name: this._translate.instant('dictionary.courseStatuses.notPassed'),
    },
  ];

  courseTypes: DictionaryItem<CourseType>[] = [
    { id: CourseType.Mandatory, name: this._translate.instant('dictionary.courseTypes.mandatory') },
    { id: CourseType.Open, name: this._translate.instant('dictionary.courseTypes.open') },
    { id: CourseType.Request, name: this._translate.instant('dictionary.courseTypes.request') },
  ];

  messageStatuses: DictionaryItem<MessageStatus>[] = [
    {
      id: MessageStatus.Scheduled,
      name: this._translate.instant('dictionary.messageStatuses.scheduled'),
    },
    { id: MessageStatus.Sent, name: this._translate.instant('dictionary.messageStatuses.sent') },
  ];

  periods: DictionaryItem<number>[] = [
    { id: null, name: this._translate.instant('dictionary.periods.all') },
    { id: 1, name: this._translate.instant('dictionary.periods.month') },
    { id: 3, name: this._translate.instant('dictionary.periods.threeMonths') },
    { id: 6, name: this._translate.instant('dictionary.periods.sixMonths') },
    { id: 12, name: this._translate.instant('dictionary.periods.year') },
  ];

  supportRequestCategories: DictionaryItem<SupportRequestCategoryType>[] = [
    {
      id: SupportRequestCategoryType.Office,
      name: this._translate.instant('dictionary.supportCategories.office'),
      code: 'AdminSupportOffice',
    },
    {
      id: SupportRequestCategoryType.Education,
      name: this._translate.instant('dictionary.supportCategories.education'),
      code: 'AdminSupportEducation',
    },
    {
      id: SupportRequestCategoryType.Platform,
      name: this._translate.instant('dictionary.supportCategories.platform'),
      code: 'AdminSupportPlatform',
    },
    {
      id: SupportRequestCategoryType.Finance,
      name: this._translate.instant('dictionary.supportCategories.finance'),
      code: 'AdminSupportFinance',
    },
  ];

  events: DictionaryItem<string>[] = [
    { id: 'new_account', name: this._translate.instant('dictionary.events.newAccount') },
    { id: 'probation_end', name: this._translate.instant('dictionary.events.probationEnd') },
    { id: 'birthday', name: this._translate.instant('dictionary.events.birthday') },
    {
      id: 'personal_year_start',
      name: this._translate.instant('dictionary.events.personalYearStart'),
    },
  ];

  months: DictionaryItem<number>[] = [
    { id: 1, name: this._translate.instant('dictionary.months.january') },
    { id: 2, name: this._translate.instant('dictionary.months.february') },
    { id: 3, name: this._translate.instant('dictionary.months.march') },
    { id: 4, name: this._translate.instant('dictionary.months.april') },
    { id: 5, name: this._translate.instant('dictionary.months.may') },
    { id: 6, name: this._translate.instant('dictionary.months.june') },
    { id: 7, name: this._translate.instant('dictionary.months.july') },
    { id: 8, name: this._translate.instant('dictionary.months.august') },
    { id: 9, name: this._translate.instant('dictionary.months.september') },
    { id: 10, name: this._translate.instant('dictionary.months.october') },
    { id: 11, name: this._translate.instant('dictionary.months.november') },
    { id: 12, name: this._translate.instant('dictionary.months.december') },
  ];

  monthsKeys = [
    'dictionary.months.january',
    'dictionary.months.february',
    'dictionary.months.march',
    'dictionary.months.april',
    'dictionary.months.may',
    'dictionary.months.june',
    'dictionary.months.july',
    'dictionary.months.august',
    'dictionary.months.september',
    'dictionary.months.october',
    'dictionary.months.november',
    'dictionary.months.december',
  ];

  automaticAccrual: DictionaryItem<string>[] = [
    {
      id: 'monthly-group-tasks',
      name: this._translate.instant('admin.coins.automaticAccrual.table.type.monthlyGroup'),
      description: this._translate.instant(
        'admin.coins.automaticAccrual.table.description.automaticAccrueMonthly',
      ),
    },
    {
      id: 'yearly-group-tasks',
      name: this._translate.instant('admin.coins.automaticAccrual.table.type.yearlyGroup'),
      description: this._translate.instant(
        'admin.coins.automaticAccrual.table.description.automaticAccrueYearly',
      ),
    },
    {
      id: 'monthly-user-tasks',
      name: this._translate.instant('admin.coins.automaticAccrual.table.type.user'),
      description: this._translate.instant(
        'admin.coins.automaticAccrual.table.description.assingAnAward',
      ),
    },
  ];

  automaticTemplates: DictionaryItem<string>[] = [
    {
      id: 'birthday',
      name: this._translate.instant('admin.templates.automatic.table.type.birthday'),
    },
    {
      id: 'anniversary',
      name: this._translate.instant('admin.templates.automatic.table.type.anniversary'),
    },
    {
      id: 'newcomers',
      name: this._translate.instant('admin.templates.automatic.table.type.newcomers'),
    },
    {
      id: 'probation-end',
      name: this._translate.instant('admin.templates.automatic.table.type.probationEnd'),
    },
  ];

  manualTemplates: DictionaryItem<string>[] = [
    {
      id: 'wedding',
      name: this._translate.instant('admin.templates.manual.table.type.wedding'),
    },
    {
      id: 'birth-child',
      name: this._translate.instant('admin.templates.manual.table.type.birthChild'),
    },
    {
      id: 'promotion',
      name: this._translate.instant('admin.templates.manual.table.type.promotion'),
    },
  ];

  automaticCongratsTypes: DictionaryItem<AutomaticCongratsType>[] = [
    { id: 'birthday', name: this._translate.instant('dictionary.automaticCongratsTypes.birthday') },
    {
      id: 'personalYearStart',
      name: this._translate.instant('dictionary.automaticCongratsTypes.personalYearStart'),
    },
    {
      id: 'probationEnd',
      name: this._translate.instant('dictionary.automaticCongratsTypes.probationEnd'),
    },
  ];

  manualCongratsTypes: DictionaryItem<ManualCongratsType>[] = [
    { id: 'wedding', name: this._translate.instant('dictionary.manualCongratsTypes.wedding') },
    {
      id: 'birthChild',
      name: this._translate.instant('dictionary.manualCongratsTypes.birthChild'),
    },
    { id: 'promotion', name: this._translate.instant('dictionary.manualCongratsTypes.promotion') },
  ];

  templateTypes: DictionaryItem<string>[] = [
    ...this.automaticCongratsTypes,
    ...this.manualCongratsTypes,
    { id: 'newcomers', name: this._translate.instant('dictionary.templateTypes.newcomers') },
  ];

  approvalStatuses: DictionaryItem<LimitApprovalStatus>[] = [
    { id: null, name: this._translate.instant('dictionary.approvalStatuses.all') },
    { id: 'auto', name: this._translate.instant('dictionary.approvalStatuses.auto') },
    { id: 'rejected', name: this._translate.instant('dictionary.approvalStatuses.rejected') },
    { id: 'inReview', name: this._translate.instant('dictionary.approvalStatuses.inReview') },
    { id: 'approved', name: this._translate.instant('dictionary.approvalStatuses.approved') },
  ];

  communityStatuses: DictionaryItem<CommunityRequestStatus>[] = [
    {
      id: CommunityRequestStatus.Rejected,
      name: this._translate.instant('dictionary.community.rejected'),
    },
    {
      id: CommunityRequestStatus.InReview,
      name: this._translate.instant('dictionary.community.inReview'),
    },
    {
      id: CommunityRequestStatus.Approved,
      name: this._translate.instant('dictionary.community.approved'),
    },
  ];

  achievementTypes: DictionaryItem<AchievementType>[] = [
    {
      id: AchievementType.Unique,
      name: this._translate.instant('dictionary.achievementTypes.unique'),
    },
    {
      id: AchievementType.Colleagues,
      name: this._translate.instant('dictionary.achievementTypes.colleagues'),
    },
    {
      id: AchievementType.Managers,
      name: this._translate.instant('dictionary.achievementTypes.managers'),
    },
    {
      id: AchievementType.Education,
      name: this._translate.instant('dictionary.achievementTypes.education'),
    },
    {
      id: AchievementType.Onboarding,
      name: this._translate.instant('dictionary.achievementTypes.onboarding'),
    },
    {
      id: AchievementType.Surveys,
      name: this._translate.instant('dictionary.achievementTypes.surveys'),
    },
  ];

  achievementStatuses: DictionaryItem<AchievementStatus>[] = [
    {
      id: AchievementStatus.Sent,
      name: this._translate.instant('dictionary.achievementStatuses.sent'),
    },
    {
      id: AchievementStatus.Received,
      name: this._translate.instant('dictionary.achievementStatuses.received'),
    },
  ];

  userRoles: DictionaryItem<UserRoles>[] = [
    { id: UserRoles.User, name: this._translate.instant('dictionary.userRoles.user') },
    { id: UserRoles.Admin, name: this._translate.instant('dictionary.userRoles.admin') },
  ];

  tgChatPermissions: DictionaryItem<TgChatPermissions>[] = [
    {
      id: TgChatPermissions.Member,
      name: this._translate.instant('dictionary.tgChatPermissions.member'),
    },
    {
      id: TgChatPermissions.Admin,
      name: this._translate.instant('dictionary.tgChatPermissions.admin'),
    },
  ];

  tgUserPermissions: DictionaryItem<TgUserPermissions>[] = [
    {
      id: TgUserPermissions.Member,
      name: this._translate.instant('dictionary.tgUserPermissions.member'),
    },
    {
      id: TgUserPermissions.Owner,
      name: this._translate.instant('dictionary.tgUserPermissions.owner'),
    },
    {
      id: TgUserPermissions.Admin,
      name: this._translate.instant('dictionary.tgUserPermissions.admin'),
    },
  ];

  tgChatTypes: DictionaryItem<TgChatTypes>[] = [
    {
      id: TgChatTypes.Channel,
      name: this._translate.instant('dictionary.tgChatTypes.channel'),
    },
    {
      id: TgChatTypes.Bot,
      name: this._translate.instant('dictionary.tgChatTypes.bot'),
    },
    {
      id: TgChatTypes.User,
      name: this._translate.instant('dictionary.tgChatTypes.user'),
    },
  ];

  userStatuses: DictionaryItem<UserStatusType>[] = [
    { id: 'active', name: this._translate.instant('dictionary.userStatuses.active') },
    { id: 'probation', name: this._translate.instant('dictionary.userStatuses.probation') },
    { id: 'offboarding', name: this._translate.instant('dictionary.userStatuses.offboarding') },
  ];

  channelTypes: DictionaryItem<SlackChannelType>[] = [
    { id: 'news', name: this._translate.instant('dictionary.channelTypes.news') },
    { id: 'team', name: this._translate.instant('dictionary.channelTypes.team') },
    { id: 'club', name: this._translate.instant('dictionary.channelTypes.club') },
    { id: 'office', name: this._translate.instant('dictionary.channelTypes.office') },
    { id: 'community', name: this._translate.instant('dictionary.channelTypes.community') },
  ];

  sortTypes: SortType[] = [
    { id: 'id', name: this._translate.instant('dictionary.sortTypes.id') },
    { id: '-id', name: this._translate.instant('dictionary.sortTypes.idDesc') },
    { id: 'name', name: this._translate.instant('dictionary.sortTypes.name') },
    { id: '-name', name: this._translate.instant('dictionary.sortTypes.nameDesc') },
    { id: 'login', name: this._translate.instant('dictionary.sortTypes.login') },
    { id: '-login', name: this._translate.instant('dictionary.sortTypes.loginDesc') },
    { id: 'date', name: this._translate.instant('dictionary.sortTypes.date') },
    { id: '-date', name: this._translate.instant('dictionary.sortTypes.dateDesc') },
    { id: 'title', name: this._translate.instant('dictionary.sortTypes.title') },
    { id: '-title', name: this._translate.instant('dictionary.sortTypes.titleDesc') },
    { id: 'price', name: this._translate.instant('dictionary.sortTypes.price') },
    { id: '-price', name: this._translate.instant('dictionary.sortTypes.priceDesc') },
    { id: 'reward', name: this._translate.instant('dictionary.sortTypes.reward') },
    { id: '-reward', name: this._translate.instant('dictionary.sortTypes.rewardDesc') },
    { id: 'members', name: this._translate.instant('dictionary.sortTypes.members') },
    { id: '-members', name: this._translate.instant('dictionary.sortTypes.membersDesc') },
    { id: 'new', name: this._translate.instant('dictionary.sortTypes.new') },
    { id: 'sale', name: this._translate.instant('dictionary.sortTypes.sale') },
    { id: 'mostRecently', name: this._translate.instant('dictionary.sortTypes.mostRecently') },
  ];

  workingMessengers: DictionaryItem<string>[] = [
    { id: Messengers.Telegram, name: 'Telegram' },
    { id: Messengers.Instagram, name: 'Instagram' },
    { id: Messengers.WhatsApp, name: 'WhatsApp' },
    { id: Messengers.LinkedIn, name: 'LinkedIn' },
    { id: Messengers.FaceBook, name: 'Facebook' },
  ];

  workingMessengerLinks: WorkingMessengerLinks = {
    [Messengers.Telegram]: {
      prefix: 'https://www.telegram.me/',
    },
    [Messengers.Instagram]: {
      prefix: 'https://www.instagram.com/',
    },
    [Messengers.WhatsApp]: {
      prefix: 'https://wa.me/',
    },
    [Messengers.FaceBook]: {
      prefix: '',
      substringPrefix: 'https://www.facebook.com/',
    },
    [Messengers.LinkedIn]: {
      prefix: '',
      substringPrefix: 'https://www.linkedin.com/in/',
    },
  };

  groupTags: DictionaryItem<GroupTagEnum>[] = [
    { id: GroupTagEnum.Courses, name: this._translate.instant('dictionary.groupTags.courses') },
    {
      id: GroupTagEnum.CoursesNotification,
      name: this._translate.instant('dictionary.groupTags.coursesNotification'),
    },
    { id: GroupTagEnum.FAQ, name: this._translate.instant('dictionary.groupTags.faq') },
    { id: GroupTagEnum.Messages, name: this._translate.instant('dictionary.groupTags.messages') },
    { id: GroupTagEnum.News, name: this._translate.instant('dictionary.groupTags.news') },
    { id: GroupTagEnum.Coins, name: this._translate.instant('dictionary.groupTags.coins') },
    { id: GroupTagEnum.Survey, name: this._translate.instant('dictionary.groupTags.survey') },
    {
      id: GroupTagEnum.PrivateEvents,
      name: this._translate.instant('dictionary.groupTags.privateEvents'),
    },
  ];

  surveyStatuses: DictionaryItem<SurveyStatus>[] = [
    {
      id: SurveyStatus.PLANNED,
      name: this._translate.instant('dictionary.surveyStatuses.planned'),
    },
    {
      id: SurveyStatus.IN_PROGRESS,
      name: this._translate.instant('dictionary.surveyStatuses.inProgress'),
    },
    {
      id: SurveyStatus.FINISHED,
      name: this._translate.instant('dictionary.surveyStatuses.finished'),
    },
  ];

  questionTypes: DictionaryItem<QuestionType>[] = [
    {
      id: QuestionType.SHORT,
      name: this._translate.instant('dictionary.surveyQuestionType.short'),
    },
    {
      id: QuestionType.LONG,
      name: this._translate.instant('dictionary.surveyQuestionType.long'),
    },
    {
      id: QuestionType.RADIO,
      name: this._translate.instant('dictionary.surveyQuestionType.radio'),
    },
    {
      id: QuestionType.CHECKBOX,
      name: this._translate.instant('dictionary.surveyQuestionType.checkbox'),
    },
    {
      id: QuestionType.RATING,
      name: this._translate.instant('dictionary.surveyQuestionType.rating'),
    },
    {
      id: QuestionType.LINEAR_SCALE,
      name: this._translate.instant('dictionary.surveyQuestionType.linear'),
    },
    {
      id: QuestionType.CHOICE_GRID,
      name: this._translate.instant('dictionary.surveyQuestionType.choiceGrid'),
    },
    {
      id: QuestionType.CHECKBOX_GRID,
      name: this._translate.instant('dictionary.surveyQuestionType.checkboxGrid'),
    },
  ];

  blogStatuses: DictionaryItem<BlogStatus>[] = [
    {
      id: BlogStatus.InReview,
      name: this._translate.instant('dictionary.blogStatuses.inReview'),
    },
    {
      id: BlogStatus.Approved,
      name: this._translate.instant('dictionary.blogStatuses.approved'),
    },
  ];

  usersCourseStatuses: DictionaryItem<CourseStatusType>[] = [
    {
      id: CourseStatusType.NotPassed,
      name: this._translate.instant('dictionary.usersCourseStatuses.notPassed'),
    },
    {
      id: CourseStatusType.InProgress,
      name: this._translate.instant('dictionary.usersCourseStatuses.inProgress'),
    },
    {
      id: CourseStatusType.Completed,
      name: this._translate.instant('dictionary.usersCourseStatuses.completed'),
    },
    {
      id: CourseStatusType.NotStarted,
      name: this._translate.instant('dictionary.usersCourseStatuses.notStarted'),
    },
  ];

  courseRequestStatuses: DictionaryItem<CourseRequestStatus>[] = [
    {
      id: CourseRequestStatus.InReview,
      name: this._translate.instant('dictionary.courseRequestStatuses.inReview'),
    },
    {
      id: CourseRequestStatus.Approved,
      name: this._translate.instant('dictionary.courseRequestStatuses.approved'),
    },
    {
      id: CourseRequestStatus.Rejected,
      name: this._translate.instant('dictionary.courseRequestStatuses.rejected'),
    },
  ];

  timezones: DictionaryItem<TimezoneTypes>[] = [
    {
      id: TimezoneTypes.Kiev,
      name: this._translate.instant('dictionary.timeZones.Europe/Kiev'),
    },
    {
      id: TimezoneTypes.Warsaw,
      name: this._translate.instant('dictionary.timeZones.Europe/Warsaw'),
    },
    {
      id: TimezoneTypes.Almaty,
      name: this._translate.instant('dictionary.timeZones.Asia/Almaty'),
    },
    {
      id: TimezoneTypes.Budapest,
      name: this._translate.instant('dictionary.timeZones.Europe/Budapest'),
    },
    {
      id: TimezoneTypes.Lima,
      name: this._translate.instant('dictionary.timeZones.America/Lima'),
    },
    {
      id: TimezoneTypes.Nicosia,
      name: this._translate.instant('dictionary.timeZones.Asia/Nicosia'),
    },
  ];

  sharingSessionEventTypes: DictionaryItem<SharingSessionType>[] = [
    {
      id: SharingSessionType.Open,
      name: this._translate.instant('dictionary.sharingSessionEventTypes.open'),
    },
    {
      id: SharingSessionType.Private,
      name: this._translate.instant('dictionary.sharingSessionEventTypes.private'),
    },
    {
      id: SharingSessionType.Request,
      name: this._translate.instant('dictionary.sharingSessionEventTypes.request'),
    },
  ];

  educationEventStatuses: DictionaryItem<EducationEventStatus>[] = [
    {
      id: EducationEventStatus.InReview,
      name: this._translate.instant('dictionary.educationEventStatuses.inReview'),
    },
    {
      id: EducationEventStatus.Approved,
      name: this._translate.instant('dictionary.educationEventStatuses.approved'),
    },
    {
      id: EducationEventStatus.Rejected,
      name: this._translate.instant('dictionary.educationEventStatuses.rejected'),
    },
    {
      id: EducationEventStatus.Passed,
      name: this._translate.instant('dictionary.educationEventStatuses.passed'),
    },
    {
      id: EducationEventStatus.Canceled,
      name: this._translate.instant('dictionary.educationEventStatuses.canceled'),
    },
  ];

  educationEventRequestStatuses: DictionaryItem<EducationRequestStatus>[] = [
    {
      id: EducationRequestStatus.InReview,
      name: this._translate.instant('dictionary.educationEventStatuses.inReview'),
    },
    {
      id: EducationRequestStatus.Approved,
      name: this._translate.instant('dictionary.educationEventStatuses.approved'),
    },
    {
      id: EducationRequestStatus.Rejected,
      name: this._translate.instant('dictionary.educationEventStatuses.rejected'),
    },
  ];

  educationEventsCalendarViewOptions: SwitchButtonOption<EventsViewType>[] = [
    {
      id: EventsViewType.Cards,
      label: this._translate.instant('education.events.viewOptions.cards'),
      icon: 'list',
    },
    {
      id: EventsViewType.Calendar,
      label: this._translate.instant('education.events.viewOptions.calendar'),
      icon: 'calendar',
    },
  ];

  educationEventsRatings: DictionaryItem<number>[] = [
    { id: 1, name: this._translate.instant('education.events.rateModal.rate1') },
    { id: 2, name: this._translate.instant('education.events.rateModal.rate2') },
    { id: 3, name: this._translate.instant('education.events.rateModal.rate3') },
    { id: 4, name: this._translate.instant('education.events.rateModal.rate4') },
    { id: 5, name: this._translate.instant('education.events.rateModal.rate5') },
  ];

  languagesOptions: DictionaryItem<EventLanguageOption>[] = [
    {
      id: EventLanguageOption.English,
      name: this._translate.instant('dictionary.languages.en'),
    },
    {
      id: EventLanguageOption.Russian,
      name: this._translate.instant('dictionary.languages.ru'),
    },
    {
      id: EventLanguageOption.Ukrainian,
      name: this._translate.instant('dictionary.languages.ua'),
    },
    {
      id: EventLanguageOption.Spanish,
      name: this._translate.instant('dictionary.languages.es'),
    },
    {
      id: EventLanguageOption.Kazakh,
      name: this._translate.instant('dictionary.languages.kz'),
    },
    {
      id: EventLanguageOption.Polish,
      name: this._translate.instant('dictionary.languages.pl'),
    },
    {
      id: EventLanguageOption.Portuguese,
      name: this._translate.instant('dictionary.languages.pt'),
    },
    {
      id: EventLanguageOption.Armenian,
      name: this._translate.instant('dictionary.languages.am'),
    },
  ];

  subordinatesTypes: DictionaryItem<SubordinatesTypes>[] = [
    {
      id: 'direct',
      name: this._translate.instant('dictionary.subordinateType.direct'),
    },
    {
      id: 'functional',
      name: this._translate.instant('dictionary.subordinateType.functional'),
    },
  ];

  subordinatesLevels: DictionaryItem<SubordinatesLevels>[] = [
    {
      id: 'proximate',
      name: this._translate.instant('dictionary.subordinateLevel.proximate'),
    },
    {
      id: 'total',
      name: this._translate.instant('dictionary.subordinateLevel.total'),
    },
  ];

  educationEventAuthorCommentTypes: DictionaryItem<EducationEventAuthorCommentType>[] = [
    {
      id: EducationEventAuthorCommentType.ContentReview,
      name: this._translate.instant(
        'dictionary.educationEventAuthorCommentType.contentReview',
      ),
    },
    {
      id: EducationEventAuthorCommentType.SpeakerPreparation,
      name: this._translate.instant(
        'dictionary.educationEventAuthorCommentType.speakerPreparation',
      ),
    },
    {
      id: EducationEventAuthorCommentType.LearningSupport,
      name: this._translate.instant(
        'dictionary.educationEventAuthorCommentType.learningSupport',
      ),
    },
    {
      id: EducationEventAuthorCommentType.GroupAssignment,
      name: this._translate.instant('dictionary.educationEventAuthorCommentType.groupAssignment'),
    },
  ];

  officeVisitsTypes: DictionaryItem<OfficeVisitsTypes>[] = [
    {
      id: 'vacationPaid',
      name: this._translate.instant('dictionary.officeVisitsTypes.vacationPaid'),
    },
    {
      id: 'vacationUnpaid',
      name: this._translate.instant('dictionary.officeVisitsTypes.vacationUnpaid'),
    },
    {
      id: 'sickLeavePaid',
      name: this._translate.instant('dictionary.officeVisitsTypes.sickLeavePaid'),
    },
    {
      id: 'sickLeaveUnpaid',
      name: this._translate.instant('dictionary.officeVisitsTypes.sickLeaveUnpaid'),
    },
    {
      id: 'dayOff',
      name: this._translate.instant('dictionary.officeVisitsTypes.dayOff'),
    },
    {
      id: 'familyDay',
      name: this._translate.instant('dictionary.officeVisitsTypes.familyDay'),
    },
    {
      id: 'recoveryDayOff',
      name: this._translate.instant('dictionary.officeVisitsTypes.recoveryDayOff'),
    },
    {
      id: 'workFromHome',
      name: this._translate.instant('dictionary.officeVisitsTypes.workFromHome'),
    },
    {
      id: 'businessTrip',
      name: this._translate.instant('dictionary.officeVisitsTypes.businessTrip'),
    },
    {
      id: 'corporateEvent',
      name: this._translate.instant('dictionary.officeVisitsTypes.corporateEvent'),
    },
  ];

  officeVisitsStatuses: DictionaryItem<OfficeVisitsStatuses>[] = [
    {
      id: 'canceled',
      name: this._translate.instant('dictionary.officeVisitsStatuses.canceled'),
    },
    {
      id: 'waitingReport',
      name: this._translate.instant('dictionary.officeVisitsStatuses.waitingReport'),
    },
    {
      id: 'needMoreInfo',
      name: this._translate.instant('dictionary.officeVisitsStatuses.needMoreInfo'),
    },
    {
      id: 'reportProvided',
      name: this._translate.instant('dictionary.officeVisitsStatuses.reportProvided'),
    },
    {
      id: 'closed',
      name: this._translate.instant('dictionary.officeVisitsStatuses.closed'),
    },
    {
      id: 'c-levelReview',
      name: this._translate.instant('dictionary.officeVisitsStatuses.c-levelReview'),
    },
    {
      id: 'approved',
      name: this._translate.instant('dictionary.officeVisitsStatuses.approved'),
    },
    {
      id: 'toApprove',
      name: this._translate.instant('dictionary.officeVisitsStatuses.toApprove'),
    },
    {
      id: 'review',
      name: this._translate.instant('dictionary.officeVisitsStatuses.review'),
    },
    {
      id: 'rejected',
      name: this._translate.instant('dictionary.officeVisitsStatuses.rejected'),
    },
    {
      id: 'inactive',
      name: this._translate.instant('dictionary.officeVisitsStatuses.inactive'),
    },
  ];

  communityRoles: DictionaryItem<CommunityRoles>[] = [
    {
      id: CommunityRoles.Expert,
      name: this._translate.instant('dictionary.community.role.expert'),
    },
    {
      id: CommunityRoles.Listener,
      name: this._translate.instant('dictionary.community.role.listener'),
    },
    {
      id: CommunityRoles.Speaker,
      name: this._translate.instant('dictionary.community.role.speaker'),
    },
    {
      id: CommunityRoles.Moderator,
      name: this._translate.instant('dictionary.community.role.moderator'),
    },
  ];

  financialRequestStatuses: DictionaryItem<FinancialRequestStatus>[] = [
    {
      id: FinancialRequestStatus.New,
      name: this._translate.instant('dictionary.financialRequestStatuses.new'),
    },
    {
      id: FinancialRequestStatus.InProgress,
      name: this._translate.instant('dictionary.financialRequestStatuses.inProgress'),
    },
    {
      id: FinancialRequestStatus.Completed,
      name: this._translate.instant('dictionary.financialRequestStatuses.completed'),
    },
    {
      id: FinancialRequestStatus.Rejected,
      name: this._translate.instant('dictionary.financialRequestStatuses.rejected'),
    },
    {
      id: FinancialRequestStatus.Canceled,
      name: this._translate.instant('dictionary.financialRequestStatuses.canceled'),
    },
  ];

  welcomeContentTypes: DictionaryItem<WelcomeContentType>[] = [
    {
      id: WelcomeContentType.Communities,
      name: this._translate.instant('dictionary.welcomeContentTypes.communities'),
    },
    {
      id: WelcomeContentType.Library,
      name: this._translate.instant('dictionary.welcomeContentTypes.library'),
    },
    {
      id: WelcomeContentType.EducationEvents,
      name: this._translate.instant('dictionary.welcomeContentTypes.educationEvents'),
    },
  ];

  constructor(private _translate: TranslateService) {
  }

  filterSortTypes(...ids: string[]): SortType[] {
    const doubleIds = [...ids, ...ids.map(id => `-${id}`)];

    return this.sortTypes.filter(item => doubleIds.includes(item.id));
  }
}
