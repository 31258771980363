import {
  ChangeDetectionStrategy, Component, computed, effect, ElementRef, inject, input, Renderer2,
  viewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { ResizeObserverDirective } from '@pinup-teams/common';
import { debounceTime, merge, ReplaySubject, take } from 'rxjs';

import { DonutChart } from './donut-chart';
import { DonutChartConfig, DonutChartData, DonutChartLegendItem } from './donut-chart.abstraction';

@Component({
  selector: 'pt-donut-chart',
  standalone: true,
  imports: [
    ResizeObserverDirective,
  ],
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DonutChartComponent {
  #renderer = inject(Renderer2);

  chartElementRef = viewChild<ElementRef>('chartWrapper');
  #chartElement = computed(() => this.chartElementRef()!.nativeElement);
  #chart = computed(() => new DonutChart());

  #renderChartEffect = effect(() => {
    this.#renderer.appendChild(this.#chartElement(), this.#chart().svgElement);
  });

  #svgResizedSubject = new ReplaySubject<{ width: number; height: number }>(Infinity);

  #svgSize = toSignal(
    merge(
      this.#svgResizedSubject.pipe(take(1)),
      this.#svgResizedSubject.pipe(debounceTime(500)),
    ),
  );
  data = input.required<DonutChartData[]>();
  config = input<DonutChartConfig>();
  legend = computed<DonutChartLegendItem[]>(() => this.data().map(item => ({
    name: item.label,
    color: this.#chart().colorScale(item.label),
  })));

  #dataChangedEffect = effect(() => {
    this.#chart().handleDataChange(this.data());
  });

  #configChangedEffect = effect(() => {
    this.#chart().handleConfigChange(this.config() || {});
  });

  #svgResizedEffect = effect(() => {
    if (this.#svgSize()) {
      this.#chart().handleChartResize(this.#svgSize()!.width, this.#svgSize()!.height);
    }
  });

  svgResized(event: ResizeObserverEntry): void {
    this.#svgResizedSubject.next({
      width: event.contentRect.width,
      height: event.contentRect.height,
    });
  }
}
