import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, DialogRef, IconModule, UaParserService, WINDOW } from '@pinup-teams/common';
import { environment } from '@pt/environment';

import { BeforeInstallPromptEvent, IS_PWA_INSTALLED } from './pwa-setup.model';

@Component({
  standalone: true,
  imports: [TranslateModule, ButtonModule, IconModule],
  selector: 'pu-pwa-setup',
  templateUrl: './pwa-setup.component.html',
  styleUrls: ['./pwa-setup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PwaSetupComponent implements OnInit {
  readonly #dialogRef = inject(DialogRef);
  readonly #uaParser = inject(UaParserService);
  readonly #window = inject(WINDOW);

  #promptEvent: BeforeInstallPromptEvent | null = null;

  isIOS = signal(this.#uaParser.isIOS);
  assetsHost = signal(environment.assetsHost);
  isInstalling = signal(false);
  canInstallPwa = signal(false);

  srcSet = signal(`
  ${this.assetsHost()}/assets/app-images/pwa/preview/preview.png 400w,
  ${this.assetsHost()}/assets/app-images/pwa/preview/preview@x2.png 800w,
  ${this.assetsHost()}/assets/app-images/pwa/preview/preview-mob.png 343w
`);

  ngOnInit(): void {
    this.#initPwaPrompt();
  }

  close(): void {
    this.#dialogRef.close();
  }

  installPwa(): void {
    if (!this.canInstallPwa()) {
      return;
    }
    this.isInstalling.set(true);
    this.canInstallPwa.set(false);
    this.#promptEvent.prompt();
    this.#promptEvent.userChoice.then(choiceResult => {
      choiceResult.outcome === 'accepted'
        ? localStorage.setItem(IS_PWA_INSTALLED, 'true')
        : this.#promptEvent = null;
    });
  }

  #initPwaPrompt(): void {
    this.#window.addEventListener('beforeinstallprompt', this.#beforeInstallPromptHandler);
  }

  #beforeInstallPromptHandler = (event: Event): void => {
    event.preventDefault();
    this.#promptEvent = event as BeforeInstallPromptEvent;
    this.canInstallPwa.set(!!this.#promptEvent);
  };
}
