import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';
import { Reaction } from '@pt/models';

import {
  ReactionReq,
  GetUsersReactionsRes,
} from '../models';

export interface ReactionsApi {
  addReaction(params: ReactionReq): Observable<Reaction>;
  deleteReaction(params: ReactionReq): Observable<void>;
  getUsersReactions(params?: ReactionReq): Observable<GetUsersReactionsRes>;
}

export const REACTIONS_API = new InjectionToken<ReactionsApi>('REACTIONS_API');
