import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import FroalaEditor from 'froala-editor';
import {
  ButtonModule,
  CheckboxComponentModule,
  ControlValueChangesDirective,
  DIALOG_DATA,
  DialogRef,
  FileModel,
  FroalaConfig,
  FroalaModule,
  getEditorConfig,
  IconModule,
  MultiSelectModule,
  PopoverDirective,
  RadioOptionModule,
  SelectComponent,
  SimpleInputModule,
  uniqueId,
  UploaderModule,
} from '@pinup-teams/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MAX_FILE_SIZE } from '@pt/constants';
import { DictionariesService } from '@pt/services';
import { FormControl } from '@ngneat/reactive-forms';
import { FlippingTypes } from '@pt/models';
import { maxArrayLength } from '@pt/validators';
import { environment } from '@pt/environment';
import { Observable, Subject } from 'rxjs';

import {
  MaterialForm,
  MaterialDialogData,
  LibraryMaterialFormData,
  MaterialFlippingBookForm,
  UploadFileRes,
  UploadedLink,
} from './models';

@Component({
  selector: 'pt-material-form',
  templateUrl: './material-form.component.html',
  styleUrl: './material-form.component.scss',
  standalone: true,
  imports: [
    TranslateModule,
    ButtonModule,
    IconModule,
    UploaderModule,
    ReactiveFormsModule,
    SimpleInputModule,
    MultiSelectModule,
    SelectComponent,
    FroalaModule,
    RadioOptionModule,
    CheckboxComponentModule,
    ControlValueChangesDirective,
    PopoverDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialFormComponent implements OnInit {
  readonly #dialogRef = inject(DialogRef);
  readonly #formBuilder = inject(FormBuilder);
  readonly #translateService = inject(TranslateService);
  readonly #data = inject<MaterialDialogData>(DIALOG_DATA);
  readonly #dictionariesService = inject(DictionariesService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #httpsClient = inject(HttpClient);

  editor: FroalaEditor;
  insertImage$ = new Subject<UploadedLink>();

  readonly MAX_FILE_SIZE = MAX_FILE_SIZE;

  title = this.#data.modalTitle;
  btnTitle = this.#data.completeBtnTitle;
  form: FormGroup<MaterialForm>;
  editorConfig = getEditorConfig({
    imageUploadRemoteUrls: false,
    imageUpload: false,
    imageDefaultAlign: 'center',
    imageDefaultDisplay: 'inline-block',
    imageMaxSize: 10 * 1024 * 1024,
    charCounterMax: 15000,
    placeholderText: this.#translateService.instant(
      'admin.library.materials.add-material.modal.form.description.label',
    ),
    events: {
      initialized: this.#onEditorInit.bind(this),
      'image.beforeUpload': this.#onImageAdded.bind(this),
    },
  } as unknown as FroalaConfig);
  categorySelect = this.#data.categories;
  typeSelect = this.#dictionariesService.materialTypes;
  languageSelect = this.#dictionariesService.languages;
  flippingHorizontal = {
    value: FlippingTypes.Horizontal,
    label: this.#translateService.instant(
      'admin.library.request.reviewModal.edit.form.flipping.horizontal',
    ),
  };
  flippingVertical = {
    value: FlippingTypes.Vertical,
    label: this.#translateService.instant(
      'admin.library.request.reviewModal.edit.form.flipping.vertical',
    ),
  };

  isFlippingBook: FormControl<boolean> = new FormControl(!!this.#data.material?.flippingBook);

  ngOnInit(): void {
    this.#initializeForm();
    const material = this.#data.material;

    if (material) {
      this.form.patchValue(material);
    }
  }

  isFlippingBookChange(value: boolean): void {
    const setValidators = (required: boolean) => {
      const validators = required ? [Validators.required] : [];
      this.form.get('flippingBook.file').setValidators(validators);
      this.form.get('flippingBook.formatType').setValidators(validators);
      this.form.get('flippingBook.buttonText').setValidators(validators);
      this.form.get('flippingBook.file').updateValueAndValidity();
      this.form.get('flippingBook.formatType').updateValueAndValidity();
      this.form.get('flippingBook.buttonText').updateValueAndValidity();
    };

    if (value) {
      setValidators(true);
    } else {
      this.form.patchValue(
        {
          flippingBook: {
            file: null,
            formatType: null,
            buttonText: null,
          },
        },
        { emitEvent: false },
      );
      setValidators(false);
    }
  }

  close(): void {
    this.#dialogRef.close();
  }

  addMaterial(): void {
    const material: LibraryMaterialFormData = {
      ...this.form.value,
      visible: this.#data.material?.visible || false,
    } as LibraryMaterialFormData;

    if (!this.isFlippingBook.value) {
      material.flippingBook = null;
    }

    this.#dialogRef.close({
      ...material,
      ...this.#data.material?.id && { id: this.#data.material.id },
    });
  }

  #initializeForm(): void {
    this.form = this.#formBuilder.group<MaterialForm>({
      coverImage: new FormControl(null),
      files: new FormControl([]),
      title: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      libraryTypes: new FormControl([], [Validators.required, maxArrayLength(2)]),
      categoriesIds: new FormControl([], [Validators.required]),
      description: new FormControl('', [Validators.required]),
      language: new FormControl('', [Validators.required]),
      videoUrl: new FormControl('', [Validators.pattern(/^https?:\/\/.+/)]),
      flippingBook: new FormGroup<MaterialFlippingBookForm>({
        file: new FormControl<FileModel>(null),
        buttonText: new FormControl<string>(''),
        formatType: new FormControl<FlippingTypes>(null),
      }),
      extraImages: new FormControl([], maxArrayLength(10)),
    });
  }

  #onEditorInit(editor: FroalaEditor): void {
    this.editor = editor['_editor'];

    this.#initImageInsert();
  }

  #onImageAdded(images: File[]): boolean {
    images.forEach(this.#uploadImage.bind(this));

    return false;
  }

  #initImageInsert(): void {
    (this.insertImage$ as Observable<UploadedLink>).pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(
        data => {
          this.editor.image.insert(
            data.link,
            true,
            null,
            this.editor.image.get(),
          );
        },
      );
  }

  #uploadImage(image: File): void {
    this.#httpsClient
      .get<UploadFileRes>(
        environment.apiHost + 'upload/image/',
        { params: { rnd: uniqueId() } },
      )
      .subscribe({
        next: data => {
          const file = new File([image], 'name1.png', { type: image.type });

          fetch(data['preSignedUrl'], {
            method: 'PUT',
            body: file,
            headers: {
              'Content-Type': file.type,
            },
          }).then(() => this.insertImage$.next({ link: data.fileUrl }));
        },
      });
  }
}
