import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  FormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  ButtonModule,
  DialogRef,
  FroalaModule,
  getEditorConfig,
  IconModule,
  ImageModel,
  SimpleInputModule,
  UploaderModule,
} from '@pinup-teams/common';
import { Store } from '@ngrx/store';
import { AuthSelectors } from '@pt/auth';
import { MAX_FILE_SIZE } from '@pt/constants';

@Component({
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    FroalaModule,
    ButtonModule,
    IconModule,
    UploaderModule,
    SimpleInputModule,
  ],
  selector: 'pt-blog-form',
  templateUrl: './blog-form.component.html',
  styleUrls: ['./blog-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlogFormComponent {
  readonly #formBuilder = inject(FormBuilder);
  readonly #dialogRef = inject(DialogRef);
  readonly #translateService = inject(TranslateService);
  readonly #store = inject(Store);

  readonly MAX_FILE_SIZE = MAX_FILE_SIZE;

  readonly userId = toSignal(this.#store.select(AuthSelectors.selectUserId));

  form = this.#formBuilder.group({
    title: ['', [Validators.required]],
    previewImage: <ImageModel>null,
    article: ['', [Validators.required]],
    authorId: this.userId(),
  });

  editorConfig = getEditorConfig({
    charCounterMax: 15000,
    placeholderText: this.#translateService.instant('blog.blogForm.control.article'),
  });

  save(): void {
    this.#dialogRef.close(this.form.value);
  }

  cancel(): void {
    this.#dialogRef.close();
  }
}
