import { inject } from '@angular/core';
import { HttpHandlerFn, HttpRequest, HttpStatusCode } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { catchError, throwError } from 'rxjs';
import {
  RouterActions, adaptApiError, AppError, DISABLE_ERROR_INTERCEPTOR,
} from '@pinup-teams/common';
import { RootState } from '@pt/store';

import { AuthActions } from '../store';

/**
 * App Errors interceptor
 * Modifies errors to AppError
 */
export function appErrorsInterceptor(request: HttpRequest<any>, next: HttpHandlerFn) {
  const store = inject(Store<RootState>);
  const codeField = 'code';
  const messageField = 'message';
  const dataField = 'data';

  if (ignoreErrorHandling(request)) {
    return next(request);
  }

  return next(request).pipe(
    adaptApiError({ codeField, messageField, dataField }),
    catchError((error: AppError) => {
      return throwError(() => beforeThrowing(store, error));
    }),
  );
}

function ignoreErrorHandling(request: HttpRequest<any>): boolean {
  return request.context.get(DISABLE_ERROR_INTERCEPTOR);
}

function beforeThrowing(store: Store<RootState>, error: AppError): AppError {
  if (error.originalError?.status === HttpStatusCode.Unauthorized) {
    store.dispatch(AuthActions.logout());
  }

  if (error.originalError?.status === HttpStatusCode.NotFound) {
    store.dispatch(RouterActions.navigateTo404());
  }

  if (error.code === 'user_is_not_registered') {
    store.dispatch(RouterActions.navigateByUrl({ url: '/user-not-found' }));
  }

  return error;
}
