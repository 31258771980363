<a
  class="pt-logo"
  routerLink="/"
>
  <svg [style.max-width]="logoWidth ? logoWidth + 'px' : '100%'">
    <use
      #idSvgUse
      [attr.xlink:href]="'#'+logoName"
      [attr.href]="'#'+logoName"
    />
  </svg>
</a>
