import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { Reaction, ReactionType } from '@pt/models';
import { environment } from '@pt/environment';
import {
  ButtonModule,
  DropdownModule,
  IconModule,
  PopoverDirective,
} from '@pinup-teams/common';
import { RootState, UiSelectors } from '@pt/store';
import { Store } from '@ngrx/store';
import { EmojiEvent, EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { LongPressDirective } from '@pt/directives';
import { TranslateModule } from '@ngx-translate/core';

import { ReactionsStore } from './store';
import { REACTIONS_API, ReactionsApiMockService, ReactionsApiService } from './api';
import { ReactionsDialog } from './components';

@Component({
  selector: 'pt-reactions',
  templateUrl: './reactions.component.html',
  styleUrls: ['./reactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    EmojiModule,
    PopoverDirective,
    DropdownModule,
    IconModule,
    PickerModule,
    ButtonModule,
    LongPressDirective,
    TranslateModule,
  ],
  providers: [
    {
      provide: REACTIONS_API,
      useClass: environment.useMocks ? ReactionsApiMockService : ReactionsApiService,
    },
    ReactionsStore,
    ReactionsDialog,
  ],
})
export class ReactionsComponent {
  #reactionsStore = inject(ReactionsStore);
  #store = inject(Store<RootState>);

  reactions = input.required<Reaction[], Reaction[]>({
    transform: value => {
      this.#reactionsStore.setReactions(value);
      return value;
    },
  });

  reactionType = input.required<ReactionType>();
  contentId = input.required<number>();

  reacted = output<string>();

  storeReactions = this.#reactionsStore.reactions;
  reactedUsers = this.#reactionsStore.reactedUsers;
  usersCount = this.#reactionsStore.usersCount;
  usersLimit = this.#reactionsStore.limit;
  isDarkTheme = toSignal(this.#store.select(UiSelectors.selectIsDarkTheme));

  onPointer(reaction: Reaction, isTouch: boolean): void {
    const updatedReaction = {
      ...reaction,
      reactionType: this.reactionType(),
      contentId: this.contentId(),
      emoji: !isTouch ? reaction.emoji : null,
    };

    this.#reactionsStore.clearUsers();
    this.#reactionsStore.getUsersReactions({ reaction: updatedReaction, isTouch });
  }

  react(reaction: Reaction, emoji?: string): void {
    const existingReaction = this.storeReactions().find(r => r.emoji === emoji);

    const payload = {
      emoji,
      reactionType: this.reactionType(),
      contentId: this.contentId(),
      ...reaction,
      ...existingReaction,
    };

    payload.currentUserReacted
      ? this.#reactionsStore.deleteReaction(payload)
      : this.#reactionsStore.addReaction(payload);

    this.reacted.emit(!payload.currentUserReacted ? payload.emoji : '');
  }

  selectEmoji({ emoji }: EmojiEvent): void {
    this.react(null, emoji.native);
  }
}
