<div class="pt-achievements-history-dialog__header">
  <h4
    class="pt-achievements-history-dialog__header-text"
    translate="achievementsHistoryDialog.header"
  >
  </h4>

  <button
    class="pt-achievements-history-dialog__close"
    type="button"
    puButton
    size="m"
    uiType="quaternary"
    [onlyIcon]="true"
    (click)="close()"
  >
    <pu-icon
      iconName="close"
      size="16"
    />
  </button>
</div>

<div class="pt-achievements-history-dialog__body">
  <pt-achievements-history
    class="pt-achievements-history-dialog__achievements"
    dialog
    [userId]="data.userId"
    [myTeam]="data.myTeam"
  />
</div>
