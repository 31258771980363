import { effect, inject, untracked } from '@angular/core';

import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import { switchMap } from 'rxjs/operators';
import { AppError } from '@pinup-teams/common';
import {
  setPending,
  setError,
  setSuccess,
  withRequestHandler,
  withRequestStatus,
  withFiltersUpdate,
} from '@pt/store';
import { CommentModel } from '@pt/models';

import { THREAD_API } from './api';
import { ThreadFilters, ThreadState } from './models';

const initialFilters: ThreadFilters = {
  page: 1,
  limit: 15,
};

const initialState: ThreadState = {
  filters: initialFilters,
  comment: null,
  replies: [],
};

export const ThreadStore = signalStore(
  withState(initialState),
  withRequestStatus(),
  withRequestHandler(),
  withFiltersUpdate<ThreadFilters>(),
  withMethods(store => ({
    setComment: (comment: CommentModel) => patchState(store, { comment }),

    updateReplies: (updatedReply: CommentModel) => {
      const updatedReplies = store.replies().map(reply => (reply.id === updatedReply.id
        ? updatedReply
        : reply));

      patchState(store, { replies: updatedReplies });
    },
  })),
  withMethods((
    store,
    blogApi = inject(THREAD_API),
  ) => ({
    getComment: rxMethod<ThreadFilters>(
      switchMap(params => {
        patchState(store, setPending());
        return blogApi.getComment(params, store.comment()).pipe(
          tapResponse({
            next: comment => patchState(
              store,
              {
                replies: params.page > 1
                  ? [...store.replies(), ...comment.replies]
                  : comment.replies,
                comment,
              },
              setSuccess(),
            ),
            error: (error: AppError) => patchState(store, setError(error)),
          }),
        );
      }),
    ),

    saveReply: rxMethod<CommentModel>(
      switchMap(reply => {
        const isEdit = !!reply.id;

        patchState(store, setPending());
        const request$ = isEdit
          ? blogApi.editReply(reply)
          : blogApi.addReply(reply);

        return request$.pipe(
          tapResponse({
            next: response => {
              patchState(store, setSuccess());
              isEdit
                ? store.updateReplies(response)
                : store.updateFilters(initialFilters);
            },
            error: (error: AppError) => patchState(store, setError(error)),
          }),
        );
      }),
    ),

    deleteReply: rxMethod<CommentModel>(
      switchMap(reply => {
        patchState(store, setPending());
        return blogApi.deleteReply(reply).pipe(
          tapResponse({
            next: () => {
              patchState(store, setSuccess());
              store.updateFilters(initialFilters);
            },
            error: (error: AppError) => patchState(store, setError(error)),
          }),
        );
      }),
    ),
  })),
  withHooks({
    onInit(store) {
      store.getComment(store.filters);

      effect(() => {
        if (store.error()) {
          untracked(() => store.handleError(store.error()));
        }
      });
    },
  }),
);
