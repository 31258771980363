import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild,
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { LetDirective, PopoverDirective } from '@pinup-teams/common';
import {
  ButtonModule, ExpansionPanelModule, IconModule, SpinnerDirective,
} from '@pinup-teams/common';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from '@pt/store';

import { SupportSelectors, SupportViewModel, SupportActions } from './store';
import { ContactDialog } from './components/contact/contact.dialog';

@Component({
  standalone: true,
  selector: 'pu-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    ButtonModule,
    PopoverDirective,
    IconModule,
    LetDirective,
    RouterLink,
    ExpansionPanelModule,
    TranslateModule,
    RouterLinkActive,
    SpinnerDirective,
    NgTemplateOutlet,
  ],
  providers: [ContactDialog],
})
export class SupportComponent implements OnInit {
  @ViewChild(RouterLinkActive) routerLinkActiveDirective: RouterLinkActive;
  @Input() type: 'popover' | 'list' = 'popover';

  @Output() clickItem = new EventEmitter<'faq' | 'contact_support'>();

  vm$: Observable<SupportViewModel> = this._store.select(SupportSelectors.selectViewModel);

  get isActive() {
    return this.routerLinkActiveDirective?.isActive;
  }

  constructor(private _store: Store<RootState>, private _contactDialog: ContactDialog) {
  }

  ngOnInit(): void {
    this._store.dispatch(SupportActions.init({ routeData: {} }));
  }

  openTipsDialog(type: string): void {
    this.clickItem.emit();
    this._store.dispatch(SupportActions.getTipsReq.action({ payload: { type } }));
  }

  openContactDialog(): void {
    this.clickItem.emit('contact_support');
    this._contactDialog.open();
  }
}
