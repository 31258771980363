import { Injectable } from '@angular/core';

import { delay, Observable, of } from 'rxjs';
import { CommentModel } from '@pt/models';

import { getCommentMock } from '../mocks';
import { ThreadFilters } from '../models';
import { ThreadApi } from '.';

@Injectable()
export class ThreadApiMockService implements ThreadApi {
  getComment(params: ThreadFilters, comment: CommentModel): Observable<CommentModel> {
    return getCommentMock(comment.id);
  }

  addReply() {
    return of(null).pipe(delay(500));
  }

  editReply() {
    return of(null).pipe(delay(500));
  }

  deleteReply() {
    return of(null).pipe(delay(500));
  }
}
