import { of, delay, Observable } from 'rxjs';
import { environment } from '@pt/environment';
import { CommentModel, Reaction, UserProfile } from '@pt/models';
import { fillArr } from '@pinup-teams/common';

import { GetCommentsRes } from '../models';

export const getRandomCount = (max = 10): number => Math.floor(Math.random() * max);

const getReplies = (count = 3): UserProfile[] => {
  return fillArr(count, i => ({
    id: i,
    fullName: `User ${i}`,
    imageUrl: `${environment.assetsHost}/assets/images/user-avatar-mock.jpg`,
    position: `Position ${i}`,
    email: '',
  }));
};

const emojis = ['👍', '👀', '✅', '🔥', '😂', '😢', '👏', '🎉'];

export const getReactions = (count = 4): Reaction[] => {
  return fillArr(count, i => ({
    emoji: emojis[i % emojis.length],
    count: getRandomCount(),
    currentUserReacted: Math.random() > 0.5,
    userReactionId: i,
  }));
};

export const getCommentsMock = (count = 12): Observable<GetCommentsRes> => {
  const comments: CommentModel[] = fillArr(count, i => {
    const replies = getReplies(getRandomCount(4));

    return {
      id: i + 1,
      article: i + 1,
      user: {
        id: i + 1,
        fullName: `User ${i + 1}`,
        imageUrl: `${environment.assetsHost}/assets/images/user-avatar-mock.jpg`,
        position: `Position ${i + 1}`,
        email: '',
      },
      content: `This is a comment content for comment ${i + 1}`,
      createdAt: new Date().toString(),
      updatedAt: Math.random() > 0.5 ? new Date().toString() : null,
      deletedAt: Math.random() > 0.5 ? new Date().toString() : null,
      parentCommentId: 0,
      repliesCount: replies.length,
      replies,
      reactions: getReactions(getRandomCount(8)),
    };
  });

  return of({ itemsCount: count, comments }).pipe(delay(500));
};
