<div 
  class="pt-reactions"
  #reactionsRef>
  @if (storeReactions()?.length) {
    <ul class="pt-reactions__list">
      @for (reaction of storeReactions(); track reaction.emoji) {
        <li
          class="pt-reactions__item"
          [class.active]="reaction.currentUserReacted"
          (click)="react(reaction)"
          [puPopover]="popover"
          [position]="['tc']"
          [offsetY]="4"
          scrollStrategy="close"
          ptLongPress
          (pointerEntered)="onPointer(reaction, $event)">
          {{ reaction.emoji }}
          <span class="reactions__count">{{ reaction.count }}</span>
        </li>

        <ng-template #popover>
          @if (reactedUsers()) {
            <ul class="pt-reactions__popover">
              @for (user of reactedUsers(); track user.id) {
                <li class="pt-reactions__popover-item">
                  {{ user.fullName + (!$last ? ',' : '') }} 
                </li>
              }

              @if (usersCount() > usersLimit()) {
                <li class="pt-reactions__popover-item">
                  {{ 'reactions.users.andOthers' | translate: { count: usersCount() - usersLimit() } }} 
                </li>
              }
            </ul>
          }
        </ng-template>
      }
    </ul>
  }

  <div  class="pt-reactions__emoji">
    <button
      type="button"
      puButton
      uiType="quaternary"
      #dropdownRef="puDropdownTrigger"
      [puDropdownTriggerFor]="emojiMart"
      [autoOverlayPosition]="true"
      [reference]="reactionsRef"
      [offsetY]="35">
      <pu-icon iconName="add-emoji" size="20" />
    </button>
      
    <pu-dropdown
      #emojiMart
      [closeOnClick]="false">
      <emoji-mart
        class="pt-reactions__emoji-mart"
        [darkMode]="isDarkTheme()"
        [isNative]="true"
        (emojiSelect)="selectEmoji($event)" />
    </pu-dropdown>
  </div>
</div>
