import { BaseType, select, Selection } from 'd3';

import { TooltipData } from './tooltip.abstraction';

export class Tooltip {
  #tooltipContainer: Selection<BaseType, string, BaseType, unknown>;

  constructor() {
    this.#tooltipContainer = select('body')
      .selectAll('div.chart-tooltip-container')
      .data(['tooltip'])
      .join(
        enter => enter
          .append('div')
          .attr('class', 'chart-tooltip-container'),
      );
  }

  show({ id, value, top, left }: TooltipData): void {
    this.#render([{ id, value, top, left }]);
  }

  hide(): void {
    this.#render([]);
  }

  move({ id, value, top, left }: TooltipData): void {
    this.#render([{ id, value, top, left }]);
  }

  #render(data: TooltipData[]): void {
    this.#tooltipContainer
      .selectAll<HTMLDivElement, TooltipData>('div.chart-tooltip')
      .data(data, d => d.id)
      .join(
        enter => enter
          .append('div')
          .attr('class', 'chart-tooltip')
          .style('opacity', 0)
          .style('top', d => `${d.top}px`)
          .style('left', d => `${d.left}px`)
          .html(d => d.value),
        update => update
          .style('top', d => `${d.top}px`)
          .style('left', d => `${d.left}px`)
          .html(d => d.value),
        exit => exit.remove(),
      )
      .style('opacity', 1);
  }
}
