import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { DonutChartModule, IconModule } from '@pinup-teams/common';
import { environment } from '@pt/environment';

import { getDonutData } from './helpers';

@Component({
  standalone: true,
  selector: 'pt-donut-total',
  templateUrl: './donut-total.component.html',
  styleUrls: ['./donut-total.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DonutChartModule, IconModule, TranslateModule],
})
export class DonutTotalComponent {
  readonly #coinIconName = `${environment.shortName}-coin`;
  sum1 = input.required<number>();
  color1 = input.required<string>();
  i18nLabel1 = input.required<string>();
  sum2 = input.required<number>();
  color2 = input.required<string>();
  i18nLabel2 = input.required<string>();
  i18nTotalTitle = input<string>('');
  totalIconName = input<string>(this.#coinIconName);
  donutData = computed(() => getDonutData(
    [this.sum1(), this.color1()],
    [this.sum2(), this.color2()],
  ));
}
