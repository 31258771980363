import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';
import { CommentModel } from '@pt/models';

import { ThreadFilters } from '../models';

export interface ThreadApi {
  getComment(params: ThreadFilters, comment: CommentModel): Observable<CommentModel>;
  addReply(params: CommentModel): Observable<CommentModel>;
  editReply(params: CommentModel): Observable<CommentModel>;
  deleteReply(params: CommentModel): Observable<void>;
}

export const THREAD_API = new InjectionToken<ThreadApi>('THREAD_API');
