<ng-container *puSpinner="isSaving">
  <h4 class="pu-accrual-user-form__header">
    {{ type + '.accrualUserForm.header' | translate }}

    <button
      class="pu-accrual-user-form__close"
      type="button"
      puButton
      uiType="quaternary"
      size="m"
      [onlyIcon]="true"
      (click)="close(true)"
    >
      <pu-icon iconName="close-2"/>
    </button>
  </h4>

  <div class="pu-accrual-user-form__body">
    <form
      class="pu-accrual-user-form__form"
      [formGroup]="form"
      (ngSubmit)="accrue()"
    >

      <pu-search-select
        formControlName="user"
        itemsField="users"
        [mockData]="usersMock"
        [api]="type === 'admin' ? 'user/users-search/' : 'user/users-search/share/'"
        [label]="type + '.accrualUserForm.control.recipient' | translate"
      >

        <ng-template
          #itemTpl
          let-item
        >
          <pu-user-profile-card [user]="item"/>
        </ng-template>
      </pu-search-select>

      <pu-simple-input
        class="pu-accrual-user-form__amount"
        formControlName="amount"
        [puNormalizeNumber]="{ min: 1, max: maxAmount }"
        [controlType]="controlTypes.Number"
        [iconTemplate]="coinIcon"
        [label]="type + '.accrualUserForm.control.amount.label' | translate"
        [isHintVisible]="true"
      />

      @if (type === 'site' && limits) {
        <div class="pu-accrual-user-form__amount-hint">
          @if (limits.approvalEnabled) {
            <div [innerHTML]="'site.accrualUserForm.control.amount.hintApproval' | translate: { perMonth: limits.perMonth }"></div>
          } @else {
            <div [innerHTML]="'site.accrualUserForm.control.amount.hintNoApproval' | translate: { perOne: limits.perOne, perMonth: limits.perMonth }"></div>
          }
        </div>
      }

      <ng-template #coinIcon>
        <pt-coin size="20"/>
      </ng-template>

      <pu-emoji
        class="pu-accrual-user-form__emoji"
        [control]="form.controls.comment"
        inputId="accrual_user_comment_id"
        [isDarkTheme]="isDarkTheme$ | async"
      />

      <pu-text-area
        controlId="accrual_user_comment_id"
        formControlName="comment"
        [maxLength]="1000"
        [rows]="4"
        [label]="(type + '.accrualUserForm.control.comment') | translate"
      />

      @if (type === 'site') {
        <span class="pu-accrual-user-form__policy-control">
          <pu-checkbox
            formControlName="isAgreed"
            [label]="(type + '.accrualUserForm.control.isAgreed') | translate"
          />

          <button
            type="button"
            puButton
            uiType="quaternary"
            (click)="openPolicy()"
          >
            {{ (type + '.accrualUserForm.ourPolicy') | translate }}
          </button>
        </span>
      }

      <div class="pu-accrual-user-form__actions">
        <button
          type="submit"
          puButton
          size="l"
          uiType="primary"
          [disabled]="form.invalid"
        >
          {{ type + '.accrualUserForm.button.share' | translate }}
        </button>

        @if (type === 'admin') {
          <button
            type="button"
            puButton
            size="l"
            uiType="secondary"
            (click)="close()"
          >
            {{ type + '.accrualUserForm.button.cancel' | translate }}
          </button>
        }
      </div>
    </form>
  </div>
</ng-container>
