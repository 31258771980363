import { inject, Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { MfeUrlSegments } from '@pt/mfe';
import { EMPTY, filter, map, Observable, of, switchMap, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { startAuthentication } from '@simplewebauthn/browser';
import { PublicKeyCredentialRequestOptionsJSON } from '@simplewebauthn/types';
import {
  adaptApiError,
  CookieService,
  createAsyncEffect,
  DialogService,
  DISABLE_ERROR_INTERCEPTOR,
  RouterActions,
  ToastService,
  ToastType,
  WINDOW,
} from '@pinup-teams/common';
import {
  AccessData,
  Enable2FAData,
  LocalStorageAnalyticsModel,
  PersonalInfo,
  SigninWithCodeData,
} from '@pt/models';
import { environment } from '@pt/environment';
import { NgxPermissionsService } from 'ngx-permissions';
import { Store } from '@ngrx/store';
import { RootState, UI_IS_DARK_THEME_KEY } from '@pt/store';
import { AnalyticsService } from '@pt/services';
import { TextFormaterHelper } from '@pt/helpers';
import * as LOCAL_STORAGE_KEYS from '@pt/constants';
import { PAGE_CATEGORY_STORAGE_KEY } from '@pt/constants';

import {
  AUTH_TOKEN_KEY,
  GOOGLE_JWT_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from '../auth.constants';
import { AuthService } from '../auth.service';
import { CodesComponent } from '../components/codes/codes.component';
import { TfaComponent } from '../components/tfa/tfa.component';
import { TfaEnableComponent } from '../components/tfa-enable/tfa-enable.component';
import { AuthActions } from './auth.actions';
import { AuthSelectors } from './auth.selectors';

@Injectable()
export class AuthEffects {
  private readonly _mfeUrlSegments = inject(MfeUrlSegments);
  private readonly _signinSegment = this._mfeUrlSegments.signin;

  /**
   * Effect is used to send analytics event when user is logging in
   */
  sendAftReq$ = createEffect(
    () => this._actions$.pipe(
      ofType(AuthActions.signIn.action),
      tap(({ payload: { token } }) => this._analytics?.sendLoginEvent(token)),
    ),
    { dispatch: false },
  );

  signIn$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.signIn.action),
    switchMap(({ payload: { token }, initiator }) => createAsyncEffect(
      this.signIn(token),
      AuthActions.signIn,
      initiator,
      true,
    )),
  ));

  onSignInSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.signIn.succeededAction),
    tap(({ payload: { token } }) => this._window.localStorage.setItem(GOOGLE_JWT_TOKEN_KEY, token)),
    switchMap(({ payload }) => {
      const step = { token: payload.token, step: '', accessData: null as AccessData | null };
      if (payload.is2faEnabled) {
        return this.verifyFingerprint(payload.token)
          .pipe(
            map(accessData => ({
              ...step, accessData, step: accessData ? 'authenticated' : 'tfa',
            })),
            catchError(() => of({ ...step, step: 'tfa' })),
          );
      } else {
        return of({ ...step, step: 'enableTfa' });
      }
    }),
    switchMap(({ token, step, accessData }) => {
      switch (step) {
        case 'enableTfa':
          return [AuthActions.enable2fa.action({ payload: { token } })];

        case 'tfa':
          this._dialog.open(TfaComponent, { token });
          return EMPTY;

        default:
          return [AuthActions.updateAccessData(accessData), AuthActions.navigateAfter()];
      }
    }),
  ));

  onSignInFailed$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.signIn.failedAction),
    switchMap(({ error }) => {
      if (error.code === 'user_is_not_found' || error.code === 'user_is_not_active') {
        return [RouterActions.navigateByUrl({ url: '/user-not-found' })];
      }

      this._toast.show({ type: ToastType.Error, message: error.message });

      return [];
    }),
  ));

  enable2fa$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.enable2fa.action),
    switchMap(({ payload: { token }, initiator }) => createAsyncEffect(
      this.enable2fa(token),
      AuthActions.enable2fa,
      initiator,
      true,
    )),
  ));

  onEnable2faSuccess$ = createEffect(
    () => this._actions$.pipe(
      ofType(AuthActions.enable2fa.succeededAction),
      tap(({ payload }) => {
        const token = this._window.localStorage.getItem(GOOGLE_JWT_TOKEN_KEY);
        this._dialog.open(TfaEnableComponent, { ...payload, token });
      }),
    ),
    { dispatch: false },
  );

  remove2fa$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.remove2fa.action),
    switchMap(() => createAsyncEffect(this.remove2fa(), AuthActions.remove2fa)),
  ));

  onRemove2faSuccess$ = createEffect(
    () => this._actions$.pipe(
      ofType(AuthActions.remove2fa.succeededAction),
      tap(() => this._auth.logout()),
    ),
    { dispatch: false },
  );

  verifyCode$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.verifyCode.action),
    switchMap(({ payload: { token, code }, initiator }) => createAsyncEffect(this.verifyCode(
      token,
      code,
    ), AuthActions.verifyCode, initiator, true)),
  ));

  onVerifyCodeSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.verifyCode.succeededAction),
    concatLatestFrom(() => [this._store.select(AuthSelectors.selectIs2faEnabled)]),
    switchMap(([{ payload }, is2faEnabled]) => {
      if (is2faEnabled) {
        return [AuthActions.updateAccessData(payload), AuthActions.navigateAfter()];
      }

      this._dialog.open(CodesComponent);

      return [AuthActions.updateAccessData(payload)];
    }),
  ));

  onVerifyCodeFailed$ = createEffect(
    () => this._actions$.pipe(
      ofType(AuthActions.verifyCode.failedAction),
      filter(({ error }) => error.code === 'throttled'),
      tap(({ error }) => {
        this._toast.show({ type: ToastType.Error, message: `error.${error.code}` });
      }),
    ),
    { dispatch: false },
  );

  resetCode$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.resetCode.action),
    switchMap(({ payload: { token, code }, initiator }) => createAsyncEffect(this.resetCode(
      token,
      code,
    ), AuthActions.resetCode, initiator, true)),
  ));

  onResetCodeSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.resetCode.succeededAction),
    switchMap(({ payload }) => [
      AuthActions.updateAccessData(payload),
      AuthActions.navigateAfter(),
    ]),
  ));

  onResetCodeFailed$ = createEffect(
    () => this._actions$.pipe(
      ofType(AuthActions.resetCode.failedAction),
      filter(({ error }) => error.code === 'throttled'),
      tap(({ error }) => {
        this._toast.show({ type: ToastType.Error, message: `error.${error.code}` });
      }),
    ),
    { dispatch: false },
  );

  navigateAfter$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.navigateAfter),
    map(() => {
      const params = new URLSearchParams(this._window.location.search);
      const encodedUrl = params.get('url');
      let url = encodedUrl ? decodeURIComponent(encodedUrl) : null;

      if (url && this._isExternalUrl(url)) {
        this._setRefreshTokenToCookie();

        return RouterActions.navigateByUrl({ url: `/redirect?url=${url}` });
      } else {
        url = url && !url.includes(this._signinSegment)
          ? (url[0] === '/' ? url : '/' + url)
          : '/';

        return RouterActions.navigateByUrl({ url });
      }
    }),
  ));

  updateAccessData$ = createEffect(
    () => this._actions$.pipe(
      ofType(AuthActions.updateAccessData),
      tap(({ access, refresh, permissions }) => {
        if (access) {
          this._window.localStorage.setItem(AUTH_TOKEN_KEY, access);
        }

        if (refresh) {
          this._window.localStorage.setItem(REFRESH_TOKEN_KEY, refresh);
        }

        this._setRefreshTokenToCookie();

        if (permissions?.length) {
          this._permissions.loadPermissions(permissions);
        }
      }),
    ),
    { dispatch: false },
  );

  logout$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.logout),
    switchMap(() => {
      const { pathname } = this._window.location;
      const path = pathname.includes(this._signinSegment) ? '' : pathname;

      this._permissions.flushPermissions();
      this._window.localStorage.removeItem(AUTH_TOKEN_KEY);
      this._window.localStorage.removeItem(REFRESH_TOKEN_KEY);
      this._window.localStorage.removeItem(GOOGLE_JWT_TOKEN_KEY);

      this._cookie.remove(REFRESH_TOKEN_KEY, environment.cookieDomain);

      return [
        AuthActions.setProfile({ profile: null }),
        RouterActions.navigate({
          route: [`/${this._signinSegment}`],
          extras: { queryParams: { url: !path || path === '/' ? null : encodeURIComponent(path) } },
        }),
      ];
    }),
  ));

  setProfile$ = createEffect(() => this._actions$.pipe(
    ofType(AuthActions.setProfile),
    switchMap(({ profile }) => this._setProfileDataToLocalStorage(profile)),
  ), { dispatch: false });

  constructor(
    private _actions$: Actions,
    private _auth: AuthService,
    private _cookie: CookieService,
    private _store: Store<RootState>,
    private _http: HttpClient,
    private _permissions: NgxPermissionsService,
    private _toast: ToastService,
    private _dialog: DialogService,
    @Optional() private _analytics: AnalyticsService,
    @Inject(WINDOW) private _window: Window,
  ) {
  }

  signIn(token: string) {
    return this._http
      .post<SigninWithCodeData>(
        environment.apiHost + 'auth/signin/',
        { token },
        { context: new HttpContext().set(DISABLE_ERROR_INTERCEPTOR, true) },
      )
      .pipe(adaptApiError());
  }

  enable2fa(token: string) {
    return this._http
      .post<Enable2FAData>(
        environment.apiHost + 'auth/enable/',
        { token },
        { context: new HttpContext().set(DISABLE_ERROR_INTERCEPTOR, true) },
      )
      .pipe(adaptApiError());
  }

  verifyFingerprint(token: string) {
    const baseUrl = environment.apiHost + 'auth/webauthn/';
    const context = new HttpContext().set(DISABLE_ERROR_INTERCEPTOR, true);

    return this._http.post<PublicKeyCredentialRequestOptionsJSON>(
      baseUrl + 'auth/options/', { token, credentials: [] }, { context },
    )
      .pipe(
        switchMap(options => startAuthentication(options)),
        switchMap(credential => (credential
          ? this._http.post<AccessData>(
            baseUrl + 'auth/verify/', { token, credential }, { context },
          )
          : of(null))),
      );
  }

  verifyCode(token: string, code: string) {
    return this._http
      .post<AccessData>(
        environment.apiHost + 'auth/verify/',
        { token, code },
        { context: new HttpContext().set(DISABLE_ERROR_INTERCEPTOR, true) },
      )
      .pipe(adaptApiError());
  }

  resetCode(token: string, code: string) {
    return this._http
      .post<AccessData>(
        environment.apiHost + 'auth/reset/',
        { token, code },
        { context: new HttpContext().set(DISABLE_ERROR_INTERCEPTOR, true) },
      )
      .pipe(adaptApiError());
  }

  remove2fa() {
    return this._http.delete<void>(environment.apiHost + 'auth/remove/');
  }

  private _isExternalUrl(url: string): boolean {
    return url.startsWith('http://') || url.startsWith('https://');
  }

  private _setRefreshTokenToCookie() {
    const refresh = this._window.localStorage.getItem(REFRESH_TOKEN_KEY);

    if (refresh) {
      const refreshData: any = this._auth.decodeJwt(refresh);
      const date = new Date(refreshData.exp * 1000).toUTCString();
      this._cookie.set(REFRESH_TOKEN_KEY, refresh, date, environment.cookieDomain);
    }
  }

  private _setProfileDataToLocalStorage(profile: PersonalInfo): Observable<void> {
    return of(null).pipe(
      tap(() => {
        if (!environment.googleAnalyticsId) return;

        const authToken = window.localStorage.getItem(AUTH_TOKEN_KEY);
        const authData = this._auth.decodeJwt(authToken);
        const authUserRoleGroups = authData?.['groups'] as string[];
        const user_role = authUserRoleGroups?.length ? this._getUserRole(authUserRoleGroups) : '';

        const preparePayload: LocalStorageAnalyticsModel = {
          user_id: profile.id,
          user_role,

          [LOCAL_STORAGE_KEYS.QUESTS_IN_PROGRESS_STORAGE_KEY]: 0, // ?
          [LOCAL_STORAGE_KEYS.QUESTS_IN_REVIEW_STORAGE_KEY]: 0, // ?
          [LOCAL_STORAGE_KEYS.QUESTS_COMPLETED_STORAGE_KEY]: 0, // ?
          [LOCAL_STORAGE_KEYS.QUESTS_REJECTED_STORAGE_KEY]: 0, // ?
          [LOCAL_STORAGE_KEYS.QUESTS_CANCELED_STORAGE_KEY]: 0, // ?
          [LOCAL_STORAGE_KEYS.SHOP_LOCATION_STORAGE_KEY]:
            TextFormaterHelper.transformToSnakeCase(profile.location),
          [LOCAL_STORAGE_KEYS.THEME_STORAGE_KEY]:
            this._window.localStorage.getItem(UI_IS_DARK_THEME_KEY) === 'true'
              ? 'dark'
              : 'light',
          [LOCAL_STORAGE_KEYS.PINCOINS_BALANCE_STORAGE_KEY]: profile.balance,

          office_location: TextFormaterHelper.transformToSnakeCase(profile.location),
          user_team: TextFormaterHelper.transformToSnakeCase(profile.team),
          user_position: TextFormaterHelper.transformToSnakeCase(profile.position),
          user_function: TextFormaterHelper.transformToSnakeCase(profile.function),
          user_gender: TextFormaterHelper.transformToSnakeCase(profile.gender),
          user_age: `${profile.age}`,
          user_status: TextFormaterHelper.transformToSnakeCase(profile.status),
          user_work_format: TextFormaterHelper.transformToSnakeCase(profile.workFormat),
          user_segment: TextFormaterHelper.transformToSnakeCase(profile.segment),
        };

        Object.entries(preparePayload).forEach(([key, value]) => {
          this._window.localStorage.setItem(key, value);
        });
      }),
    );
  }

  private _getUserRole(roles: string[]): string {
    const extractedRoles = roles.map(role => role.split('.').slice(-2, -1)[0]);

    return extractedRoles.find(role => role.includes('admin'))
      || extractedRoles.find(role => role !== 'user')
      || 'user';
  }
}
