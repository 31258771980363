import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { atLeastOneValidator, DialogRef, trackByIndex } from '@pinup-teams/common';
import { environment } from '@pt/environment';
import { UserProfile } from '@pt/models';
import { FormArray, FormBuilder } from '@ngneat/reactive-forms';
import { GroupResolverFormBuilder } from '@ngneat/reactive-forms/lib/form-builder';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface TeamMemberBonusForm {
  userId: number;
  amount: number;
  comment: string;
}

@UntilDestroy()
@Component({
  selector: 'pu-bonus-form',
  templateUrl: './bonus-form.component.html',
  styleUrls: ['./bonus-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BonusFormComponent implements OnInit {
  @Input() functionalUsers: UserProfile[];
  @Input() bonusTotal: number;

  readonly coinIconName = `${environment.shortName}-coin`;

  bonusesForm: FormArray<GroupResolverFormBuilder<TeamMemberBonusForm>>;
  bonusLeft: number;
  commentsState: Record<string, any> = {};

  trackByIndex = trackByIndex;

  constructor(private _fb: FormBuilder, private _dialogRef: DialogRef) {
  }

  ngOnInit() {
    this.bonusLeft = this.bonusTotal;
    this.bonusesForm = this._initBonusesForm();

    this.bonusesForm.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: values => {
        this.bonusLeft = this.bonusTotal - values.filter(value => value.amount > 0).reduce((
          acc,
          cur,
        ) => acc + cur.amount, 0);
      },
    });
  }

  accrueEqually() {
    const remainder = this.bonusTotal % this.bonusesForm.controls.length;
    this.bonusesForm.controls.forEach(group => group.patchValue({
      amount: (this.bonusTotal - remainder) / this.functionalUsers.length,
    }));
    this.bonusLeft = remainder;
  }

  reset() {
    this.bonusesForm.controls.forEach(group => group.patchValue({ amount: 0 }));
    this.bonusLeft = this.bonusTotal;
  }

  toggleComment(index: number) {
    this.commentsState[index] = !this.commentsState[index];
  }

  send(): void {
    this._dialogRef.close({
      type: 'bonus',
      payload: this.bonusesForm.value.filter(value => value.amount),
    });
  }

  close() {
    this._dialogRef.close();
  }

  private _initBonusesForm(): FormArray<GroupResolverFormBuilder<TeamMemberBonusForm>> {
    return new FormArray<GroupResolverFormBuilder<TeamMemberBonusForm>>(
      this.functionalUsers.map(({ id }) => this._fb.group<TeamMemberBonusForm>({
        userId: id,
        amount: 0,
        comment: '',
      })),
      atLeastOneValidator(control => (<FormArray<TeamMemberBonusForm>>control)
        .controls.some(control => control.value.amount > 0)),
    );
  }
}
