import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable, share } from 'rxjs';
import { environment } from '@pt/environment';
import { getQueryParams } from '@pinup-teams/common';
import { Reaction } from '@pt/models';

import { ReactionsApi } from '.';
import {
  ReactionReq,
  GetUsersReactionsRes,
} from '../models';

@Injectable()
export class ReactionsApiService implements ReactionsApi {
  readonly #http = inject(HttpClient);
  readonly #apiHost = environment.apiHost;
  readonly #apiPath = `${this.#apiHost}news/reactions/`;

  addReaction(params: ReactionReq): Observable<Reaction> {
    const { reactionType, contentId, emoji } = params;

    return this.#http.post<Reaction>(
      `${this.#apiPath}${reactionType}/${contentId}/`,
      { emoji },
    ).pipe(share());
  }

  deleteReaction(params: ReactionReq): Observable<void> {
    const { contentId, userReactionId, reactionType } = params;

    return this.#http.delete<void>(
      `${this.#apiPath}${reactionType}/${contentId}/${userReactionId}/`,
    ).pipe(share());
  }

  getUsersReactions(params: ReactionReq): Observable<GetUsersReactionsRes> {
    const { contentId, reactionType } = params;

    return this.#http.get<GetUsersReactionsRes>(
      `${this.#apiPath}${reactionType}/${contentId}/users/`,
      { params: getQueryParams(params, ['emoji', 'limit']) },
    );
  }
}
