import { inject, Injectable } from '@angular/core';

import { DialogRef, DialogService } from '@pinup-teams/common';

import { BlogFormComponent } from './blog-form.component';

@Injectable()
export class BlogFormDialog {
  readonly #dialog = inject(DialogService);

  open(): DialogRef {
    return this.#dialog.open(BlogFormComponent);
  }
}
