import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef, DictionaryItem, SpinnerService, trackById } from '@pinup-teams/common';
import { RootState } from '@pt/store';
import { Store } from '@ngrx/store';

import { TeamBonusesActions, TeamBonusesSelectors, TeamBonusesViewModel } from './store';

export type TeamBonusesType = 'bonus' | 'awards';

@Component({
  selector: 'pu-team-bonuses',
  templateUrl: './team-bonuses.component.html',
  styleUrls: ['./team-bonuses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamBonusesComponent implements OnInit {
  vm$: Observable<TeamBonusesViewModel> = this._store.select(TeamBonusesSelectors.selectViewModel);
  type: TeamBonusesType = 'bonus';

  tabs: DictionaryItem<TeamBonusesType>[] = [
    { id: 'bonus', name: this._translate.instant('personalInfo.teamBonuses.tab.bonusCoins') },
    { id: 'awards', name: this._translate.instant('personalInfo.teamBonuses.tab.awards') },
  ];

  trackById = trackById;

  constructor(
    private _dialogRef: DialogRef,
    private _translate: TranslateService,
    private _store: Store<RootState>,
    private _spinner: SpinnerService,
  ) {
    this._spinner.observe(this._store.select(TeamBonusesSelectors.selectIsLoading).pipe(
      takeUntilDestroyed(),
    ));
  }

  ngOnInit() {
    this._store.dispatch(TeamBonusesActions.init());
  }

  setType(type: TeamBonusesType) {
    this.type = type;
  }

  close(): void {
    this._dialogRef.close();
  }
}
