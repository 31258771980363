<div
  [class]="'pt-article-card pt-article-card_' + item().articleType"
  [ngClass]="{
    'pt-article-card_widget': widget(),
    'pt-article-card_read': item()?.read,
  }">
  <div class="pt-article-card__image-container">
    <img
      ptImageLoader
      class="pt-article-card__img"
      [src]="item().previewImage?.url || item().image.url"
    />

    <button
      class="pt-article-card__btn-toggle-saved"
      type="button"
      (click)="$event.stopPropagation(); toggleSaved.emit()"
    >
      @if (item().saved) {
        <pu-icon
          iconName="pinned"
          size="16"
        />
      } @else {
        <pu-icon
          iconName="pin"
          size="16"
        />
      }
    </button>
  </div>

  <div class="pt-article-card__panel">
    <div class="pt-article-card__published">
      {{ item().publishedAt | date:'dd/MM/yy HH:mm' }}
    </div>

    <div class="pt-article-card__badges">
      @if (item().articleType !== 'faq' && !item().read) {
        <div class="pt-article-card__new">
          {{ 'news.status.new' | translate }}
        </div>
      }

      @if(item().tag) {
        <div class="pt-article-card__tag">
          {{ item().tag.name }}
        </div>
      }

      @if(item().commentsCount) {
        <div class="pt-article-card__comments">
          <pu-icon
            iconName="comment"
            size="16"
          />
          {{ item().commentsCount }}
        </div>
      }
    </div>
  </div>

  <h3 class="pt-article-card__title">
    {{ item().title }}
  </h3>

  <article class="pt-article-card__preview">
    {{ item().previewText }}
  </article>

  <a
    class="pt-article-card__link"
    [routerLink]="[link(), item().id]"
    [queryParams]="{ categoryTagName: categoryTagName() }"
  >
  </a>
</div>
