import { inject, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@pt/environment';
import { auditTime, fromEvent, map, tap } from 'rxjs';
import {
  AppError, ToastService, ToastType, CommonMediaQueries, MediaScreenService,
} from '@pinup-teams/common';
import { ErrorsActions, RootActions } from '@pinup-teams/common';
import { THEME_STORAGE_KEY } from '@pt/constants';
import { GoogleAnalyticsService } from '@pt/services';
import { TextFormaterHelper } from '@pt/helpers';

import { UiActions, UI_IS_DARK_THEME_KEY } from './';

@Injectable()
export class UiEffects {
  readonly #googleAnalyticsService = inject(GoogleAnalyticsService);

  initTheme$ = createEffect(() => this._actions$.pipe(
    ofType(RootActions.appInitialized),
    map(() => {
      const isDarkTheme = window.localStorage.getItem(UI_IS_DARK_THEME_KEY);

      return UiActions.setTheme({ isDarkTheme: isDarkTheme === 'true' });
    }),
  ));

  setDarkTheme$ = createEffect(
    () => this._actions$.pipe(
      ofType(UiActions.setTheme),
      tap(({ isDarkTheme }) => {
        window.localStorage.setItem(UI_IS_DARK_THEME_KEY, isDarkTheme ? 'true' : 'false');
        window.localStorage.setItem(THEME_STORAGE_KEY, isDarkTheme ? 'dark' : 'light');
      }),
    ),
    {
      dispatch: false,
    },
  );

  scrollPosition$ = createEffect(() => {
    let currentScrollPosition = 0;
    const scrollThreshold = this._mediaScreenService.isMatched(CommonMediaQueries.LG) ? 0 : 70;

    return fromEvent(this._document, 'scroll').pipe(
      auditTime(200),
      map((event: Event) => {
        const target = event.target as Document;
        const scroll = target.scrollingElement.scrollTop;
        const isScrollBottom = target.scrollingElement.clientHeight + scroll
          >= target.scrollingElement.scrollHeight;
        const visibilityChangedPayload = { isNavbarHidden: false, isShadowVisible: true };
        visibilityChangedPayload.isNavbarHidden = scroll > scrollThreshold && scroll
          > currentScrollPosition && !isScrollBottom;
        visibilityChangedPayload.isShadowVisible = scroll > scrollThreshold;
        currentScrollPosition = scroll;

        return UiActions.elementsScrollVisibilityChanged(visibilityChangedPayload);
      }),
    );
  });

  showToast$ = createEffect(
    () => this._actions$.pipe(
      ofType(UiActions.showToast),
      tap(action => {
        this._toastService.show({
          type: action.toastType,
          message: action.message,
          title: action.title,
        });
      }),
    ),
    { dispatch: false },
  );

  showErrorToast$ = createEffect(() => this._actions$.pipe(
    ofType(ErrorsActions.unexpectedServerErrorHappened),
    tap(action => {
      if (!environment.production) {
        console.error('Async action error: ', action.error);
      }
      this.#googleAnalyticsService.pushCustomEvent(
        'error',
        'toast',
        { status: TextFormaterHelper.transformToSnakeCase(`error_${action.error.originalError.status}.${action.error.code}`) },
      );
    }),
    map(action => UiActions.showToast({
      toastType: ToastType.Error,
      message: this._detectMessage(action.error),
    })),
  ));

  constructor(
    private _actions$: Actions,
    private _toastService: ToastService,
    private _translate: TranslateService,
    @Inject(DOCUMENT) private _document: Document,
    private _mediaScreenService: MediaScreenService,
  ) {
  }

  private _detectMessage(error: AppError): string {
    if (error.code) {
      const code = `errors.${error.code}`;
      const message = this._translate.instant(code);

      if (message === code) {
        return error.message || code;
      } else {
        return message;
      }
    } else {
      return error.message || this._translate.instant('errors.unknown');
    }
  }
}
