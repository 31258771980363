// eslint-disable-next-line max-classes-per-file
import { NgZone } from '@angular/core';

import { EnvironmentModel, PersonalInfo } from '@pt/models';
import { Observable, Subject } from 'rxjs';

/**
 * Describes MFE url segments that need to be replaced with host (shell) segments
 */
export class MfeUrlSegments {
  shop = 'shop';
  cart = 'cart';
  'my-orders' = 'my-orders';
  'my-wishlist' = 'my-wishlist';
  'my-quests' = 'my-quests';
  quests = 'quests';
  faq = 'faq';
  article = 'article';
  transactions = 'transactions';
  signin = 'signin';
}

/**
 * Describes the configuration object provided by
 * a host (shell) application to a micro frontend
 */
export abstract class MfeConfig {
  abstract environment: Partial<EnvironmentModel>;
  abstract profile: Partial<PersonalInfo>;
  abstract darkTheme$: Observable<boolean>;
  abstract hostUrlUpdateSubject: Subject<string>;
  abstract mfeUrlUpdateSubject: Subject<string>;
  abstract cartQuantitySubject: Subject<number>;
  abstract baseHref: string;
  abstract urlSegments: MfeUrlSegments;
  abstract isPaidInternationalShipping?: boolean;
  abstract apiAuthSlug?: string;
  abstract ngZone?: NgZone;
}
