import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@pinup-teams/common';

import { SmallLogoComponent } from '../small-logo/small-logo.component';

@Component({
  standalone: true,
  selector: 'pt-user-not-found',
  templateUrl: './user-not-found.component.html',
  styleUrls: ['./user-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconModule, TranslateModule, SmallLogoComponent],
})
export class UserNotFoundComponent {
}
