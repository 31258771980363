import { inject, Injectable } from '@angular/core';

import { DialogRef, DialogService } from '@pinup-teams/common';

import { DialogData, OnboardingTaskDetailsComponent } from './onboarding-task-details.component';

@Injectable()
export class OnboardingTaskDetailsDialog {
  readonly #dialog = inject(DialogService);

  open(taskData: DialogData): DialogRef {
    return this.#dialog.open(OnboardingTaskDetailsComponent, taskData);
  }
}
