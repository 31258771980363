<div class="pt-user-not-found">
  <div class="pt-user-not-found__icon">
    <pt-small-logo/>
  </div>

  <h2
    class="pt-user-not-found__header"
    translate="userNotFound.header"
  >
  </h2>

  <p
    class="pt-user-not-found__description"
    translate="userNotFound.description1"
  >
  </p>

  <p
    class="pt-user-not-found__description"
    translate="userNotFound.description2"
  >
  </p>

  <p
    class="pt-user-not-found__description"
    translate="userNotFound.description3"
  >
  </p>
</div>
