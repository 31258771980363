import { Directive, HostListener, inject, input, OnDestroy } from '@angular/core';

import { FlipBookDialog } from '@pt/components';
import { DialogRef } from '@pinup-teams/common';

@Directive({
  selector: '[ptFlipBook]',
  standalone: true,
})
export class FlipBookDirective implements OnDestroy {
  readonly #flipBookDialog = inject(FlipBookDialog);
  #dialogRef?: DialogRef;

  pdfUrl = input.required<string>();
  options? = input<{ [key: string]: unknown }>();

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.preventDefault();
    this.#dialogRef = this.#flipBookDialog.open({ source: this.pdfUrl(), options: this.options() });
  }

  ngOnDestroy(): void {
    this.#dialogRef?.close();
  }
}
