import {
  ChangeDetectionStrategy, Component, computed, effect, ElementRef, inject, input, Renderer2,
  viewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { ResizeObserverDirective } from '@pinup-teams/common';
import { debounceTime, merge, ReplaySubject, take } from 'rxjs';

import { VerticalBarChart } from './vertical-bar-chart';
import { BarChartConfig, BarChartData, BarChartLegendItem } from './bar-chart.abstraction';
import { HorizontalBarChart } from './horizontal-bar-chart';

@Component({
  selector: 'pt-bar-chart',
  standalone: true,
  imports: [
    ResizeObserverDirective,
  ],
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarChartComponent {
  #renderer = inject(Renderer2);

  chartElementRef = viewChild<ElementRef>('chartWrapper');
  #chartElement = computed(() => this.chartElementRef()!.nativeElement);
  #chart = computed(
    () => (this.orientation() === 'vertical' ? new VerticalBarChart() : new HorizontalBarChart()),
  );

  #renderChartEffect = effect(() => {
    this.#renderer.appendChild(this.#chartElement(), this.#chart().svgElement);
  });

  #svgResizedSubject = new ReplaySubject<{ width: number; height: number }>(Infinity);

  _svgSize = toSignal(
    merge(
      this.#svgResizedSubject.pipe(take(1)),
      this.#svgResizedSubject.pipe(debounceTime(500)),
    ),
  );
  data = input.required<BarChartData>();
  orientation = input<'horizontal' | 'vertical'>('vertical');
  config = input<Partial<BarChartConfig>>();
  legend = computed<BarChartLegendItem[]>(() => this.data().series.map(item => ({
    name: item.name,
    color: this.#chart().colorScale(item.name),
  })));

  #dataChangedEffect = effect(() => {
    this.#chart().handleDataChange(this.data());
  });

  #configChangedEffect = effect(() => {
    this.#chart().handleConfigChange(this.config() || {});
  });

  #svgResizedEffect = effect(() => {
    if (this._svgSize()) {
      this.#chart().handleChartResize(this._svgSize()!.width, this._svgSize()!.height);
    }
  });

  svgResized(event: ResizeObserverEntry): void {
    this.#svgResizedSubject.next({
      width: event.contentRect.width,
      height: event.contentRect.height,
    });
  }
}
