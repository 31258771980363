import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Store } from '@ngrx/store';
import { BalanceType } from '@pt/models';
import { RootState } from '@pt/store';
import { combineLatest, Observable } from 'rxjs';
import { CommonMediaQueries, MediaScreenService, SpinnerService } from '@pinup-teams/common';
import SwiperCore, { Pagination, SwiperOptions } from 'swiper';
import { AccrualUserFormDialog, TransactionsDialog, TeamBonusesActions } from '@pt/components';

import { BalanceSelectors, BalanceViewModel } from './store';

SwiperCore.use([Pagination]);

@Component({
  selector: 'pu-balances',
  templateUrl: './balances.component.html',
  styleUrls: ['./balances.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BalancesComponent {
  @Input() isCard = true;

  vm$: Observable<BalanceViewModel> = this._store.select(BalanceSelectors.selectViewModel);
  breakpoints$ = combineLatest({
    isTablet: this._mediaScreenService.mediaMatcher(CommonMediaQueries.MD),
    isDesktop: this._mediaScreenService.mediaMatcher(CommonMediaQueries.LG),
  });

  sliderConfig: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 4,
    grabCursor: true,
    navigation: {
      prevEl: '.pu-balances__slider-controls_prev',
      nextEl: '.pu-balances__slider-controls_next',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  constructor(
    private _accrualUserFormDialog: AccrualUserFormDialog,
    private _store: Store<RootState>,
    private _mediaScreenService: MediaScreenService,
    private _spinner: SpinnerService,
    private _transactionsDialog: TransactionsDialog,
  ) {
    this._spinner.observe(this._store.select(BalanceSelectors.selectIsLoading).pipe(
      takeUntilDestroyed(),
    ));
  }

  share(): void {
    this._accrualUserFormDialog.open('site');
  }

  openTeamBonuses(): void {
    this._store.dispatch(TeamBonusesActions.openTeamBonusesDialog());
  }

  openHistory(type: BalanceType): void {
    this._transactionsDialog
      .open(<any>{}, false, type === 'functional')
      .afterClosed()
      .subscribe();
  }
}
