import {
  Component,
  ElementRef,
  OnDestroy,
  inject,
  DestroyRef,
  ChangeDetectionStrategy,
  model,
  signal,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { environment } from '@pt/environment';
import { ButtonModule, DIALOG_DATA, DialogRef, IconModule, WINDOW } from '@pinup-teams/common';
import { AssetLoaderService } from '@pt/services';
import { forkJoin, Observable, switchMap } from 'rxjs';

import { FlipBookData } from './flip-book.model';

@Component({
  selector: 'pt-flip-book',
  imports: [ButtonModule, IconModule],
  templateUrl: './flip-book.component.html',
  styleUrl: './flip-book.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlipBookComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly #el = inject(ElementRef);
  readonly #scriptLoader = inject(AssetLoaderService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #dialogRef = inject(DialogRef, { optional: true });
  readonly #window: any = inject(WINDOW);

  dialogData = inject<FlipBookData>(DIALOG_DATA, { optional: true });

  source = model<string | string[] | null>();
  options = model<{ [key: string]: unknown }>({});

  isDialog = signal(false);

  readonly #bookUrl = `${environment.assetsHost}/assets/flip-book/`;
  #flipBookElement: HTMLElement | null = null;

  readonly #stylesheets = [
    `${this.#bookUrl}css/dflip.css`,
    `${this.#bookUrl}css/themify-icons.min.css`,
  ];
  readonly #scripts = [
    `${this.#bookUrl}js/libs/jquery.min.js`,
    `${this.#bookUrl}js/libs/pdfjs/stable/pdf.min.js`,
    `${this.#bookUrl}js/libs/pdfjs/stable/pdf.worker.min.js`,
  ];
  readonly #dflipScript = `${this.#bookUrl}js/dflip.js`;

  constructor() {
    if (!this.dialogData?.source) return;
    this.isDialog.set(true);
    this.source.set(this.dialogData.source);
    this.options.set(this.dialogData.options);
  }

  ngAfterViewInit(): void {
    this.#applyFlipBookOptions();
  }

  ngOnInit(): void {
    this.#loadScripts().pipe(takeUntilDestroyed(this.#destroyRef)).subscribe();
  }

  ngOnDestroy(): void {
    this.#cleanup();
  }

  close(): void {
    this.#dialogRef?.close();
  }

  /**
   * Loads the required scripts and stylesheets for the flip book.
   * @returns {Observable<void>} An observable that completes when all scripts
   * and stylesheets are loaded.
   */
  #loadScripts(): Observable<void> {
    const loadStylesheets = this.#stylesheets
      .map(src => this.#scriptLoader.loadCSS(src));
    const loadScripts = this.#scripts.map(src => this.#scriptLoader.loadJS(src));

    return forkJoin([...loadStylesheets, ...loadScripts]).pipe(
      switchMap(() => this.#scriptLoader.loadJS(this.#dflipScript)),
    );
  }

  /**
   * Applies the flip book options to the element.
   */
  #applyFlipBookOptions(): void {
    this.#flipBookElement = this.#el.nativeElement.querySelector('#pt_flipbook');
    if (this.#flipBookElement?.id) {
      const optionVariableName = `option_${this.#flipBookElement.id}`;
      this.#window[optionVariableName] = {
        source: this.source(),
        webgl: true,
        hashNavigationEnabled: false,
        hideControls: 'share, print',
        ...this.options(),
      };
    }

    if (this.#window?.DFLIP?.parseBooks) this.#window.DFLIP.parseBooks();
  }

  /**
   * Cleans up the loaded scripts and stylesheets.
   */
  #cleanup(): void {
    if (!this.#flipBookElement) return;
    this.#flipBookElement = null;
    if (this.#window?.DFLIP?.closeBook) this.#window.DFLIP.closeBook();
  }
}
