import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { OnboardingType } from '@pt/models';

@Component({
  selector: 'pt-onboarding-stage-image',
  standalone: true,
  templateUrl: './onboarding-stage-image.component.html',
  styleUrl: './onboarding-stage-image.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingStageImageComponent {
  onboardingType = input<OnboardingType>('corporate');
  readonly #stageFillColors: Record<OnboardingType, string[]> = {
    corporate: ['#00A988', '#00876C'],
    professional: ['#2C99FF', '#1885EB'],
    mentoring: ['#AD00FF', '#9900EB'],
  };

  get filledColors(): string[] {
    return this.#stageFillColors[this.onboardingType()] || this.#stageFillColors['corporate'];
  }
}
