import { createAction, props } from '@ngrx/store';
import { ToastType } from '@pinup-teams/common';

const featureName = 'UI';

const setTheme = createAction(`[${featureName}]: Set theme`, props<{ isDarkTheme: boolean }>());
const showToast = createAction(
  `[${featureName}]: Show toast`,
  props<{ toastType: ToastType; message: string; title?: string }>(),
);
const elementsScrollVisibilityChanged = createAction(
  `[${featureName}]: Scroll visibility changed`,
  props<{ isNavbarHidden: boolean; isShadowVisible: boolean }>(),
);
const setHeaderHeight = createAction(
  `[${featureName}]: Set header height`, props<{ headerHeight: number }>(),
);

export const UiActions = {
  setTheme,
  showToast,
  elementsScrollVisibilityChanged,
  setHeaderHeight,
};
