import {
  ChangeDetectionStrategy,
  Component,
  inject,
} from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonModule,
  DIALOG_DATA,
  DialogRef,
  IconModule,
} from '@pinup-teams/common';
import { UserProfileCardModule } from '@pt/components';
import { ReactionDialog } from '@pt/models';
import { SwiperOptions } from 'swiper';
import { SwiperModule } from 'swiper/angular';

interface ReactionsDialogData {
  reactions: ReactionDialog[];
  limit: number;
}

@Component({
  selector: 'pt-reactions-dialog',
  templateUrl: './reactions-dialog.component.html',
  styleUrls: ['./reactions-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    ButtonModule,
    IconModule,
    UserProfileCardModule,
    SwiperModule,
  ],
})
export class ReactionsDialogComponent {
  readonly #data = inject<ReactionsDialogData>(DIALOG_DATA);
  readonly #dialogRef = inject(DialogRef);

  reactions = this.#data.reactions;
  usersLimit = this.#data.limit;
  selectedReaction: ReactionDialog;

  sliderConfig: SwiperOptions = {
    slidesPerView: 'auto',
    grabCursor: true,
    spaceBetween: 16,
    navigation: {
      prevEl: '.pt-reactions-dialog__swiper-control-prev',
      nextEl: '.pt-reactions-dialog__swiper-control-next',
    },
  };

  setReaction(reaction: ReactionDialog | null) {
    this.selectedReaction = reaction;
  }

  onClose() {
    this.#dialogRef.close();
  }
}
