import { isNull } from "@tsparticles/engine";
import { MotionReduce } from "./MotionReduce.js";
export class Motion {
  constructor() {
    this.disable = false;
    this.reduce = new MotionReduce();
  }
  load(data) {
    if (isNull(data)) {
      return;
    }
    if (data.disable !== undefined) {
      this.disable = data.disable;
    }
    this.reduce.load(data.reduce);
  }
}