<div class="add-new-modal__header">
  <h4 class="add-new-modal__header-text">
    {{ title | translate }}
  </h4>

  <button
    class="add-new-modal__close"
    type="button"
    puButton
    size="m"
    uiType="quaternary"
    [onlyIcon]="true"
    (click)="close()"
  >
    <pu-icon
      iconName="close"
      size="16"
    />
  </button>
</div>
<div class="add-new-modal__content">
  <form [formGroup]="form">
    <div class="add-new-modal__controls-group">
      <pu-uploader
        formControlName="coverImage"
        apiS3="upload/image/"
        accept=".jpg,.jpeg,.png"
        labelKey="admin.library.materials.add-material.image"
        placeholderKey="admin.library.materials.add-material.browseImage"
        hiddenBrowse
        hiddenPlaceholder
        [maxFileSize]="MAX_FILE_SIZE"
      />
      <pu-uploader
        id="extraImages"
        formControlName="extraImages"
        apiS3="upload/image/"
        accept=".jpg,.jpeg,.png"
        labelKey="admin.library.materials.add-material.extraImages"
        placeholderKey="admin.library.materials.add-material.browseImages"
        multiple
        hiddenPlaceholder
        [maxFileSize]="MAX_FILE_SIZE"
      />
    </div>

    <pu-simple-input
      formControlName="title"
      [label]="'admin.library.materials.add-material.modal.form.title.label' | translate"
      [placeholder]="'admin.library.materials.add-material.modal.form.title.label' | translate"
    />

    <div class="inputs-row">
      <pu-multi-select
        class="inputs-row__categories"
        formControlName="categoriesIds"
        [label]="'admin.library.materials.add-material.modal.form.category.label' | translate"
        [items]="categorySelect"
      />

      <div class="inputs-row__group">
        <pu-multi-select
          class="inputs-row__group-item"
          formControlName="libraryTypes"
          [label]="'admin.library.materials.add-material.modal.form.type.label' | translate"
          [items]="typeSelect"
          [tooltip]="'admin.library.materials.add-material.modal.form.type.info' | translate"
        />

        <pu-select
          class="inputs-row__group-item"
          formControlName="language"
          [label]="'admin.library.materials.add-material.modal.form.language.label' | translate"
          [items]="languageSelect"
        />
      </div>
    </div>
    <div
      class="description"
      formControlName="description"
      [froalaEditor]="editorConfig"
    >
    </div>
    <pu-simple-input
      formControlName="videoUrl"
      [placeholder]="'admin.library.materials.add-material.modal.form.videoLink.label' | translate"
      [label]="'admin.library.materials.add-material.modal.form.videoLink.label' | translate"
    />

    <div class="files-row">
      <pu-uploader
        #uploader
        formControlName="files"
        apiS3="upload/image/"
        accept=".pdf,.xlsx,.ppt,.epub,.pptx"
        placeholderKey="library.add-new-material.files.uploadPdfFile"
        small
        multiple
        hiddenBrowse
        hiddenPlaceholder
      >
        <ng-template #filesTpl>
          @if (uploader.files.length) {
            @for (file of uploader.files; track file) {
              <div
                class="pu-uploader-file"
                [puPopover]="popover"
                [position]="['bl', 'br']"
                (click)="uploader.removeFile($event, 0)"
              >
                <pu-icon
                  iconName="info"
                  size="20"
                />
                <span class="pu-uploader-file__name">
                  {{ file.name }}
                </span>
                <div class="pu-uploader-file__remove">
                  <pu-icon
                    iconName="close"
                    size="16"
                  />
                </div>
              </div>
              <ng-template #popover>
                <div class="file-name-popover">{{ file.name }}</div>
              </ng-template>
            }
          }
        </ng-template>
      </pu-uploader>
    </div>

    <pu-checkbox
      [formControl]="isFlippingBook"
      (puControlValueChanges)="isFlippingBookChange($event)"
      [label]="'admin.library.materials.add-material.modal.form.addFlippingBook' | translate"
    />

    <p class="hint">
      {{ 'admin.library.materials.add-material.modal.form.addFlippingBook.hint' | translate }}
    </p>
    <ng-container formGroupName="flippingBook">
      @if (isFlippingBook.value) {
        <div class="inputs-row">
          <pu-radio-option
            formControlName="formatType"
            controlId="flippingType"
            [option]="flippingHorizontal"
          />

          <pu-radio-option
            formControlName="formatType"
            controlId="flippingType"
            [option]="flippingVertical"
          />
        </div>
      }

      @if (form.controls.flippingBook.controls.formatType.value) {
        <div class="flipping-data">
          <pu-simple-input
            formControlName="buttonText"
            [maxlength]="255"
            [label]="'admin.library.materials.add-material.modal.form.flippingData.label' | translate"
            [placeholder]="'admin.library.materials.add-material.modal.form.flippingData.placeholder' | translate"
          />
        </div>

        <pu-uploader
          class="flipping-data-uploader"
          #uploader
          formControlName="file"
          apiS3="upload/image/"
          accept=".pdf"
          placeholderKey="admin.newsForm.files.uploadPdfFile"
          small
          hiddenBrowse
          hiddenPlaceholder
        >
          <ng-template #filesTpl>
            @if (uploader.files.length) {
              @for (file of uploader.files; track file) {
                <div
                  class="pu-uploader-file"
                  [puPopover]="popover"
                  [position]="['bl', 'br']"
                  (click)="uploader.removeFile($event, 0)"
                >

                  <pu-icon
                    iconName="info"
                    size="20"
                  />

                  <span class="pu-uploader-file__name">
                    {{ file.name }}
                  </span>

                  <div class="pu-uploader-file__remove">
                    <pu-icon
                      iconName="close"
                      size="16"
                    />
                  </div>

                  <ng-template #popover>
                    <div class="file-name-popover">{{ file.name }}</div>
                  </ng-template>
                </div>
              }
            }
          </ng-template>
        </pu-uploader>
      }
    </ng-container>
  </form>

  <div class="add-new-modal__actions">
    <button
      type="button"
      puButton
      size="l"
      uiType="primary"
      [disabled]="form.invalid"
      [translate]="btnTitle"
      (click)="addMaterial()"
    >
    </button>
    <button
      type="button"
      puButton
      size="l"
      uiType="secondary"
      translate="admin.library.materials.add-material.modal.btn.cancel"
      (click)="close()"
    >
    </button>
  </div>
</div>
