import { inject, Injectable } from '@angular/core';

import { DialogRef, DialogService } from '@pinup-teams/common';

import { FlipBookComponent } from './flip-book.component';
import { FlipBookData } from './flip-book.model';

@Injectable()
export class FlipBookDialog {
  readonly #dialog = inject(DialogService);

  open(flipBookData: FlipBookData): DialogRef {
    return this.#dialog.open(FlipBookComponent, flipBookData);
  }
}
