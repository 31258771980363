import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

import { DialogService, INavigator, NAVIGATOR, UaParserService, WINDOW } from '@pinup-teams/common';

import { PwaSetupComponent } from './pwa-setup.component';
import { IS_PWA_INSTALLED } from './pwa-setup.model';

@Injectable({ providedIn: 'root' })
export class PwaSetupDialog {
  readonly #dialog = inject(DialogService);
  readonly #uaParser = inject(UaParserService);
  readonly #document = inject(DOCUMENT);
  readonly #window = inject(WINDOW);
  readonly #navigator = inject(NAVIGATOR);

  #isPwaInstalled: boolean = JSON.parse(localStorage.getItem(IS_PWA_INSTALLED) ?? 'false');

  init(): void {
    if (this.#shouldShowPwaSetupDialog()) {
      this.#dialog.open(PwaSetupComponent, null, {
        panelClass: 'pu-overlay-panel_pwa-setup',
      });
    }
  }

  #shouldShowPwaSetupDialog(): boolean {
    return (!this.#isStandaloneMode() && !this.#isPublicPage() && this.#uaParser.isMobile
      && !this.#isPwaInstalled);
  }

  #isStandaloneMode(): boolean {
    return (
      this.#window.matchMedia('(display-mode: standalone)').matches
      || (this.#navigator as INavigator).standalone
      || this.#document.referrer.includes('android-app://')
    );
  }

  #isPublicPage(): boolean {
    return this.#document.location.href.includes('public');
  }
}
