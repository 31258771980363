<div class="pt-onboarding-stage__header">
  <div class="pt-onboarding-stage__name">
    {{ stage().name }}
  </div>

  @if (stage().reward) {
    <div class="pt-onboarding-stage__reward">
      {{ (stage().status === 'completed'
      ? 'onboardingStage.reward.earned'
      : 'onboardingStage.reward.prize') | translate }}
      :
      {{ stage().reward }}
      <pt-coin/>
    </div>
  }
</div>
<div class="pt-onboarding-stage__body">
  <div
    class="pt-onboarding-stage__type"
    [ngClass]="stage().onboardingType"
  >
    <div class="pt-onboarding-stage__type-text">
      {{ 'onboardingStage.onboardingType.' + stage().onboardingType | translate }}
    </div>
  </div>

  <div class="pt-onboarding-stage__info">
    <pt-onboarding-stage-image
      [onboardingType]="stage().onboardingType"
      class="pt-onboarding-stage__image"
    >
      <span class="pt-onboarding-stage__image-number">{{ stageOrder() }}</span>

      <span
        class="pt-onboarding-stage__image-label"
        slot="label"
      >
        {{ 'onboardingStageImage.label' | translate }}
      </span>
    </pt-onboarding-stage-image>

    <div class="pt-onboarding-stage__subtitle">
      {{ stage().subtitle }}
    </div>

    @if (stage().status === 'inProgress') {
      <div
        class="pt-onboarding-stage__tasks-number"
        translate="onboardingStage.tasksNumber"
        [translateParams]="{required: stage().requiredTasks.length,
      optional: stage().optionalTasks.length}"
      >
      </div>

      @if (stage().reward) {
        <div class="pt-onboarding-stage__hint">
          {{ 'onboardingStage.timerHint' | translate }}
        </div>
      }

      @if (stage().hasOwnProperty('startedAt') && stage().hasOwnProperty('expiredAt')) {
        <pu-timer
          [durationData]="$any(stage())"
          withLeadingZero
        />
      }
    }
  </div>

  @if (stage().status === 'completed') {
    <div
      class="pt-onboarding-stage__completed-label"
      [ngClass]="stage().onboardingType"
    >
      <div class="pt-onboarding-stage__completed-label-text">
        {{ 'onboardingStage.completed' | translate }}!
        <div>{{ 'onboardingStage.newAchievement' | translate }}</div>
      </div>

      <pt-achievement
        class="pt-onboarding-stage__completed-label-achievement"
        [achievement]="stage().achievement"
      />
    </div>
  }

  @if (!stage().status) {
    <div class="pt-onboarding-stage__blocked">
      <pu-icon
        iconName="lock"
        size="128"
      />
      <div class="pt-onboarding-stage__hint">
        {{ 'onboardingStage.blockedHint' | translate }}
      </div>
    </div>
  } @else {
    <div class="pt-onboarding-stage__tasks">
      <div class="pt-onboarding-stage__tasks-label">
        {{ 'onboardingStage.requiredTasks' | translate }}
        @if (stage().hasOwnProperty('totalRequiredCompleted')) {
          {{ $any(stage()).totalRequiredCompleted }}/{{ stage().requiredTasks.length }}
        }
      </div>

      @for (task of stage().requiredTasks; track task.id) {
        <ng-container *ngTemplateOutlet="taskTmpl; context: { $implicit: task }"/>
      }

      @if (stage().optionalTasks.length) {
        <div class="pt-onboarding-stage__tasks-label">
          {{ 'onboardingStage.optionalTasks' | translate }}
          @if (stage().hasOwnProperty('totalOptionalCompleted')) {
            {{ $any(stage()).totalOptionalCompleted }}/{{ stage().optionalTasks.length }}
          }
        </div>

        @for (task of stage().optionalTasks; track task.id) {
          <ng-container *ngTemplateOutlet="taskTmpl; context: { $implicit: task }"/>
        }
      }
    </div>
  }
</div>

<ng-template
  #taskTmpl
  let-task
>
  <div
    class="pt-onboarding-stage__task"
    [class.pt-onboarding-stage__task_completed]="task.completed"
    (click)="openTask.emit({ task, userOnboardingId: $any(stage()).userOnboardingId })"
  >
    {{ task.name }}

    @if (task.completed) {
      <pu-icon
        class="pt-onboarding-stage__task-icon"
        iconName="pu-checkbox-yes"
        size="16"
      />
    }
  </div>
</ng-template>
