import { BarChartConfig, BarChartData } from './bar-chart.abstraction';

export const BAR_CHART_DEFAULT_DATA: BarChartData = {
  categories: [],
  series: [],
};

export const BAR_CHART_DEFAULT_CONFIG: BarChartConfig = {
  width: 500,
  height: 500,
  marginTop: 50,
  marginRight: 50,
  marginBottom: 50,
  marginLeft: 50,
  tooltipPadding: 5,
};

/**
 * Magic number which is used to calculate the amount of ticks
 * on the Y axis, so the labels don't overlap.
 */
export const BAR_CHART_TICKS_DISTANCE = 50;
