import { OrderStatusType } from '@pt/models';

export const orderStatusTypes: OrderStatusType[] = [
  'inReview',
  'rejected',
  'new',
  'confirmed',
  'shipping',
  'delivered',
  'canceled',
  'completed',
];
