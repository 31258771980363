@if (isEditing()) {
  <pt-comment-form
    class="pt-comment__comment-form"
    [content]="comment().content"
    (formSubmitted)="save.emit($event)"
  >
    <button
      puButton
      type="button"
      size="m"
      uiType="primary"
      (click)="cancel.emit()"
    >
      {{ 'blog.post.commentForm.btn.cancel' | translate }}
    </button>
  </pt-comment-form>
} @else {
  @if (isDeleted()) { 
    <p class="pt-comment__deleted">
      <pu-icon iconName="trash" size="20" />
  
      {{ 'blog.post.comment.deleted' | translate }}
    </p>
  } @else {
    <pu-user-profile-card
      class="pt-comment__user"
      [user]="comment().user"
    />
    <time
      class="pt-comment__date">
      {{ comment().createdAt | date: 'dd MMM yyyy, HH:mm' }}
    </time>
  }

  <div 
    class="pt-comment__content"
    [class.deleted]="isDeleted()"
    >
    <div class="pt-comment__content-main">
      <div class="pt-comment__comment">
        <p class="pt-comment__comment-text">{{ comment().content }}</p>
  
        @if (comment().updatedAt) {
          <span
            [puPopover]="popover"
            [position]="['tc']"
            [offsetY]="4">
            {{ 'blog.post.comment.edited' | translate }}
          </span>
  
          <ng-template #popover>
            <p class="pt-comment__comment-popover">
              {{ 'blog.post.comment.edited.time' | translate: { time: comment().updatedAt | date: 'dd MMM yyyy, HH:mm' } }}
            </p>
          </ng-template>
        }
      </div>

      <pt-reactions
        class="pt-comment__reactions"
        [reactionType]="reactionType.COMMENT"
        [contentId]="comment().id"
        [reactions]="comment().reactions"
        (reacted)="reactionsChanged.emit()"/>

      @if (comment().repliesCount && !isParent()) {
        <div
          class="pt-comment__replies"
          (click)="reply.emit(comment())">
          <ul class="pt-comment__replies-list">
            @for (reply of comment().replies.slice(0, 3); track reply.id) {
              <li class="pt-comment__replies-item">
                <pu-avatar
                  class="pt-comment__replies-avatar"
                  size="20"
                  [image]="reply.imageUrl"
                  [nameFirstLetter]="reply.fullName && reply.fullName[0]"
                />
              </li> 
            }
          </ul>
    
          <span class="pt-comment__replies-count">
            {{ 'blog.post.comment.replies' | translate: { count: comment()?.repliesCount || '' } }}
          </span>
        </div>
      }
    </div>

    @if(!isDeleted()) {
      <div class="pt-comment__actions">
        @if (showReplyBtn()) {
          <button
            type="button"
            puButton
            size="s"
            uiType="quaternary"
            (click)="reply.emit(comment())"
          >
            <pu-icon iconName="comment" size="20" />
          </button>
        }
        
        @if(!isParent() && isCommentOwner()) {
          <button
            type="button"
            puButton
            size="s"
            uiType="quaternary"
            (click)="edit.emit(comment().id)"
          >
            <pu-icon iconName="edit" size="20" />
          </button>
    
          <button
            type="button"
            puButton
            size="s"
            uiType="quaternary"
            (click)="remove.emit(comment())"  
          >
            <pu-icon iconName="trash" size="20" />
          </button>
        }
      </div>
    }
  </div>
}
