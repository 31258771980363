<div
  class="pt-comments"
  *puSpinner="isLoading()"
  ptInfiniteScroll
  useGlobalScroll
  [hasMore]="comments()?.length < itemsCount()"
  [isLoading]="isLoading()"
  (scrolled)="loadMore()"
  (scrollPositionChanged)="onScrollPossitionChanged($event)">
  @if (comments()?.length) {
    <h3 class="pt-comments__title">{{ 'blog.post.comments.title' | translate }}</h3>
    <ul class="pt-comments__list">
      @for (comment of comments(); track $index) {
        <li
          ptComment
          [comment]="comment"
          [isEditing]="isEditing(comment.id)"
          (edit)="onEdit($event)"
          (save)="onSave($event, comment)"
          (cancel)="cancelEdit()"
          (reply)="onReply($event)"
          (remove)="onDelete($event)"
          showReplyBtn
        >
        </li>
      }
    </ul>
  }

  <div
    class="pt-comments__footer"
    [class.pt-comments__footer_navbar-hidden]="isNavbarHidden() && !isDesktop()">
    @if (showScrollTopBtn) {
      <button
        class="pt-comments__to-top-btn"
        puButton
        size="m"
        uiType="secondary"
        type="button"
        (click)="scrollToPost()"
        [innerHTML]="'blog.post.comments.topBtn' | translate">
      </button>
    }
    
    <pt-comment-form
      class="pt-comments__form"
      (formSubmitted)="onSave($event)"/>
  </div>
</div>

