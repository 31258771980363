import { Injectable } from '@angular/core';

import { delay, Observable, of } from 'rxjs';

import { GetCommentsRes } from '../models';
import { CommentsApi } from './comments-api.interface';
import { getCommentsMock } from '../mocks';

@Injectable()
export class CommentsApiMockService implements CommentsApi {
  getComments(): Observable<GetCommentsRes> {
    return getCommentsMock();
  }

  addComment() {
    return of(null).pipe(delay(500));
  }

  editComment() {
    return of(null).pipe(delay(500));
  }

  deleteComment() {
    return of(null).pipe(delay(500));
  }
}
