import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { environment } from '@pt/environment';
import { getParams, getQueryParams } from '@pinup-teams/common';
import { CommentModel } from '@pt/models';

import { ThreadFilters } from '../models';
import { ThreadApi } from './thread-api.interface';

@Injectable()
export class ThreadApiService implements ThreadApi {
  readonly #http = inject(HttpClient);
  readonly #apiHost = environment.apiHost;
  readonly #apiPath = `${this.#apiHost}news/`;

  getComment(params: ThreadFilters, comment: CommentModel): Observable<CommentModel> {
    return this.#http.get<CommentModel>(
      `${this.#apiPath}${comment.article}/comments/${comment.id}/`,
      { params: getQueryParams(params, ['page', 'limit']) },
    );
  }

  addReply(params: CommentModel): Observable<CommentModel> {
    return this.#http.post<CommentModel>(
      `${this.#apiPath}${params.article}/comments/`,
      getParams(params, ['content', 'parentCommentId']),
    );
  }

  editReply(params: CommentModel): Observable<CommentModel> {
    return this.#http.patch<CommentModel>(
      `${this.#apiPath}${params.article}/comments/${params.id}/`,
      getParams(params, ['content']),
    );
  }

  deleteReply(params: CommentModel): Observable<void> {
    return this.#http.delete<void>(
      `${this.#apiPath}${params.article}/comments/${params.id}/`,
    );
  }
}
