<ng-container *puLet="vm$ | async as vm">
  <div
    class="pt-achievements-history"
    *puSpinner="vm.isLoading"
  >

    <div class="pt-achievements-history__header">
      <h1 class="pt-achievements-history__h1">
        {{ 'achievementsHistory.title' | translate }}
      </h1>

      <div class="pt-achievements-history__filters">
        <swiper
          class="pt-achievements-history__tabs pu-swiper-gap-16"
          [config]="sliderConfig"
        >

          @for (type of types | withAllOption; track type.id) {
            <button
              [class.pu-button_pressed]="filtersForm.controls.achievementType.value === type.id"
              *swiperSlide
              type="button"
              puButton
              uiType="gradient"
              size="s"
              (click)="changeType(type)"
            >
              {{ type.name }}
            </button>
          }
        </swiper>

        <div class="pt-achievements-history__filters-controls">
          @if (filtersForm.controls.achievementType.value === AchievementType.Colleagues
          || filtersForm.controls.achievementType.value === AchievementType.Managers) {
            <pu-select
              class="pt-achievements-history__filters-controls-select"
              [formControl]="filtersForm.controls.status"
              [items]="statuses | withAllOption"
              [label]="'achievementsHistory.filters.status' | translate"
            />
          }

          <pu-select
            class="pt-achievements-history__filters-controls-select"
            [formControl]="filtersForm.controls.period"
            [items]="periods"
            [label]="'achievementsHistory.filters.period' | translate"
          />
        </div>
      </div>
    </div>

    <ul class="pt-achievements-history__achievements">
      @for (achievement of vm.achievements; track achievement.id) {
        <li
          class="pt-achievements-history__achievement"
          ptAchievementHistory
          [achievement]="achievement"
        >
        </li>
      } @empty {
        @if (!vm.isLoading) {
          <pu-empty
            iconName="achievements"
            titleKey="achievementsHistory.empty.title"
          />
        }
      }
    </ul>

    @if (vm.itemsCount > vm.filters.limit) {
      <div class="pt-achievements-history__paginator">
        <mat-paginator
          [length]="vm.itemsCount"
          [pageSize]="vm.filters.limit"
          [showFirstLastButtons]="true"
          [pageSizeOptions]="[30, 50, 100]"
          [hidePageSize]="true"
          [pageIndex]="vm.filters.page - 1"
          (page)="changePage($event)"
          aria-label="Select page"
        />
      </div>
    }

    @if (vm.itemsCount) {
      <div class="pt-achievements-history__total">
        @if (vm.filters.achievementType !== AchievementType.Education && vm.filters.status
        === null) {
          <pt-donut-total
            [sum1]="vm.total.received"
            color1="var(--color-donut-2)"
            i18nLabel1="achievementsHistory.label.received"
            [sum2]="vm.total.sent"
            color2="var(--color-donut-1)"
            i18nLabel2="achievementsHistory.label.sent"
          />
        }

        @if (vm.filters.status !== null || vm.filters.achievementType
        === AchievementType.Education) {
          <pt-text-total
            [sum]="vm.filters.status === AchievementStatus.Received ? vm.total.received : vm.total.sent"
            i18nHeader="achievementsHistory.label.total"
          />
        }
      </div>
    }
  </div>
</ng-container>
