@if(isDialog()) {
  <button
    class="flip-book__close"
    type="button"
    puButton
    size="l"
    uiType="quaternary"
    [onlyIcon]="true"
    (click)="close()"
  >
    <pu-icon
      iconName="close"
      size="18"
    />
  </button>
}

<div class="_df_book" id="pt_flipbook"></div>

