import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { IconModule, IconSize } from '@pinup-teams/common';
import { environment } from '@pt/environment';

@Component({
  standalone: true,
  selector: 'pt-coin',
  templateUrl: './coin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconModule],
})
export class CoinComponent {
  size = input<IconSize>('16');
  readonly coinIconName = `${environment.shortName}-coin`;
}
