@if (onboardingDetails()) {
    <div class="pt-onboarding-preview__swiper-container">
    <swiper
      class="pt-onboarding-preview__swiper"
      [config]="sliderConfig"
    >
      @for (stage of onboardingDetails().stages; track stage.id) {
        <pt-onboarding-stage
          class="pt-onboarding-preview__stage"
          *swiperSlide
          [stage]="stage"
          [stageOrder]="$index + 1"
          (openTask)="openTask($event)"
        />
      }
    </swiper>

    <pu-slider-controls
      [hasButtons]="false"
      prevControlClass="pt-onboarding-preview__swiper-control-prev"
      nextControlClass="pt-onboarding-preview__swiper-control-next"
    />
  </div>
}
